import { Component, Input } from "@angular/core";
import { BackendService } from "@app/shared/services/backend.service";
import { Meldung } from "@app/shared/classes/meldung";
import { HistoryEntry } from '@app/shared/classes/history-entry';

@Component({
  selector: 'meldung-history-dialog',
  templateUrl: './meldung-history-dialog.component.html'
})
export class MeldungHistoryDialogComponent {

  historyEntries: HistoryEntry[];

  constructor(private backendService: BackendService) {
  }

  @Input()
  set meldung(value: Meldung) {
    if (value) {
      this.backendService.listHistoryEntries(value)
        .subscribe({
          next: entries => this.historyEntries = entries,
          error: console.error
        });
    } else {
      this.historyEntries = [];
    }
  }
}
