import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {BackendService} from "@app/shared/services/backend.service";
import { BlvStatusId, MeldungFilter } from "@app/shared/classes/meldung-filter";
import { Land } from "@app/shared/classes/land";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { CryptoService } from "@app/shared/services/crypto.service";
import { IDatePickerDirectiveConfig } from 'ng2-date-picker';
import dayjs from 'dayjs';
import { Status } from "@app/shared/classes/status";
import { downloadFile } from "@app/shared/download-utils";
import { AuthorizationService } from "@app/core/auth/authorization.service";
import Swal from "sweetalert2";
import { MeldungBewilligungFilter } from "@app/shared/classes/meldung-bewilligung-filter";

@Component({
  selector: 'app-meldung-list',
  templateUrl: './meldung-list.component.html'
})
export class MeldungListComponent implements OnInit {
  bewilligung: MeldungBewilligungFilter = new MeldungBewilligungFilter();
  filter: MeldungFilter;
  laender: Land[];
  statusList: Status[];
  filterForm: UntypedFormGroup;
  datumVonBis: IDatePickerDirectiveConfig;
  totalMeldungen: number;
  @ViewChild('filterComponent') filterComponent: ElementRef;

  constructor(private backendService: BackendService,
              private cryptoService: CryptoService,
              private authorizationService: AuthorizationService) {
  }

  ngOnInit(): void {
    this.datumVonBis = {
      firstDayOfWeek: "mo",
      format: "DD.MM.YYYY",
      disableKeypress: true,
      max: dayjs()
    };

    this.backendService.listVerwendeteLaender()
      .subscribe( result => {
        this.laender = result;
      });
    this.backendService.listStatus()
      .subscribe( result => {
        this.statusList = result;
      });
    this.filter = new MeldungFilter();
    this.filterForm = new UntypedFormGroup({
      freitext: new UntypedFormControl(this.filter.freitext),
      land: new UntypedFormControl(this.filter.land),
      status: new UntypedFormControl(this.filter.status),
      offeneAnfrage: new UntypedFormControl(this.filter.offeneAnfrage),
      datumVon: new UntypedFormControl(this.filter.datumVon),
      datumBis: new UntypedFormControl(this.filter.datumBis),
      blvStatus: new UntypedFormControl(this.filter.blvStatus)
    });
    this.filterForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe(values => this.storeFilter(MeldungFilter.fromJSON(values)) );

    let filterCiphertext = localStorage.getItem('filter');
    if (filterCiphertext) {
      this.cryptoService.decrypt(filterCiphertext).then(filterPlaintext => {
        this.filter = MeldungFilter.fromJSON(filterPlaintext);
        this.bewilligung = new MeldungBewilligungFilter(this.filter.bewilligung);
        this.filterForm.setValue({
          freitext: this.filter.freitext,
          land: this.filter.land,
          status: this.filter.status,
          offeneAnfrage: this.filter.offeneAnfrage,
          datumVon: this.filter.datumVon,
          datumBis: this.filter.datumBis,
          blvStatus: this.filter.blvStatus
        })
      });
    }
  }

  clearValue(name: string): void {
    this.filterForm.patchValue({ [name]: '' });
  }

  resetFilter(): void {
    this.bewilligung = new MeldungBewilligungFilter();
    this.filterForm.setValue({
      freitext: '',
      land: 0,
      status: 0,
      offeneAnfrage: 0,
      datumVon: '',
      datumBis: '',
      blvStatus: 0
    });
  }

  downloadCsv(): void {
    this.backendService.getMeldungenCsv(this.filter)
      .subscribe({
        next: downloadFile,
        error: console.error
      });
  }

  downloadXls(): void {
    this.backendService.getMeldungenXls(this.filter)
      .subscribe({
        next: downloadFile,
        error: console.error
      });
  }

  downloadZoll(): void {
    const zollFilter = new MeldungFilter({
      blvStatus : BlvStatusId.VOLLSTAENDIG,
      datumVon  : dayjs().subtract(6, 'months').format('DD.MM.YYYY')
    });

    this.backendService.getDatenauszugZoll(zollFilter)
      .subscribe({
        next: downloadFile,
        error: console.error
      });
  }

  get classFreitext(): string {
    return this.authorizationService.isBlv() ? 'col-sm-7.5 mb-0' : 'col-sm-12 mb-0'
  }

  get classLandStatus(): string {
    return this.authorizationService.isBlv() ? 'col-sm-2.5 mb-0' : 'col-sm-4 mb-0'
  }

  erweiterterFilterAktiv(): boolean {
    return this.bewilligung.anyAktiv();
  }

  erweiterterFilterAnzeigen(): void {
    Swal.fire({
      html: this.filterComponent.nativeElement,
      width: 'auto',
      didClose: () => this.storeFilter(new MeldungFilter(this.filter))
    });
  }

  private storeFilter(filter: MeldungFilter): void {
    filter.bewilligung = this.bewilligung;
    this.filter = filter;
    let filterPlaintext = JSON.stringify(this.filter.toJSON());
    this.cryptoService.encrypt(filterPlaintext).then(filterCiphertext => {
      localStorage.setItem('filter', filterCiphertext);
    });
  }
}
