import { Component, EventEmitter, Input, Output } from "@angular/core";
import Swal from "sweetalert2";
import { BackendService } from "@app/shared/services/backend.service";
import { Meldung } from "@app/shared/classes/meldung";
import { DeaktivierungsGrund } from '@app/shared/classes/deaktivierungs-grund';
import { MeldungStorno } from '@app/shared/classes/meldung-storno';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from '@app/core/auth/authorization.service';
import type { Sprache } from '@app/shared/types';

@Component({
  selector: 'meldung-deaktivieren-dialog',
  templateUrl: './meldung-deaktivieren-dialog.component.html'
})
export class MeldungDeaktivierenDialogComponent {

  id: number;
  label: string;
  tierarztSprache: string;
  stornoKommentar: string;
  gruende: DeaktivierungsGrund[];
  grund: DeaktivierungsGrund;
  sprache: Sprache = 'DE';
  kommentar: string;
  error: string;
  pendingRequest: boolean = false;

  constructor(private authorizationService: AuthorizationService,
              private backendService: BackendService,
              private translateService: TranslateService) {

    this.label = this.authorizationService.isTierarzt()
           ? 'meldung.view.deaktivieren.grund.request'
           : 'meldung.view.deaktivieren.grund.label';
  }

  @Output() result = new EventEmitter<Meldung>();

  @Input()
  set meldung(value: Meldung) {
    this.id = value?.id;
    this.error = null;
    if (value && this.authorizationService.isBlv()) {
      this.stornoKommentar = value.stornoKommentar;
      this.backendService.letzteSprache(this.id)
        .subscribe({
          next: sprache => {
            if (sprache) {
              this.setSprache(sprache);
              this.tierarztSprache = this.translateService.instant('meldung.view.deaktivieren.sprache', {
                sprache: this.translateService.instant('global.sprache.' + sprache.toLowerCase())
              });
            } else {
              this.tierarztSprache = null;
            }
          },
          error: console.error
        });
      this.backendService.listDeaktivierungsGruende()
        .subscribe( gruende => {
          this.gruende = gruende;
          if (this.stornoKommentar) {
            this.setGrund(gruende.find( it =>
              it.textDe?.includes('{{ANFRAGE}}') ||
              it.textFr?.includes('{{ANFRAGE}}') ||
              it.textIt?.includes('{{ANFRAGE}}')
            ));
          }
        });
    }
  }

  cancel(): void {
    Swal.close();
  }

  save(): void {
    if (!this.kommentar || this.kommentar.length < 10) {
      this.error = this.translateService.instant('meldung.view.deaktivieren.grund.tooshort');
      return;
    }
    this.error = null;
    this.pendingRequest = true;

    const storno = new MeldungStorno();
    storno.id = this.id;
    storno.kommentar = this.kommentar;

    this.backendService.stornoMeldung(storno)
      .subscribe({
        next: meldung => {
          this.pendingRequest = false;
          Swal.close();
          this.result.emit(meldung)
        },
        error: err => {
          this.pendingRequest = false;
          console.error(err);
        }
      });
  }

  convertGrund(grund: DeaktivierungsGrund, sprache: Sprache): string {
    let text: string;
    switch(sprache) {
      case 'DE': text = grund?.textDe; break;
      case 'FR': text = grund?.textFr; break;
      case 'IT': text = grund?.textIt; break;
    }
    return text?.replace('{{ANFRAGE}}', this.stornoKommentar ?? '');
  }

  setSprache(sprache: Sprache): void {
    const oldKommentar = this.convertGrund(this.grund, this.sprache);
    this.sprache = sprache;
    if (this.kommentar === oldKommentar) {
      this.kommentar = this.convertGrund(this.grund, this.sprache);
    }
  }

  setGrund(grund: DeaktivierungsGrund): void {
    this.grund = grund;
    this.kommentar = this.convertGrund(this.grund, this.sprache);
  }
}
