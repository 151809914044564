<footer>
  <address>
    <span class="hidden-xs" [innerText]="'global.blv' | translate"></span>
    <nav class="pull-right">
      <ul>
        <li>
          <a href="{{ 'url.kontakt' | setting:true }}" target="_blank">
            {{ 'global.kontakt' | translate }}
          </a>
        </li>
        <li>
          <a href="{{ 'url.rechtliches' | setting:true }}" target="_blank">
            {{ 'global.rechtliches' | translate }}
          </a>
        </li>
        <li class="small">
          v{{ frontendVersion }}
        </li>
      </ul>
    </nav>
  </address>
</footer>
