import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { Login } from '@app/core/auth/login';
import { LoginService } from '@app/shared/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subject, SubscriptionLike } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from "sweetalert2";
import { AuthorizationService } from "@app/core/auth/authorization.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject();

  currentUser: Login;
  versionInfo = '';
  backendPingTask: SubscriptionLike;


  currentLanguage: string;
  // TODO: Gibt es einen anderen Weg um diese Liste zu erstellen?
  availableLanguages = ['DE', 'FR', 'IT'];

  constructor(private authorizationService: AuthorizationService,
              private authenticationService: AuthenticationService,
              private personService: LoginService,
              private translate: TranslateService) {
  }

  @Output() versionLabel: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    this.versionLabel.emit(this.versionInfo);

    this.authenticationService.authenticated$
      .pipe(takeUntil(this.destroyed$))
      .subscribe( () => this.currentUser = this.authenticationService.getUser() );

    this.currentLanguage = this.translate.currentLang;
    this.translate.onLangChange
      .pipe(takeUntil(this.destroyed$))
      .subscribe(event => {
        this.currentLanguage = event.lang;
      });
    this.authenticationService.onTokenExpired = () => this.tokenExpired();
    this.backendPingTask = interval(10_000).subscribe(() => {
      this.personService.checkLoginStatus().subscribe(
        loggedIn => loggedIn || this.tokenExpired()
      )
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.backendPingTask?.unsubscribe();
  }

  tokenExpired() {
    this.backendPingTask?.unsubscribe();
    Swal.fire({
      icon: 'info',
      title: this.translate.instant('global.tokenExpired.title'),
      text: this.authorizationService.isTierarzt() ?
        this.translate.instant('global.tokenExpired.isabv') :
        this.translate.instant('global.tokenExpired.blv'),
      confirmButtonText: this.translate.instant('global.tokenExpired.reload'),
      showConfirmButton: this.authorizationService.isBlv(),
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    }).then(() => location.reload());
  }

  changeLanguage(newLang: string) {
    const user = this.currentUser;
    user.language = newLang;
    this.personService.changeLanguage(user)
      .subscribe(result => {
        this.currentUser.language = result.language;
        this.translate.use(this.currentUser.language);
        window.location.reload();
      });
  }

  userAccount() {
    this.authenticationService.goToMyAccount();
  }

  logout() {
    this.authenticationService.logout();
  }

  get displayName(): string {
    const user = this.currentUser;
    if (user) {
      if (user.uid) {
        // IS-ABV
        return user.zusatz ? `${user.uid} - ${user.zusatz}` : user.uid;
      }
      // eIAM
      return [user.vorname, user.nachname].filter( it => it ).join(' ');
    }
    return '';
  }
}
