{
  "name": "frontend",
  "version": "1.4.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --ssl true",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/localize": "^18.2.13",
    "@angular/material": "^18.2.14",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@ngx-loading-bar/core": "^7.0.0",
    "@ngx-loading-bar/http-client": "^7.0.0",
    "@ngx-loading-bar/router": "^7.0.0",
    "@ngx-translate/core": "^16.0.0",
    "@ngx-translate/http-loader": "^16.0.0",
    "dayjs": "^1.11.13",
    "file-saver": "^2.0.5",
    "keycloak-js": "^26.0.0",
    "marked": "^15.0.0",
    "ng2-date-picker": "^18.0.0",
    "ngx-dynamic-hooks": "^3.0.0",
    "ngx-spinner": "^17.0.0",
    "rxjs": "~7.8.1",
    "sweetalert2": "^11.15.10",
    "tslib": "^2.8.1",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.13",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^22.0.0",
    "jasmine-core": "~5.5.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.5.4"
  },
  "overrides": {
    "@angular-devkit/build-angular": {
      "electron-to-chromium": "1.5.76"
    }
  }
}
