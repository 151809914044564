<table class="table table-naked history-table">
  <tr *ngFor="let entry of historyEntries" class="history-action-{{entry.action?.id}}">
    <td [innerText]="entry.timestamp | date:'dd.MM.yyyy HH:mm'"></td>
    <td [innerText]="entry.action?.messageCode | translate"></td>
    <td [innerText]="entry.user ?? ''"></td>
  </tr>
</table>

<style>
  .history-table {
    text-align: left;
  }
  .history-action-0 {
    color: darkgray;
    font-style: italic;
  }
</style>
