import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '@app/core/config/configuration.service';
import { Settings } from '@app/core/config/settings';
import { MeldungStorno } from '@app/shared/classes/meldung-storno';
import { forkJoin, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Meldung } from '@app/shared/classes/meldung';
import { ImportGrund, ImportGrundAdmin } from '@app/shared/classes/import-grund';
import { Land, LandAdmin } from '@app/shared/classes/land';
import { MeldungFilter } from '@app/shared/classes/meldung-filter';
import { MeldungList } from '@app/shared/classes/meldung-list';
import { Status } from "@app/shared/classes/status";
import { DokumentTyp } from "@app/shared/classes/dokument-typ";
import { Dokument } from "@app/shared/classes/dokument";
import { TranslateService } from "@ngx-translate/core";
import { SettingsService } from "@app/core/config/settings.service";
import { Nutzung } from "@app/shared/classes/nutzung";
import { NotizBlv } from "@app/shared/classes/notiz-blv";
import { DeaktivierungsGrund, DeaktivierungsGrundAdmin } from '@app/shared/classes/deaktivierungs-grund';
import { TemplateType } from '@app/shared/classes/template-type';
import { MeldungBewilligung, MeldungBewilligungPreview } from '@app/shared/classes/meldung-bewilligung';
import { TemplateList } from '@app/shared/classes/template-list';
import { type Sprache } from '@app/shared/types';
import { HistoryEntry } from '@app/shared/classes/history-entry';

@Injectable({providedIn: 'root'})
export class BackendService {

  private apiBase: string;

  constructor(private http: HttpClient,
              configurationService: ConfigurationService,
              private translateService: TranslateService,
              private settingsService: SettingsService) {
    this.apiBase = configurationService.getConfig().backendUrl;
  }

  getLog(days: string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.apiBase}/log/${days}`, {observe: 'response', responseType: 'blob'})
      .pipe();
  }

  getVersion(): Observable<any> {
    return this.http.get(`${this.apiBase}/admin/version`, {responseType: 'text'})
      .pipe();
  }

  checkBackend(): Observable<any> {
    return this.http.get(`${this.apiBase}/monitoring/checkDetails`)
      .pipe();
  }

  listSettings(): Observable<Settings[]> {
    return this.http.get(`${this.apiBase}/settings`)
      .pipe(
        map((data: Settings[]) => data.map(Settings.fromJSON))
      );
  }

  createSetting(setting: Settings): Observable<Settings[]> {
    return this.http.put(`${this.apiBase}/settings/create`, setting.toJSON())
      .pipe(
        map((data: Settings[]) => data.map(Settings.fromJSON))
      );
  }

  editSetting(setting: Settings): Observable<Settings> {
    return this.http.put(`${this.apiBase}/settings/edit`, setting)
      .pipe(
        map((data: Settings) => Settings.fromJSON(data))
      );
  }

  deleteSetting(settingId: number): Observable<Settings[]> {
    return this.http.put(`${this.apiBase}/settings/delete`, settingId)
      .pipe(
        map((data: Settings[]) => data.map(Settings.fromJSON))
      );
  }

  listZulassungsLaender(): Observable<Land[]> {
    return this.http.get(`${this.apiBase}/settings/zulassungsLaender`)
      .pipe(
        map((data: Land[]) => data.map(Land.fromJSON))
      );
  }

  listImportLaender(): Observable<Land[]> {
    return this.http.get(`${this.apiBase}/settings/importLaender`)
      .pipe(
        map((data: Land[]) => data.map(Land.fromJSON))
      );
  }

  listVerwendeteLaender(): Observable<Land[]> {
    return this.http.get(`${this.apiBase}/settings/laender/search`)
      .pipe(
        map((data: Land[]) => data.map(Land.fromJSON))
      );
  }

  listLaender(): Observable<LandAdmin[]> {
    return this.http.get(`${this.apiBase}/settings/laender/admin`)
      .pipe(
        map((data: LandAdmin[]) => data.map(LandAdmin.fromJSON))
      );
  }

  listStatus(): Observable<Status[]> {
    return this.http.get(`${this.apiBase}/settings/status`)
      .pipe(
        map((data: Status[]) => data.map(Status.fromJSON))
      );
  }

  listNutzung(): Observable<Nutzung[]> {
    return this.http.get(`${this.apiBase}/settings/nutzung`)
      .pipe(
        map((data: Nutzung[]) => data.map(Nutzung.fromJSON))
      );
  }

  createLand(land: LandAdmin): Observable<LandAdmin[]> {
    return this.http.put(`${this.apiBase}/settings/laender/create`, land.toJSON())
      .pipe(
        map((data: LandAdmin[]) => data.map(LandAdmin.fromJSON))
      );
  }

  editLand(land: LandAdmin): Observable<LandAdmin> {
    return this.http.put(`${this.apiBase}/settings/laender/edit`, land)
      .pipe(
        map((data: LandAdmin) => LandAdmin.fromJSON(data))
      );
  }

  deleteLand(landId: number): Observable<LandAdmin[]> {
    return this.http.put(`${this.apiBase}/settings/laender/delete`, landId)
      .pipe(
        map((data: LandAdmin[]) => data.map(LandAdmin.fromJSON))
      );
  }

  listImportGruende(): Observable<ImportGrund[]> {
    return this.http.get(`${this.apiBase}/settings/importGruende`)
      .pipe(
        map((data: ImportGrund[]) => data.map(ImportGrund.fromJSON))
      );
  }

  listImportGruendeAdmin(): Observable<ImportGrundAdmin[]> {
    return this.http.get(`${this.apiBase}/settings/importGruende/admin`)
      .pipe(
        map((data: ImportGrundAdmin[]) => data.map(ImportGrundAdmin.fromJSON))
      );
  }

  createImportgrund(land: ImportGrundAdmin): Observable<ImportGrundAdmin[]> {
    return this.http.put(`${this.apiBase}/settings/importGruende/create`, land.toJSON())
      .pipe(
        map((data: ImportGrundAdmin[]) => data.map(ImportGrundAdmin.fromJSON))
      );
  }

  editImportgrund(land: ImportGrundAdmin): Observable<ImportGrundAdmin> {
    return this.http.put(`${this.apiBase}/settings/importGruende/edit`, land)
      .pipe(
        map((data: ImportGrundAdmin) => ImportGrundAdmin.fromJSON(data))
      );
  }

  deleteImportgrund(grundId: number): Observable<ImportGrundAdmin[]> {
    return this.http.put(`${this.apiBase}/settings/importGruende/delete`, grundId)
      .pipe(
        map((data: ImportGrundAdmin[]) => data.map(ImportGrundAdmin.fromJSON))
      );
  }

  listDeaktivierungsGruende(): Observable<DeaktivierungsGrund[]> {
    return this.http.get(`${this.apiBase}/settings/deaktivierungsGruende`)
      .pipe(
        map((data: DeaktivierungsGrund[]) => data.map(DeaktivierungsGrund.fromJSON))
      );
  }

  listDeaktivierungsGruendeAdmin(): Observable<DeaktivierungsGrundAdmin[]> {
    return this.http.get(`${this.apiBase}/settings/deaktivierungsGruende/admin`)
      .pipe(
        map((data: DeaktivierungsGrundAdmin[]) => data.map(DeaktivierungsGrundAdmin.fromJSON))
      );
  }

  createDeaktivierungsGrund(land: DeaktivierungsGrundAdmin): Observable<DeaktivierungsGrundAdmin[]> {
    return this.http.put(`${this.apiBase}/settings/deaktivierungsGruende/create`, land.toJSON())
      .pipe(
        map((data: DeaktivierungsGrundAdmin[]) => data.map(DeaktivierungsGrundAdmin.fromJSON))
      );
  }

  editDeaktivierungsGrund(land: DeaktivierungsGrundAdmin): Observable<DeaktivierungsGrundAdmin> {
    return this.http.put(`${this.apiBase}/settings/deaktivierungsGruende/edit`, land)
      .pipe(
        map((data: DeaktivierungsGrundAdmin) => DeaktivierungsGrundAdmin.fromJSON(data))
      );
  }

  deleteDeaktivierungsGrund(grundId: number): Observable<DeaktivierungsGrundAdmin[]> {
    return this.http.put(`${this.apiBase}/settings/deaktivierungsGruende/delete`, grundId)
      .pipe(
        map((data: DeaktivierungsGrundAdmin[]) => data.map(DeaktivierungsGrundAdmin.fromJSON))
      );
  }

  listTemplateTypes(): Observable<TemplateList> {
    return this.http.get(`${this.apiBase}/settings/template`)
      .pipe(
        map((data: TemplateList) => TemplateList.fromJSON(data))
      );
  }

  uploadTemplate(type: TemplateType, sprache: Sprache, file: File): Observable<any> {
    let maxSize = parseFloat(this.settingsService.get("dokument.maxSize")) || 10;
    if (file.size > maxSize * 1024 * 1024) {
      return throwError(() => this.translateService.instant('dokument.maxSize', {size: maxSize}));
    }
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('data', file);
    return this.http.post(`${this.apiBase}/settings/template/${type.id}/${sprache}`, formData)
      .pipe();
  }

  downloadTemplate(type: TemplateType, sprache: Sprache): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.apiBase}/settings/template/${type.id}/${sprache}`, {observe: 'response', responseType: 'blob'});
  }

  downloadDummyTemplate(): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.apiBase}/settings/template/dummy`, {observe: 'response', responseType: 'blob'});
  }

  createMeldung(meldung: Meldung): Observable<number> {
    return this.http.post(`${this.apiBase}/meldung/create`, meldung)
      .pipe(
        map((data: any) => data['id'])
      );
  }

  getMeldung(id: string|number): Observable<Meldung> {
    return this.http.get(`${this.apiBase}/meldung/${id}`)
      .pipe(
        map((data: Meldung) => Meldung.fromJSON(data))
      );
  }

  listMeldungen(filter: MeldungFilter): Observable<MeldungList> {
    return this.http.post(`${this.apiBase}/meldung/list`, filter)
      .pipe(
        map((data: MeldungList) => MeldungList.fromJSON(data))
      );
  }

  stornoMeldung(storno: MeldungStorno): Observable<Meldung> {
    return this.http.put(`${this.apiBase}/meldung/storno`, storno)
      .pipe(
        map((data: Meldung) => Meldung.fromJSON(data))
      );
  }

  bewilligeMeldungPreview(bewilligung: MeldungBewilligung): Observable<MeldungBewilligungPreview> {
    return this.http.put(`${this.apiBase}/meldung/bewillige/preview`, bewilligung)
      .pipe(
        map((data: MeldungBewilligungPreview) => MeldungBewilligungPreview.fromJSON(data))
      );
  }

  bewilligeMeldung(bewilligung: MeldungBewilligung): Observable<Meldung> {
    return this.http.put(`${this.apiBase}/meldung/bewillige`, bewilligung)
      .pipe(
        map((data: Meldung) => Meldung.fromJSON(data))
      );
  }

  letzteSprache(id: string|number): Observable<Sprache> {
    return this.http.get(`${this.apiBase}/meldung/sprache/${id}`, {responseType: 'text'})
      .pipe(
        map((data: string) => {
          if (data === 'DE' || data === 'FR'|| data === 'IT') {
            return data;
          }
          return null;
        })
      );
  }

  saveNotizBlv(notiz: NotizBlv): Observable<Meldung> {
    return this.http.put(`${this.apiBase}/meldung/notiz`, notiz)
      .pipe(
        map((data: Meldung) => Meldung.fromJSON(data))
      );
  }

  getMeldungPdf(id: number|string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.apiBase}/meldung/pdf/${id}`, {observe: 'response', responseType: 'blob'});
  }

  getMeldungenCsv(filter: MeldungFilter): Observable<HttpResponse<Blob>> {
    return this.http.post(`${this.apiBase}/meldung/csv`, filter, {observe: 'response', responseType: 'blob'});
  }

  getMeldungenXls(filter: MeldungFilter): Observable<HttpResponse<Blob>> {
    return this.http.post(`${this.apiBase}/meldung/xls`, filter, {observe: 'response', responseType: 'blob'});
  }

  getDatenauszugZoll(filter: MeldungFilter): Observable<HttpResponse<Blob>> {
    return this.http.post(`${this.apiBase}/meldung/bazg`, filter, {observe: 'response', responseType: 'blob'});
  }

  listDokumente(id: number|string, kopieren?: boolean): Observable<Dokument[]> {
    let suffix = kopieren ? '?copy' : '';
    return this.http.get(`${this.apiBase}/dokument/list/${id}${suffix}`)
      .pipe(
        map((data: Dokument[]) => data.map(Dokument.fromJSON))
      );
  }

  listAvailableUploads(id: number|string): Observable<DokumentTyp[]> {
    return this.http.get(`${this.apiBase}/dokument/availableUploads/${id}`)
      .pipe(
        map((data: DokumentTyp[]) => data.map(DokumentTyp.fromJSON))
      );
  }

  uploadDokument(dokumentTyp: DokumentTyp, file: File): Observable<Dokument> {
    let maxSize = parseFloat(this.settingsService.get("dokument.maxSize")) || 10;
    if (file.size > maxSize * 1024 * 1024) {
      return throwError(() => this.translateService.instant('dokument.maxSize', {size: maxSize}));
    }
    const formData = new FormData();
    formData.append('dokumentTyp', dokumentTyp.id.toString());
    formData.append('name', file.name);
    formData.append('data', file);
    return this.http.post(`${this.apiBase}/dokument/upload`, formData)
      .pipe(
        map((data: Dokument) => Dokument.fromJSON(data))
      );
  }

  linkDokumente(meldungId: number, dokumentIds: number[]): Observable<Dokument[]> {
    return this.http.put(`${this.apiBase}/dokument/link`, { meldungId, dokumentIds } )
      .pipe(
        map((data: Dokument[]) => data.map(Dokument.fromJSON))
      );
  }

  getDokumente(ids: number[]): Observable<Dokument[]> {
    return forkJoin(
      ids.map( it => this.http.get(`${this.apiBase}/dokument/info/${it}`) )
    ).pipe(
      map((data: Dokument[]) => data.map(Dokument.fromJSON))
    );
  }

  downloadDokument(dokument: Dokument): Observable<HttpResponse<Blob>> {
    if (dokument.temporary) {
      return this.http.get(`${this.apiBase}/dokument/temp/${dokument.id}`, {observe: 'response', responseType: 'blob'});
    }
    return this.http.get(`${this.apiBase}/dokument/${dokument.id}`, {observe: 'response', responseType: 'blob'});
  }

  downloadBewilligung(id: number|string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.apiBase}/dokument/bewilligung/${id}`, {observe: 'response', responseType: 'blob'});
  }

  markUnread(dokument: Dokument) {
    return this.http.get(`${this.apiBase}/dokument/unread/${dokument.id}`);
  }

  deleteDokument(dokument: Dokument) {
    let suffix = dokument.temporary ? '?temp' : ''
    return this.http.delete(`${this.apiBase}/dokument/delete/${dokument.id}${suffix}`);
  }

  getDokumentTypen(ids: number[]): Observable<DokumentTyp[]> {
    return forkJoin(
      ids.map( it => this.http.get(`${this.apiBase}/dokument/typ/${it}`) )
    ).pipe(
      map((data: DokumentTyp[]) => data.map(DokumentTyp.fromJSON))
    );
  }

  listDokumentTypen(): Observable<DokumentTyp[]> {
    return this.http.get(`${this.apiBase}/dokument/typ`)
      .pipe(
        map((data: DokumentTyp[]) => data.map(DokumentTyp.fromJSON))
      );
  }

  getI18nJsonTemplate(): Observable<string> {
    return this.http.get('assets/i18n/template.json', {responseType: 'text'});
  }

  getI18nPropertiesTemplate(): Observable<string> {
    return this.http.get('assets/i18n/template.properties', {responseType: 'text'});
  }

  listHistoryEntries(meldung: Meldung): Observable<HistoryEntry[]> {
    return this.http.get(`${this.apiBase}/history/${meldung.id}`)
      .pipe(
        map((data: HistoryEntry[]) => data.map(HistoryEntry.fromJSON))
      );
  }
}
