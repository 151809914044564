import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localeDeCh from '@angular/common/locales/de-CH'
import { RouteReuseStrategy } from '@angular/router';
import { CheckComponent } from '@app/admin/check/check.component';
import { AdminSettingsEntryComponent } from '@app/admin/settings/admin-settings-entry/admin-settings-entry.component';
import { AdminSettingsNewComponent } from '@app/admin/settings/admin-settings-new/admin-settings-new.component';
import { AdminSettingsComponent } from '@app/admin/settings/admin-settings.component';
import { AdminStammdatenImportgrundEntryComponent } from '@app/admin/stammdaten/importgrund/admin-stammdaten-importgrund-entry/admin-stammdaten-importgrund-entry.component';
import { AdminStammdatenImportgrundNewComponent } from '@app/admin/stammdaten/importgrund/admin-stammdaten-importgrund-new/admin-stammdaten-importgrund-new.component';
import { AdminStammdatenImportgrundComponent } from '@app/admin/stammdaten/importgrund/admin-stammdaten-importgrund.component';
import { AdminStammdatenLandEntryComponent } from '@app/admin/stammdaten/land/admin-stammdaten-land-entry/admin-stammdaten-land-entry.component';
import { AdminStammdatenLandNewComponent } from '@app/admin/stammdaten/land/admin-stammdaten-land-new/admin-stammdaten-land-new.component';
import { AdminStammdatenLandComponent } from '@app/admin/stammdaten/land/admin-stammdaten-land.component';
import { AppInitService, initializeApp } from '@app/app-init.service';
import { GlobalHttpInterceptor } from '@app/core/auth/global-http-interceptor.service';
import { HasRoleDirective } from '@app/core/auth/has-role.directive';
import { LanguageInterceptor } from '@app/core/i18n/language-interceptor.service';
import { CustomRouteReuseStrategy } from '@app/core/routing/custom-route-reuse-strategy';
import { BackButtonDirective } from '@app/core/routing/navigation/back-button.directive';
import { ForbiddenComponent } from '@app/layouts/errors/forbidden/forbidden.component';
import { NotFoundComponent } from '@app/layouts/errors/not-found/not-found.component';
import { UnauthorizedComponent } from '@app/layouts/errors/unauthorized/unauthorized.component';
import { MeldungNewComponent } from '@app/meldung-new/meldung-new.component';
import { CollapseContentDirective } from '@app/shared/collapse-content.directive';
import { CollapseDirective } from '@app/shared/collapse.directive';
import { DropdownDirective } from '@app/shared/dropdown.directive';
import { DateMergePipe } from '@app/shared/pipes/date-merge.pipe';
import { FilesizePipe } from '@app/shared/pipes/filesize.pipe';
import { TextTruncatePipe } from '@app/shared/pipes/text-truncate.pipe';
import { TabComponent } from '@app/shared/tabs/tab.component';
import { TabsComponent } from '@app/shared/tabs/tabs.component';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DpDatePickerModule } from 'ng2-date-picker';
import { DynamicHooksComponent, provideDynamicHooks } from 'ngx-dynamic-hooks';
import { AdminComponent } from './admin/admin.component';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AccessTokenInterceptor } from './core/auth/access-token-interceptor.service';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { MeldungComponent } from './meldung/meldung.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MeldungListComponent } from '@app/meldung-list/meldung-list.component';
import { MeldungListTableComponent } from '@app/meldung-list/meldung-list-table/meldung-list-table.component';
import { IsBlvDirective } from '@app/core/auth/is-blv.directive';
import { IsTierarztDirective } from '@app/core/auth/is-tierarzt.directive';
import { SettingPipe } from "@app/shared/pipes/setting.pipe";
import { InjectUrlPipe } from "@app/shared/pipes/inject-url.pipe";
import { DokumentComponent } from "@app/dokument/dokument.component";
import { TemporaryDokumentUploadComponent } from "@app/dokument/temporary-dokument-upload/temporary-dokument-upload.component";
import { MeldungNotizEditComponent } from "@app/meldung/meldung-notiz-edit/meldung-notiz-edit.component";
import { MarkedPipe } from "@app/shared/pipes/marked.pipe";
import { MeldungListFilterComponent } from "@app/meldung-list/meldung-list-filter/meldung-list-filter.component";
import { AdminI18nComponent } from "@app/admin/i18n/admin-i18n.component";
import { MeldungDeaktivierenDialogComponent } from '@app/meldung/meldung-deaktivieren-dialog/meldung-deaktivieren-dialog.component';
import {
  AdminStammdatenDeaktivierungsgrundNewComponent
} from '@app/admin/stammdaten/deaktivierungsgrund/admin-stammdaten-deaktivierungsgrund-new/admin-stammdaten-deaktivierungsgrund-new.component';
import {
  AdminStammdatenDeaktivierungsgrundEntryComponent
} from '@app/admin/stammdaten/deaktivierungsgrund/admin-stammdaten-deaktivierungsgrund-entry/admin-stammdaten-deaktivierungsgrund-entry.component';
import { AdminStammdatenDeaktivierungsgrundComponent } from '@app/admin/stammdaten/deaktivierungsgrund/admin-stammdaten-deaktivierungsgrund.component';
import { AdminStammdatenTemplateComponent } from '@app/admin/stammdaten/template/admin-stammdaten-template.component';
import { MeldungBewilligenDialogComponent } from '@app/meldung/meldung-bewilligen-dialog/meldung-bewilligen-dialog.component';
import { MeldungHistoryDialogComponent } from '@app/meldung/meldung-history-dialog/meldung-history-dialog.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

registerLocaleData(localeDeCh, 'de-CH');

@NgModule({
  declarations: [
    AppComponent,
    ForbiddenComponent,
    NotFoundComponent,
    UnauthorizedComponent,
    HeaderComponent,
    MainLayoutComponent,
    HomeComponent,
    FooterComponent,
    FilesizePipe,
    AdminComponent,
    DropdownDirective,
    CollapseDirective,
    CollapseContentDirective,
    HasRoleDirective,
    IsBlvDirective,
    IsTierarztDirective,
    TabsComponent,
    TabComponent,
    TextTruncatePipe,
    DateMergePipe,
    InjectUrlPipe,
    MarkedPipe,
    SettingPipe,
    AdminI18nComponent,
    AdminSettingsComponent,
    AdminSettingsEntryComponent,
    AdminSettingsNewComponent,
    AdminStammdatenLandComponent,
    AdminStammdatenLandEntryComponent,
    AdminStammdatenLandNewComponent,
    AdminStammdatenImportgrundComponent,
    AdminStammdatenImportgrundEntryComponent,
    AdminStammdatenImportgrundNewComponent,
    AdminStammdatenDeaktivierungsgrundComponent,
    AdminStammdatenDeaktivierungsgrundEntryComponent,
    AdminStammdatenDeaktivierungsgrundNewComponent,
    AdminStammdatenTemplateComponent,
    CheckComponent,
    BackButtonDirective,
    MeldungNewComponent,
    MeldungListComponent,
    MeldungListFilterComponent,
    MeldungListTableComponent,
    MeldungComponent,
    MeldungDeaktivierenDialogComponent,
    MeldungBewilligenDialogComponent,
    MeldungNotizEditComponent,
    MeldungHistoryDialogComponent,
    DokumentComponent,
    TemporaryDokumentUploadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DpDatePickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    DynamicHooksComponent,
    BrowserAnimationsModule,
    MatTooltipModule
  ],
  providers: [
    AppInitService,
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptor, multi: true},
    {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
    provideHttpClient(withInterceptorsFromDi()),
    provideDynamicHooks({
      parsers: [
        {component: TabsComponent, enclosing: true},
        {component: TabComponent}
      ]
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
