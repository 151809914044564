<div *appIsBlv>
  <div class="mb-15" *ngIf="tierarztSprache" [innerText]="tierarztSprache"></div>

  <div class="btn-group mb-15" role="group">
    <button type="button" class="btn" [class]="sprache === 'DE' ? 'btn-primary' : 'btn-default'" (click)="setSprache('DE')">{{ 'global.sprache.de' | translate }}</button>
    <button type="button" class="btn" [class]="sprache === 'FR' ? 'btn-primary' : 'btn-default'" (click)="setSprache('FR')">{{ 'global.sprache.fr' | translate }}</button>
    <button type="button" class="btn" [class]="sprache === 'IT' ? 'btn-primary' : 'btn-default'" (click)="setSprache('IT')">{{ 'global.sprache.it' | translate }}</button>
  </div>

  <div class="mb-15" [innerText]="label | translate">{{ 'meldung.view.deaktivieren.grund.label' | translate }}</div>
  <div *ngFor="let grund of gruende" (click)="setGrund(grund)" class="btn-link cursor-pointer">{{ grund.bezeichnung }}</div>
</div>

<textarea autocomplete="off" aria-required="true"
          class="form-control textarea form_field form_field_textarea mt-15"
          rows="4" [(ngModel)]="kommentar"></textarea>

<div class="swal2-validation-message" *ngIf="error" [innerText]="error"></div>

<button class="btn btn-default mt-15" (click)="cancel()">{{ "global.cancel" | translate }}</button>
<button class="btn btn-primary mt-15 ml-15" (click)="save()" *ngIf="!pendingRequest">{{ "global.ok" | translate }}</button>
<button class="btn btn-primary mt-15 ml-15" disabled *ngIf="pendingRequest">
  <i class="fa fa-play fa-fw" ngClass="fa-spinner fa-pulse"></i>
</button>
