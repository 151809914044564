import { HistoryAction } from '@app/shared/classes/history-action';

export interface HistoryEntryJSON {
  timestamp: Date;
  action: HistoryAction;
  user: string;
}

export class HistoryEntry {
  timestamp: Date;
  action: HistoryAction;
  user: string;

  static fromJSON(json: string | object): HistoryEntry {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const dokumentTyp = Object.create(HistoryEntry.prototype);
    return Object.assign(dokumentTyp, object);
  }

  toJSON(): HistoryEntryJSON {
    return Object.assign({}, this);
  }
}
