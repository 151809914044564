import * as i0 from '@angular/core';
import { InjectionToken, reflectComponentType, Injectable, SecurityContext, Inject, Optional, isDevMode, PLATFORM_ID, createComponent, APP_INITIALIZER, SkipSelf, createEnvironmentInjector, NgZone, EventEmitter, Component, Input, Output } from '@angular/core';
import { Observable, ReplaySubject, of, combineLatest, firstValueFrom } from 'rxjs';
import { mergeMap, tap, catchError, first, map } from 'rxjs/operators';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import * as i1 from '@angular/platform-browser';
import { createApplication } from '@angular/platform-browser';

/**
 * Custom injector tokens that are used for varous internal communication purposes
 */
const DYNAMICHOOKS_ALLSETTINGS = new InjectionToken('All of the settings registered in the whole app.');
const DYNAMICHOOKS_ANCESTORSETTINGS = new InjectionToken('The settings collected from all ancestor injectors');
const DYNAMICHOOKS_MODULESETTINGS = new InjectionToken('The settings for the currently loaded module.');
const contentElementAttr = '__ngx_dynamic_hooks_content';
const anchorElementTag = 'dynamic-component-anchor';
const anchorAttrHookId = '__ngx_dynamic_hooks_anchor_id';
const anchorAttrParseToken = '__ngx_dynamic_hooks_anchor_parsetoken';
const voidElementTags = ['area', 'base', 'br', 'col', 'command', 'embed', 'hr', 'img', 'input', 'keygen', 'link', 'meta', 'param', 'source', 'track', 'wbr'];
var DynamicHooksInheritance;
(function (DynamicHooksInheritance) {
  /**
   * Merges with settings from all injectors in the app.
   */
  DynamicHooksInheritance[DynamicHooksInheritance["All"] = 0] = "All";
  /**
   * (Default) Only merges with settings from direct ancestor injectors (such a father and grandfather injectors, but not "uncle" injectors).
   */
  DynamicHooksInheritance[DynamicHooksInheritance["Linear"] = 1] = "Linear";
  /**
   * Does not merge at all. Injector only uses own settings.
   */
  DynamicHooksInheritance[DynamicHooksInheritance["None"] = 2] = "None";
})(DynamicHooksInheritance || (DynamicHooksInheritance = {}));

/**
 * Returns the default values for the ParseOptions
 */
const getParseOptionDefaults = () => {
  return {
    sanitize: true,
    convertHTMLEntities: true,
    fixParagraphTags: true,
    updateOnPushOnly: false,
    compareInputsByValue: false,
    compareOutputsByValue: false,
    compareByValueDepth: 5,
    triggerDOMEvents: false,
    ignoreInputAliases: false,
    ignoreOutputAliases: false,
    acceptInputsForAnyProperty: false,
    acceptOutputsForAnyObservable: false,
    logOptions: {
      dev: true,
      prod: false,
      ssr: false
    }
  };
};
const regexes = {};
// General
const variableName = '[a-zA-Z_$]+[a-zA-Z0-9_$]*';
const attributeName = '[a-zA-Z$\\-_:][a-zA-Z$\\-_:0-9\\.]*';
// Attribute regex
regexes.attributeNameNoBracketsRegex = '(' + attributeName + ')';
regexes.attributeNameBracketsRegex = '\\[(' + attributeName + ')\\]';
regexes.attributeNameRoundBracketsRegex = '\\((' + attributeName + ')\\)';
regexes.attributeNameRegex = '(?:' + regexes.attributeNameNoBracketsRegex + '|' + regexes.attributeNameBracketsRegex + '|' + regexes.attributeNameRoundBracketsRegex + ')';
regexes.attributeValueDoubleQuotesRegex = '\"((?:\\\\.|[^\"])*?)\"'; // Clever bit of regex to allow escaped chars in strings: https://stackoverflow.com/a/1016356/3099523
regexes.attributeValueSingleQuotesRegex = '\'((?:\\\\.|[^\'])*?)\'';
// Context var regex examples: https://regex101.com/r/zSbY7M/4
// Supports the dot notation, the [] notation as well as function calls () for building variable paths
regexes.variablePathDotNotation = '\\.' + variableName;
regexes.variableBracketsNotation = '\\[[^\\]]*\\]'; // Relies on nested '[]'brackets being encoded
regexes.variablePathFunctionCall = '\\([^\\)]*\\)'; // Relies on nested '()'-brackets being encoded.
regexes.variablePathPartRegex = '(?:' + regexes.variablePathDotNotation + '|' + regexes.variableBracketsNotation + '|' + regexes.variablePathFunctionCall + ')';
regexes.contextVariableRegex = 'context' + regexes.variablePathPartRegex + '*';
regexes.placeholderVariablePathDotNotation = '\\@@@cxtDot@@@' + variableName;
regexes.placeholderVariableBracketsNotation = '@@@cxtOpenSquareBracket@@@[^\\]]*@@@cxtCloseSquareBracket@@@';
regexes.placeholderVariablePathFunctionCall = '@@@cxtOpenRoundBracket@@@[^\\)]*@@@cxtCloseRoundBracket@@@';
regexes.placeholderVariablePathPartRegex = '(?:' + regexes.placeholderVariablePathDotNotation + '|' + regexes.placeholderVariableBracketsNotation + '|' + regexes.placeholderVariablePathFunctionCall + ')';
regexes.placeholderContextVariableRegex = '__CXT__' + regexes.placeholderVariablePathPartRegex + '*';

/**
 * Polyfill for String.prototype.matchAll() from the ES2020 spec
 *
 * Note: The 'string.prototype.matchall' npm package was unstable for me so providing my own version here
 *
 * @param text - The text to search
 * @param regExp - The RegExp object to use
 */
function matchAll(text, regExp) {
  // Must be global
  if (!regExp.global) {
    throw Error('TypeError: matchAll called with a non-global RegExp argument');
  }
  // Get matches
  const result = [];
  let match = regExp.exec(text);
  while (match !== null) {
    result.push(match);
    match = regExp.exec(text);
  }
  // Reset internal index
  regExp.lastIndex = 0;
  return result;
}
/**
 * Sort elements/nodes based on the order of their appearance in the document
 *
 * @param arr - The array to sort
 * @param sortCallback - The callback to use to sort the elements
 * @param getElement - An optional callback that returns the element to compare from each arr entry
 */
function sortElements(arr, sortCallback, getElementCallback) {
  const result = [...arr];
  return result.sort(function (a, b) {
    if (typeof getElementCallback === 'function') {
      a = getElementCallback(a);
      b = getElementCallback(b);
    }
    return sortCallback(a, b);
  });
}
/**
 * Indicates if an element is either a component host element or part of a component's view/template
 *
 * @param element - The element to inspect
 */
function isAngularManagedElement(element) {
  // Angular gives component host and view elements the following property, so can simply check for that
  return element?.__ngContext__ !== undefined;
}

/**
 * A text parser to load components with their bindings like in Angular templates.
 */
class TextSelectorHookParser {
  constructor(config, configResolver, tagHookFinder, bindingsValueManager) {
    this.configResolver = configResolver;
    this.tagHookFinder = tagHookFinder;
    this.bindingsValueManager = bindingsValueManager;
    this.savedBindings = {};
    this.config = this.configResolver.processConfig(config);
    this.name = this.config.name;
  }
  findHooks(content, context, options) {
    let hookPositions = this.config.enclosing ? this.tagHookFinder.findEnclosingTags(content, this.config.selector, this.config.bracketStyle, options) : this.tagHookFinder.findSingleTags(content, this.config.selector, this.config.bracketStyle, options);
    if (this.config.allowSelfClosing) {
      hookPositions = [...hookPositions, ...this.tagHookFinder.findSelfClosingTags(content, this.config.selector, this.config.bracketStyle, options)];
      hookPositions.sort((a, b) => a.openingTagStartIndex - b.openingTagStartIndex);
    }
    return hookPositions;
  }
  loadComponent(hookId, hookValue, context, childNodes, options) {
    return {
      component: this.config.component,
      hostElementTag: this.config.hostElementTag || this.config.selector,
      // If no hostElementTag specified, use selector (which in the case of TextSelectorHookParser is only allowed to be tag name)
      injector: this.config.injector,
      environmentInjector: this.config.environmentInjector
    };
  }
  getBindings(hookId, hookValue, context, options) {
    let hookBindings = this.savedBindings[hookId];
    // Parse bindings once from hookValue, then reuse on subsequent runs
    if (hookBindings === undefined) {
      hookBindings = this.createBindings(hookValue.openingTag);
      this.savedBindings[hookId] = hookBindings;
    }
    // (Re)evaluate if needed
    this.bindingsValueManager.checkInputBindings(hookBindings.inputs, context, this.config, options);
    this.bindingsValueManager.checkOutputBindings(hookBindings.outputs, this.config, options);
    return {
      inputs: this.getValuesFromSavedBindings(hookBindings.inputs),
      outputs: this.getValuesFromSavedBindings(hookBindings.outputs)
    };
  }
  // Bindings
  // --------------------------------------------------------------------------
  /**
   * Returns RichBindingData for Angular-style inputs & output attrs from an openingTag
   *
   * @param openingTag - The openingTag to inspect
   */
  createBindings(openingTag) {
    const rawInputs = this.collectRawInputs(openingTag);
    const inputBindings = {};
    for (const [rawInputKey, rawInputValue] of Object.entries(rawInputs)) {
      inputBindings[rawInputKey] = {
        raw: rawInputValue,
        parsed: false,
        value: null,
        boundContextVariables: {}
      };
    }
    const rawOutputs = this.collectRawOutputs(openingTag);
    const outputBindings = {};
    for (const [rawOutputKey, rawOutputValue] of Object.entries(rawOutputs)) {
      outputBindings[rawOutputKey] = {
        raw: rawOutputValue,
        parsed: false,
        value: null,
        boundContextVariables: {}
      };
    }
    return {
      inputs: inputBindings,
      outputs: outputBindings
    };
  }
  /**
   * Collects Angular-style inputs from an openingTag
   *
   * @param openingTag - The openingTag to inspect
   */
  collectRawInputs(openingTag) {
    const rawNoBracketInputs = this.getBindingsFromOpeningTag(openingTag, 'noBracketInputs', this.config.inputsBlacklist || null, this.config.inputsWhitelist || null);
    const rawBracketInputs = this.getBindingsFromOpeningTag(openingTag, 'bracketInputs', this.config.inputsBlacklist || null, this.config.inputsWhitelist || null);
    // NoBracketInputs are to be interpreted as plain strings, so wrap them in quotes
    for (const [noBracketInputName, noBracketInputValue] of Object.entries(rawNoBracketInputs)) {
      rawNoBracketInputs[noBracketInputName] = "'" + noBracketInputValue + "'";
    }
    // Merge both input objects
    return {
      ...rawNoBracketInputs,
      ...rawBracketInputs
    };
  }
  /**
   * Collects Angular-style outputs from an openingTag
   *
   * @param openingTag - The openingTag to inspect
   */
  collectRawOutputs(openingTag) {
    return this.getBindingsFromOpeningTag(openingTag, 'outputs', this.config.outputsBlacklist || null, this.config.outputsWhitelist || null);
  }
  /**
   * Collects Angular-style inputs or outputs from an openingTag
   *
   * @param type - What kind of bindings to extract
   * @param openingTag - The opening tag to inspect
   * @param blacklist - A list of inputs/outputs to blacklist
   * @param whitelist - A list of inputs/outputs to whitelist
   */
  getBindingsFromOpeningTag(openingTag, type, blacklist, whitelist) {
    const bindings = {};
    // Examples: https://regex101.com/r/17x3cc/16
    const attributeValuesOR = '(?:' + regexes.attributeValueDoubleQuotesRegex + '|' + regexes.attributeValueSingleQuotesRegex + ')';
    let attributeNameRegex;
    switch (type) {
      case 'noBracketInputs':
        attributeNameRegex = regexes.attributeNameNoBracketsRegex;
        break;
      case 'bracketInputs':
        attributeNameRegex = regexes.attributeNameBracketsRegex;
        break;
      case 'outputs':
        attributeNameRegex = regexes.attributeNameRoundBracketsRegex;
        break;
    }
    const attributeRegex = attributeNameRegex + '\=' + attributeValuesOR;
    const attributePattern = new RegExp(attributeRegex, 'gim');
    const attributeMatches = matchAll(openingTag, attributePattern);
    // Collect raw bindings
    for (const match of attributeMatches) {
      // Could be either of the attribute value capturing groups
      let rawBindingValue = match[2] || match[3];
      // If value is empty (someInput=""), it will return undefined for it. When using noBracketInputs, return empty string instead.
      if (rawBindingValue === undefined && type === 'noBracketInputs') {
        rawBindingValue = '';
      }
      bindings[match[1]] = rawBindingValue;
    }
    // Filter bindings
    const filteredBindings = {};
    for (const [bindingName, bindingValue] of Object.entries(bindings)) {
      if (blacklist && blacklist.includes(bindingName)) {
        continue;
      }
      if (whitelist && !whitelist.includes(bindingName)) {
        continue;
      }
      filteredBindings[bindingName] = bindingValue;
    }
    return filteredBindings;
  }
  /**
   * Transforms a RichBindingData object into a normal bindings object
   *
   * @param richBindingsObject - The object containing the RichBindingData
   */
  getValuesFromSavedBindings(richBindingsObject) {
    const result = {};
    for (const [key, value] of Object.entries(richBindingsObject)) {
      result[key] = value.value;
    }
    return result;
  }
}

/**
 * An element parser to load components with their bindings like in Angular templates.
 */
class ElementSelectorHookParser {
  constructor(config, configResolver, platformService, bindingsValueManager) {
    this.configResolver = configResolver;
    this.platformService = platformService;
    this.bindingsValueManager = bindingsValueManager;
    this.savedBindings = {};
    this.config = this.configResolver.processConfig(config);
    this.name = this.config.name;
  }
  findHookElements(contentElement, context, options) {
    return Array.from(this.platformService.querySelectorAll(contentElement, this.config.selector));
  }
  loadComponent(hookId, hookValue, context, childNodes, options) {
    // Always scrub potential []-input- and ()-output-attrs from anchor elements 
    this.scrubAngularBindingAttrs(hookValue.element);
    return {
      component: this.config.component,
      hostElementTag: this.config.hostElementTag,
      injector: this.config.injector,
      environmentInjector: this.config.environmentInjector
    };
  }
  getBindings(hookId, hookValue, context, options) {
    let hookBindings = this.savedBindings[hookId];
    // Parse bindings once from hookValue, then reuse on subsequent runs (raw values will never change as hookValue.element is a snapshot)
    if (hookBindings === undefined) {
      hookBindings = this.createBindings(hookValue.elementSnapshot);
      this.savedBindings[hookId] = hookBindings;
    }
    // (Re)evaluate if needed
    this.bindingsValueManager.checkInputBindings(hookBindings.inputs, context, this.config, options);
    this.bindingsValueManager.checkOutputBindings(hookBindings.outputs, this.config, options);
    return {
      inputs: this.getValuesFromSavedBindings(hookBindings.inputs),
      outputs: this.getValuesFromSavedBindings(hookBindings.outputs)
    };
  }
  // Bindings
  // --------------------------------------------------------------------------
  /**
   * Always removes angular-typical template attrs like []-input and ()-outputs from anchors
   *
   * @param anchorElement - The element to strub
   */
  scrubAngularBindingAttrs(anchorElement) {
    const attrsToScrub = Array.from(anchorElement.attributes).map(attrObj => attrObj.name).filter(attr => attr.startsWith('[') && attr.endsWith(']') || attr.startsWith('(') && attr.endsWith(')'));
    for (const attr of attrsToScrub) {
      this.platformService.removeAttribute(anchorElement, attr);
    }
  }
  /**
   * Returns RichBindingData for Angular-style inputs & output attrs from an element
   *
   * @param element - The element to inspect
   */
  createBindings(element) {
    const rawInputs = this.collectRawBindings(element, 'inputs', this.config.inputsBlacklist || null, this.config.inputsWhitelist || null);
    const inputBindings = {};
    for (const [rawInputKey, rawInputValue] of Object.entries(rawInputs)) {
      inputBindings[rawInputKey] = {
        raw: rawInputValue,
        parsed: false,
        value: null,
        boundContextVariables: {}
      };
    }
    const rawOutputs = this.collectRawBindings(element, 'outputs', this.config.outputsBlacklist || null, this.config.outputsWhitelist || null);
    const outputBindings = {};
    for (const [rawOutputKey, rawOutputValue] of Object.entries(rawOutputs)) {
      outputBindings[rawOutputKey] = {
        raw: rawOutputValue,
        parsed: false,
        value: null,
        boundContextVariables: {}
      };
    }
    return {
      inputs: inputBindings,
      outputs: outputBindings
    };
  }
  /**
   * Returns Angular-style inputs or output attrs from an element
   *
   * @param element - The element to inspect
   * @param type - Whether to return the inputs or outputs
   * @param blacklist - A list of inputs/outputs to blacklist
   * @param whitelist - A list of inputs/outputs to whitelist
   */
  collectRawBindings(element, type, blacklist, whitelist) {
    const bindings = {};
    // Collect raw bindings
    const attrNames = this.platformService.getAttributeNames(element);
    for (let attrName of attrNames) {
      if (type === 'inputs' && (!attrName.startsWith('(') || !attrName.endsWith(')')) || type === 'outputs' && attrName.startsWith('(') && attrName.endsWith(')')) {
        let binding = this.platformService.getAttribute(element, attrName);
        // If input has []-brackets: Transform empty attr to undefined
        if (type === 'inputs' && attrName.startsWith('[') && attrName.endsWith(']') && binding === '') {
          binding = undefined;
        }
        // If input has no []-brackets: Should be interpreted as plain strings, so wrap in quotes
        if (type === 'inputs' && (!attrName.startsWith('[') || !attrName.endsWith(']'))) {
          binding = `'${binding}'`;
        }
        // Trim [] and () brackets from attr name
        attrName = attrName.replace(/^\[|^\(|\]$|\)$/g, '');
        bindings[attrName] = binding;
      }
    }
    // Filter bindings
    const filteredBindings = {};
    for (const [bindingName, bindingValue] of Object.entries(bindings)) {
      if (blacklist && blacklist.includes(bindingName)) {
        continue;
      }
      if (whitelist && !whitelist.includes(bindingName)) {
        continue;
      }
      filteredBindings[bindingName] = bindingValue;
    }
    return filteredBindings;
  }
  /**
   * Transforms a RichBindingData object into a normal bindings object
   *
   * @param richBindingsObject - The object containing the RichBindingData
   */
  getValuesFromSavedBindings(richBindingsObject) {
    const result = {};
    for (const [key, value] of Object.entries(richBindingsObject)) {
      result[key] = value.value;
    }
    return result;
  }
}

/**
 * The default values for the SelectorHookParserConfig
 */
const selectorHookParserConfigDefaults = {
  component: undefined,
  name: undefined,
  parseWithRegex: false,
  selector: undefined,
  hostElementTag: undefined,
  injector: undefined,
  allowSelfClosing: true,
  enclosing: true,
  bracketStyle: {
    opening: '<',
    closing: '>'
  },
  parseInputs: true,
  unescapeStrings: true,
  inputsBlacklist: undefined,
  inputsWhitelist: undefined,
  outputsBlacklist: undefined,
  outputsWhitelist: undefined,
  allowContextInBindings: true,
  allowContextFunctionCalls: true
};

/**
 * A helper class for resolving a SelectorHookParserConfig
 */
class SelectorHookParserConfigResolver {
  constructor() {}
  /**
   * Overwrites the default parser config with a (partial) SelectorHookParserConfig object and returns the result
   *
   * @param userParserConfig - The (partial) SelectorHookParserConfig object
   */
  processConfig(userParserConfig) {
    const parserConfig = JSON.parse(JSON.stringify(selectorHookParserConfigDefaults));
    // component
    if (!userParserConfig || !userParserConfig.hasOwnProperty('component')) {
      throw Error('Missing the required "component" property for the SelectorHookParserConfig. Must be either the component class or a LazyLoadComponentConfig.');
    }
    parserConfig.component = userParserConfig.component;
    // If is class
    if (userParserConfig.component.hasOwnProperty('prototype')) {
      const compMeta = reflectComponentType(userParserConfig.component);
      parserConfig.selector = compMeta.selector;
      // If is LazyLoadingComponentConfig
    } else if (userParserConfig.component.hasOwnProperty('importPromise') && userParserConfig.component.hasOwnProperty('importName')) {
      if (!userParserConfig.hasOwnProperty('selector')) {
        throw Error(`When using lazy-loaded dynamic components, you have to specify the "selector" property in the parser config, as the real selector can't be known before the component is loaded.`);
      }
      // If is neither
    } else {
      throw Error('The "component" property in the SelectorHookParserConfig must either contain the component class or a LazyLoadComponentConfig.');
    }
    // name
    if (userParserConfig.hasOwnProperty('name')) {
      if (typeof userParserConfig.name !== 'string') {
        throw Error('The submitted "name" property in the SelectorHookParserConfig must be of type string, was ' + typeof userParserConfig.name);
      }
      parserConfig.name = userParserConfig.name;
    }
    // selector (defaults to component selector)
    if (userParserConfig.hasOwnProperty('selector')) {
      if (typeof userParserConfig.selector !== 'string') {
        throw Error('The submitted "selector" property in the SelectorHookParserConfig must be of type string, was ' + typeof userParserConfig.selector);
      }
      parserConfig.selector = userParserConfig.selector;
    }
    // hostElementTag
    if (userParserConfig.hasOwnProperty('hostElementTag')) {
      if (typeof userParserConfig.hostElementTag !== 'string') {
        throw Error('The submitted "hostElementTag" property in the SelectorHookParserConfig must be of type string, was ' + typeof userParserConfig.hostElementTag);
      }
      parserConfig.hostElementTag = userParserConfig.hostElementTag;
    }
    // parseWithRegex
    if (userParserConfig.hasOwnProperty('parseWithRegex')) {
      if (typeof userParserConfig.parseWithRegex !== 'boolean') {
        throw Error('The submitted "parseWithRegex" property in the SelectorHookParserConfig must be of type boolean, was ' + typeof userParserConfig.parseWithRegex);
      }
      parserConfig.parseWithRegex = userParserConfig.parseWithRegex;
    }
    // allowSelfClosing
    if (userParserConfig.hasOwnProperty('allowSelfClosing')) {
      if (typeof userParserConfig.allowSelfClosing !== 'boolean') {
        throw Error('The submitted "allowSelfClosing" property in the SelectorHookParserConfig must be of type boolean, was ' + typeof userParserConfig.allowSelfClosing);
      }
      parserConfig.allowSelfClosing = userParserConfig.allowSelfClosing;
    }
    // enclosing
    if (userParserConfig.hasOwnProperty('enclosing')) {
      if (typeof userParserConfig.enclosing !== 'boolean') {
        throw Error('The submitted "enclosing" property in the SelectorHookParserConfig must be of type boolean, was ' + typeof userParserConfig.enclosing);
      }
      parserConfig.enclosing = userParserConfig.enclosing;
    }
    // bracketStyle
    if (userParserConfig.hasOwnProperty('bracketStyle')) {
      if (typeof userParserConfig.bracketStyle !== 'object' || typeof userParserConfig.bracketStyle.opening !== 'string' || typeof userParserConfig.bracketStyle.closing !== 'string') {
        throw Error('The submitted "bracketStyle" property in the SelectorHookParserConfig must have the form {opening: string, closing: string}');
      }
      parserConfig.bracketStyle = userParserConfig.bracketStyle;
    }
    // injector (defaults to undefined)
    if (userParserConfig.hasOwnProperty('injector')) {
      parserConfig.injector = userParserConfig.injector;
    }
    // environmentInjector (defaults to undefined)
    if (userParserConfig.hasOwnProperty('environmentInjector')) {
      parserConfig.environmentInjector = userParserConfig.environmentInjector;
    }
    // unescapeStrings
    if (userParserConfig.hasOwnProperty('unescapeStrings')) {
      if (typeof userParserConfig.unescapeStrings !== 'boolean') {
        throw Error('The submitted "unescapeStrings" property in the SelectorHookParserConfig must be of type boolean, was ' + typeof userParserConfig.unescapeStrings);
      }
      parserConfig.unescapeStrings = userParserConfig.unescapeStrings;
    }
    // parseInputs
    if (userParserConfig.hasOwnProperty('parseInputs')) {
      if (typeof userParserConfig.parseInputs !== 'boolean') {
        throw Error('The submitted "parseInputs" property in the SelectorHookParserConfig must be of type boolean, was ' + typeof userParserConfig.parseInputs);
      }
      parserConfig.parseInputs = userParserConfig.parseInputs;
    }
    // inputsBlacklist
    if (userParserConfig.hasOwnProperty('inputsBlacklist')) {
      if (!Array.isArray(userParserConfig.inputsBlacklist)) {
        throw Error('The submitted "inputsBlacklist" property in the SelectorHookParserConfig must be an array of strings.');
      }
      for (const entry of userParserConfig.inputsBlacklist) {
        if (typeof entry !== 'string') {
          throw Error('All entries of the submitted "inputsBlacklist" property in the SelectorHookParserConfig must be of type string, ' + typeof entry + ' found.');
        }
      }
      parserConfig.inputsBlacklist = userParserConfig.inputsBlacklist;
    }
    // inputsWhitelist
    if (userParserConfig.hasOwnProperty('inputsWhitelist')) {
      if (!Array.isArray(userParserConfig.inputsWhitelist)) {
        throw Error('The submitted "inputsWhitelist" property in the SelectorHookParserConfig must be an array of strings.');
      }
      for (const entry of userParserConfig.inputsWhitelist) {
        if (typeof entry !== 'string') {
          throw Error('All entries of the submitted "inputsWhitelist" property in the SelectorHookParserConfig must be of type string, ' + typeof entry + ' found.');
        }
      }
      parserConfig.inputsWhitelist = userParserConfig.inputsWhitelist;
    }
    // outputsBlacklist
    if (userParserConfig.hasOwnProperty('outputsBlacklist')) {
      if (!Array.isArray(userParserConfig.outputsBlacklist)) {
        throw Error('The submitted "outputsBlacklist" property in the SelectorHookParserConfig must be an array of strings.');
      }
      for (const entry of userParserConfig.outputsBlacklist) {
        if (typeof entry !== 'string') {
          throw Error('All entries of the submitted "outputsBlacklist" property in the SelectorHookParserConfig must be of type string, ' + typeof entry + ' found.');
        }
      }
      parserConfig.outputsBlacklist = userParserConfig.outputsBlacklist;
    }
    // outputsWhitelist
    if (userParserConfig.hasOwnProperty('outputsWhitelist')) {
      if (!Array.isArray(userParserConfig.outputsWhitelist)) {
        throw Error('The submitted "outputsWhitelist" property in the SelectorHookParserConfig must be an array of strings.');
      }
      for (const entry of userParserConfig.outputsWhitelist) {
        if (typeof entry !== 'string') {
          throw Error('All entries of the submitted "outputsWhitelist" property in the SelectorHookParserConfig must be of type string, ' + typeof entry + ' found.');
        }
      }
      parserConfig.outputsWhitelist = userParserConfig.outputsWhitelist;
    }
    // allowContextInBindings
    if (userParserConfig.hasOwnProperty('allowContextInBindings')) {
      if (typeof userParserConfig.allowContextInBindings !== 'boolean') {
        throw Error('The submitted "allowContextInBindings" property in the SelectorHookParserConfig must be of type boolean, was ' + typeof userParserConfig.allowContextInBindings);
      }
      parserConfig.allowContextInBindings = userParserConfig.allowContextInBindings;
    }
    // allowContextFunctionCalls
    if (userParserConfig.hasOwnProperty('allowContextFunctionCalls')) {
      if (typeof userParserConfig.allowContextFunctionCalls !== 'boolean') {
        throw Error('The submitted "allowContextFunctionCalls" property in the SelectorHookParserConfig must be of type boolean, was ' + typeof userParserConfig.allowContextFunctionCalls);
      }
      parserConfig.allowContextFunctionCalls = userParserConfig.allowContextFunctionCalls;
    }
    return parserConfig;
  }
  static {
    this.ɵfac = function SelectorHookParserConfigResolver_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SelectorHookParserConfigResolver)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SelectorHookParserConfigResolver,
      factory: SelectorHookParserConfigResolver.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SelectorHookParserConfigResolver, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
const PLATFORM_SERVICE = new InjectionToken('An injection token to retrieve an optionally user-provided PlatformService');

/**
 * General implementation of PlatformService suited for both the standard browser and server environments
 */
class DefaultPlatformService {
  constructor(document, rendererFactory, sanitizer) {
    this.document = document;
    this.rendererFactory = rendererFactory;
    this.sanitizer = sanitizer;
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }
  getNgVersion() {
    if (typeof this.document !== "undefined") {
      const versionElement = this.querySelectorAll(this.document, '[ng-version]')?.[0];
      const versionAttr = versionElement?.getAttribute('ng-version');
      if (versionAttr) {
        return parseInt(versionAttr, 10);
      }
    }
    return null;
  }
  sanitize(content) {
    return this.sanitizer.sanitize(SecurityContext.HTML, content) || '';
  }
  createElement(tagName) {
    return this.renderer.createElement(tagName);
  }
  sortElements(a, b) {
    if (a === b) return 0;
    if (!a.compareDocumentPosition) {
      // support for IE8 and below
      return a.sourceIndex - b.sourceIndex;
    }
    if (a.compareDocumentPosition(b) & 2) {
      // b comes before a
      return 1;
    }
    return -1;
  }
  cloneElement(element) {
    return element.cloneNode(true);
  }
  getTagName(element) {
    return element.tagName;
  }
  getOpeningTag(element) {
    // Approach by: https://stackoverflow.com/a/55859966/3099523
    const innerLength = element.innerHTML.length;
    const outerLength = element.outerHTML.length;
    // Check for self-closing elements
    const openingTagLength = element.outerHTML[outerLength - 2] === '/' ? outerLength : outerLength - innerLength - element.tagName.length - 3;
    return element.outerHTML.slice(0, openingTagLength);
  }
  getClosingTag(element) {
    return element.outerHTML.slice(element.outerHTML.length - element.tagName.length - 3);
  }
  getAttributeNames(element) {
    return typeof element.getAttributeNames === 'function' ? element.getAttributeNames() : [];
  }
  getAttribute(element, attributeName) {
    return typeof element.getAttribute === 'function' ? element.getAttribute(attributeName) : null;
  }
  setAttribute(element, attributeName, value) {
    this.renderer.setAttribute(element, attributeName, value);
  }
  removeAttribute(element, attributeName) {
    this.renderer.removeAttribute(element, attributeName);
  }
  getParentNode(element) {
    try {
      return this.renderer.parentNode(element);
    } catch (e) {
      return null;
    }
  }
  querySelectorAll(parentElement, selector) {
    return Array.from(parentElement.querySelectorAll(selector));
  }
  getChildNodes(node) {
    return Array.prototype.slice.call(node.childNodes);
  }
  appendChild(parentElement, childElement) {
    this.renderer.appendChild(parentElement, childElement);
  }
  insertBefore(parentElement, childElement, referenceElement) {
    this.renderer.insertBefore(parentElement, childElement, referenceElement);
  }
  clearChildNodes(element) {
    if (element) {
      while (element.firstChild) {
        this.removeChild(element, element.firstChild);
      }
    }
  }
  removeChild(parentElement, childElement) {
    parentElement.removeChild(childElement);
  }
  getInnerContent(element) {
    return element.innerHTML;
  }
  setInnerContent(element, content) {
    if (element) {
      element.innerHTML = content;
    }
  }
  isTextNode(element) {
    return element.nodeType === Node.TEXT_NODE;
  }
  createTextNode(content) {
    return document.createTextNode(content);
  }
  getTextContent(element) {
    return element.textContent;
  }
  dispatchEvent(element, name, payload) {
    element.dispatchEvent(new CustomEvent(name, {
      detail: payload,
      bubbles: true
    }));
  }
  static {
    this.ɵfac = function DefaultPlatformService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DefaultPlatformService)(i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(i0.RendererFactory2), i0.ɵɵinject(i1.DomSanitizer));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DefaultPlatformService,
      factory: DefaultPlatformService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultPlatformService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.RendererFactory2
  }, {
    type: i1.DomSanitizer
  }], null);
})();

/**
 * Wrapper class that either calls user-provided PlatformService methods or falls back to default implementations
 */
class AutoPlatformService {
  constructor(userPlatformService, defaultPlatformService) {
    this.userPlatformService = userPlatformService;
    this.defaultPlatformService = defaultPlatformService;
  }
  getFor(methodName) {
    if (this.userPlatformService && typeof this.userPlatformService[methodName] === 'function') {
      return this.userPlatformService;
    } else {
      return this.defaultPlatformService;
    }
  }
  getNgVersion() {
    return this.getFor('getNgVersion').getNgVersion();
  }
  sanitize(content) {
    return this.getFor('sanitize').sanitize(content);
  }
  createElement(tagName) {
    return this.getFor('createElement').createElement(tagName);
  }
  sortElements(a, b) {
    return this.getFor('sortElements').sortElements(a, b);
  }
  cloneElement(element) {
    return this.getFor('cloneElement').cloneElement(element);
  }
  getTagName(element) {
    return this.getFor('getTagName').getTagName(element);
  }
  getOpeningTag(element) {
    return this.getFor('getOpeningTag').getOpeningTag(element);
  }
  getClosingTag(element) {
    return this.getFor('getClosingTag').getClosingTag(element);
  }
  getAttributeNames(element) {
    return this.getFor('getAttributeNames').getAttributeNames(element);
  }
  getAttribute(element, attributeName) {
    return this.getFor('getAttribute').getAttribute(element, attributeName);
  }
  setAttribute(element, attributeName, value) {
    return this.getFor('setAttribute').setAttribute(element, attributeName, value);
  }
  removeAttribute(element, attributeName) {
    return this.getFor('removeAttribute').removeAttribute(element, attributeName);
  }
  getParentNode(element) {
    return this.getFor('getParentNode').getParentNode(element);
  }
  querySelectorAll(parentElement, selector) {
    return this.getFor('querySelectorAll').querySelectorAll(parentElement, selector);
  }
  getChildNodes(node) {
    return this.getFor('getChildNodes').getChildNodes(node);
  }
  appendChild(parentElement, childElement) {
    return this.getFor('appendChild').appendChild(parentElement, childElement);
  }
  insertBefore(parentElement, childElement, referenceElement) {
    return this.getFor('insertBefore').insertBefore(parentElement, childElement, referenceElement);
  }
  clearChildNodes(element) {
    return this.getFor('clearChildNodes').clearChildNodes(element);
  }
  removeChild(parentElement, childElement) {
    return this.getFor('removeChild').removeChild(parentElement, childElement);
  }
  getInnerContent(element) {
    return this.getFor('getInnerContent').getInnerContent(element);
  }
  setInnerContent(element, content) {
    return this.getFor('setInnerContent').setInnerContent(element, content);
  }
  isTextNode(element) {
    return this.getFor('isTextNode').isTextNode(element);
  }
  createTextNode(content) {
    return this.getFor('createTextNode').createTextNode(content);
  }
  getTextContent(element) {
    return this.getFor('getTextContent').getTextContent(element);
  }
  dispatchEvent(element, name, payload) {
    return this.getFor('dispatchEvent').dispatchEvent(element, name, payload);
  }
  static {
    this.ɵfac = function AutoPlatformService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AutoPlatformService)(i0.ɵɵinject(PLATFORM_SERVICE, 8), i0.ɵɵinject(DefaultPlatformService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AutoPlatformService,
      factory: AutoPlatformService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutoPlatformService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [PLATFORM_SERVICE]
    }]
  }, {
    type: DefaultPlatformService
  }], null);
})();

/**
 * A utility service to print logs and warnings
 */
class Logger {
  constructor(platformId) {
    this.platformId = platformId;
  }
  log(content, options) {
    this.handleLog(content, options, 'log');
  }
  warn(content, options) {
    this.handleLog(content, options, 'warn');
  }
  error(content, options) {
    this.handleLog(content, options, 'error');
  }
  /**
   * Logs an array of content according to the submitted options
   *
   * @param content - The content to log
   * @param options - The current ParseOptions
   * @param method - The console method to use
   */
  handleLog(content, options, method) {
    if (options.logOptions?.dev && this.isDevMode() && isPlatformBrowser(this.platformId) || options.logOptions?.prod && !this.isDevMode() && isPlatformBrowser(this.platformId) || options.logOptions?.ssr && !isPlatformBrowser(this.platformId)) {
      console[method](...content);
    }
  }
  /**
   * Use local method that is easier to mock in tests
   */
  isDevMode() {
    return isDevMode();
  }
  static {
    this.ɵfac = function Logger_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || Logger)(i0.ɵɵinject(PLATFORM_ID));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: Logger,
      factory: Logger.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Logger, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], null);
})();

/**
 * A utility service to easily parse hooks from text content
 */
class HookFinder {
  constructor(logger) {
    this.logger = logger;
  }
  /**
   * Finds all text hooks in a piece of content, e.g. <hook>...</hook>, and returns their positions
   *
   * @param content - The text to parse
   * @param openingTagRegex - The regex for the opening tag
   * @param closingTagRegex - The regex for the closing tag
   * @param includeNested - Whether to include nested hooks in the result
   * @param options - The current ParseOptions
   */
  find(content, openingTagRegex, closingTagRegex, includeNested, options = getParseOptionDefaults()) {
    if (!closingTagRegex) {
      return this.findSingletagHooks(content, openingTagRegex);
    } else {
      return this.findEnclosingHooks(content, openingTagRegex, closingTagRegex, includeNested, options);
    }
  }
  /**
   * Finds all text hooks that are non-enclosing in a piece of text, e.g. <hook>
   *
   * @param content - The text to search
   * @param hookRegex - The regex to use for the hook
   */
  findSingletagHooks(content, hookRegex) {
    const result = [];
    // Find all hooks
    const openingTagMatches = matchAll(content, hookRegex);
    for (const match of openingTagMatches) {
      result.push({
        openingTagStartIndex: match.index,
        openingTagEndIndex: match.index + match[0].length,
        closingTagStartIndex: null,
        closingTagEndIndex: null
      });
    }
    return result;
  }
  /**
   * Finds all text hooks that are enclosing in a piece of text, e.g. <hook>...</hook>
   *
   * Correctly finding enclosing hooks requires a programmatic parser rather then just regex alone, as regex cannot handle
   * patterns that are potentially nested within themselves.
   *
   * - If the content between the opening and closing is lazy (.*?), it would take the first closing tag after the opening tag,
   *   regardless if it belongs to the opening tag or actually a nested hook. This would falsely match the first and third tag
   *   in this example: '<hook><hook></hook></hook>'
   *
   * - If the content between the opening and closing is greedy (.*), it would only end on the last closing tag in the string,
   *   ignoring any previous closing tags. This would falsely match the first and fourth tag in this example:
   *   '<hook></hook><hook></hook>'
   *
   * There is no regex that works for both scenarios. This method therefore manually counts and compares the opening tags with the closing tags.
   *
   * @param content - The text to parse
   * @param openingTagRegex - The regex for the opening tag
   * @param closingTagRegex - The regex for the closing tag
   * @param includeNested - Whether to include nested hooks in the result
   * @param options - The current parseOptions
   */
  findEnclosingHooks(content, openingTagRegex, closingTagRegex, includeNested, options = getParseOptionDefaults()) {
    const allTags = [];
    const result = [];
    // Find all opening tags
    const openingTagMatches = matchAll(content, openingTagRegex);
    for (const match of openingTagMatches) {
      allTags.push({
        isOpening: true,
        value: match[0],
        startIndex: match.index,
        endIndex: match.index + match[0].length
      });
    }
    // Find all closing tags
    const closingTagMatches = matchAll(content, closingTagRegex);
    for (const match of closingTagMatches) {
      allTags.push({
        isOpening: false,
        value: match[0],
        startIndex: match.index,
        endIndex: match.index + match[0].length
      });
    }
    // Sort by startIndex
    allTags.sort((a, b) => a.startIndex - b.startIndex);
    // Create HookPositions by figuring out which opening tag belongs to which closing tag
    const openedTags = [];
    allTagsLoop: for (const [index, tag] of allTags.entries()) {
      // Any subsequent tag is only allowed to start after previous tag has ended
      if (index > 0 && tag.startIndex < allTags[index - 1].endIndex) {
        this.logger.warn(['Syntax error - New tag "' + tag.value + '" started at position ' + tag.startIndex + ' before previous tag "' + allTags[index - 1].value + '" ended at position ' + allTags[index - 1].endIndex + '. Ignoring.'], options);
        continue;
      }
      // Opening or closing tag?
      if (tag.isOpening) {
        openedTags.push(tag);
      } else {
        // Syntax error: Closing tag without preceding opening tag. Syntax error.
        if (openedTags.length === 0) {
          this.logger.warn(['Syntax error - Closing tag without preceding opening tag found: "' + tag.value + '". Ignoring.'], options);
          continue;
        }
        // If nested hooks not allowed and more than one tag is open, discard both this closing tag and the latest opening tag
        if (includeNested === false && openedTags.length > 1) {
          openedTags.pop();
          continue;
        }
        // Valid hook! Add to result array
        const openingTag = openedTags[openedTags.length - 1];
        result.push({
          openingTagStartIndex: openingTag.startIndex,
          openingTagEndIndex: openingTag.startIndex + openingTag.value.length,
          closingTagStartIndex: tag.startIndex,
          closingTagEndIndex: tag.startIndex + tag.value.length
        });
        openedTags.pop();
      }
    }
    if (openedTags.length > 0) {
      this.logger.warn(['Syntax error - Opening tags without corresponding closing tags found.'], options);
    }
    return result;
  }
  static {
    this.ɵfac = function HookFinder_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HookFinder)(i0.ɵɵinject(Logger));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HookFinder,
      factory: HookFinder.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HookFinder, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: Logger
  }], null);
})();

/**
 * A utility service for the TextSelectorHookParser that finds Angular component selectors in the content
 */
class TagHookFinder {
  constructor(hookFinder) {
    this.hookFinder = hookFinder;
  }
  /**
   * Finds singletag Angular component selectors
   *
   * @param content - The content to parse
   * @param selector - The Angular selector to find
   * @param bracketStyle - What bracket style to use
   * @param options - The current ParseOptions
   */
  findSingleTags(content, selector, bracketStyle = {
    opening: '<',
    closing: '>'
  }, options) {
    // Create opening tag regex
    const openingTagRegex = this.generateOpeningTagRegex(selector, bracketStyle);
    return this.hookFinder.find(content, openingTagRegex, undefined, undefined, options);
  }
  /**
   * Finds enclosing Angular component selectors
   *
   * @param content - The content to parse
   * @param selector - The Angular selector to find
   * @param bracketStyle - What bracket style to use
   * @param options - The current ParseOptions
   */
  findEnclosingTags(content, selector, bracketStyle = {
    opening: '<',
    closing: '>'
  }, options) {
    // Create opening and closing tag regex
    const openingTagRegex = this.generateOpeningTagRegex(selector, bracketStyle);
    const closingTagRegex = this.generateClosingTagRegex(selector, bracketStyle);
    return this.hookFinder.find(content, openingTagRegex, closingTagRegex, true, options);
  }
  /**
   * Finds self-closing Angular component selectors
   *
   * @param content - The content to parse
   * @param selector - The Angular selector to find
   * @param bracketStyle - What bracket style to use
   * @param options - The current ParseOptions
   */
  findSelfClosingTags(content, selector, bracketStyle = {
    opening: '<',
    closing: '>'
  }, options) {
    const selfClosingTagRegex = this.generateOpeningTagRegex(selector, bracketStyle, true);
    return this.hookFinder.find(content, selfClosingTagRegex, undefined, undefined, options);
  }
  // Hook regex helper
  // ----------------------------------------------------------------------------------------------------------------------------------------
  /**
   * Generates the opening tag regex for a standard Angular component selector
   *
   * @param selector - The selector name
   * @param bracketStyle - What bracket style to use
   */
  generateOpeningTagRegex(selector, bracketStyle = {
    opening: '<',
    closing: '>'
  }, selfClosing = false) {
    // Find opening tag of hook lazily
    // Examples for this regex: https://regex101.com/r/Glyt2Z/1
    // Features: Ignores redundant whitespace & line-breaks, supports n attributes, both normal and []-attribute-name-syntax, both ' and " as attribute-value delimiters
    const openingArrow = this.escapeRegex(bracketStyle.opening);
    const selectorName = this.escapeRegex(selector);
    const closingArrow = (selfClosing ? '\\/' : '') + this.escapeRegex(bracketStyle.closing);
    const space = '\\s';
    const attributeValuesOR = '(?:' + regexes.attributeValueDoubleQuotesRegex + '|' + regexes.attributeValueSingleQuotesRegex + ')';
    const attributes = '(?:' + space + '+' + regexes.attributeNameRegex + '\=' + attributeValuesOR + ')+';
    const fullRegex = openingArrow + selectorName + '(?:' + space + '*' + closingArrow + '|' + attributes + space + '*' + closingArrow + ')';
    const regexObject = new RegExp(fullRegex, 'gim');
    return regexObject;
  }
  /**
   * Generates the opening tag regex for a standard hook
   *
   * @param selector - The selector of the hook
   * @param bracketStyle - What bracket style to use
   */
  generateClosingTagRegex(selector, bracketStyle = {
    opening: '<',
    closing: '>'
  }) {
    const openingArrow = this.escapeRegex(bracketStyle.opening) + '\/';
    const selectorName = this.escapeRegex(selector);
    const closingArrow = this.escapeRegex(bracketStyle.closing);
    const fullRegex = openingArrow + selectorName + closingArrow;
    const regexObject = new RegExp(fullRegex, 'gim');
    return regexObject;
  }
  /**
   * Safely escapes a string for use in regex
   *
   * @param text - The string to escape
   */
  escapeRegex(text) {
    return text.replace(new RegExp('[-\\/\\\\^$*+?.()|[\\]{}]', 'g'), '\\$&');
  }
  static {
    this.ɵfac = function TagHookFinder_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TagHookFinder)(i0.ɵɵinject(HookFinder));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TagHookFinder,
      factory: TagHookFinder.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TagHookFinder, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: HookFinder
  }], null);
})();

/**
 * A service that provides various functions for en- and decoding data type strings in order to make them
 * meaningfully parseable by regex
 */
class DataTypeEncoder {
  // Substrings
  // -----------------------------------------------------
  /**
   * Finds all substrings in a piece of text and replaces all special characters within with @@@...@@@-placeholders.
   *
   * @param text - The text to parse for substrings
   */
  encodeSubstrings(text) {
    // Get a list of all quotes (that are not preceded by an escaping backslash)
    const singleQuotes = matchAll(text, /'/gm).filter(match => match.index === 0 || text[match.index - 1] !== '\\');
    const doubleQuotes = matchAll(text, /"/gm).filter(match => match.index === 0 || text[match.index - 1] !== '\\');
    const graveQuotes = matchAll(text, /`/gm).filter(match => match.index === 0 || text[match.index - 1] !== '\\');
    const allQuotes = [...singleQuotes, ...doubleQuotes, ...graveQuotes];
    allQuotes.sort((a, b) => a['index'] - b['index']);
    // Create quotes text segments
    const quoteSegments = [];
    let outermostOpenedQuote = null;
    for (const quote of allQuotes) {
      if (!outermostOpenedQuote) {
        outermostOpenedQuote = quote;
      } else {
        if (outermostOpenedQuote[0] === quote[0]) {
          quoteSegments.push({
            startIndex: outermostOpenedQuote.index + 1,
            endIndex: quote['index']
          });
          outermostOpenedQuote = null;
        }
      }
    }
    if (outermostOpenedQuote !== null) {
      throw Error('Input parse error. String was opened, but not closed.');
    }
    // Encode quote segments
    const encodedBracketsText = this.encodeTextSegments(text, quoteSegments, this.encodeStringSpecialChars);
    return encodedBracketsText;
  }
  /**
   * Encodes all special characters that might be confused as code syntax in a piece of string
   *
   * @param text - The text to encode
   */
  encodeStringSpecialChars(text) {
    text = text.replace(/'/g, '@@@singlequote@@@');
    text = text.replace(/"/g, '@@@doublequote@@@');
    text = text.replace(/`/g, '@@@gravequote@@@');
    text = text.replace(/:/g, '@@@colon@@@');
    text = text.replace(/;/g, '@@@semicolon@@@');
    text = text.replace(/\./g, '@@@dot@@@');
    text = text.replace(/,/g, '@@@comma@@@');
    text = text.replace(/\\/g, '@@@backslash@@@');
    text = text.replace(/\(/g, '@@@openRoundBracket@@@');
    text = text.replace(/\)/g, '@@@closeRoundBracket@@@');
    text = text.replace(/\[/g, '@@@openSquareBracket@@@');
    text = text.replace(/\]/g, '@@@closeSquareBracket@@@');
    text = text.replace(/\{/g, '@@@openCurlyBracket@@@');
    text = text.replace(/\}/g, '@@@closeCurlyBracket@@@');
    return text;
  }
  /**
   * Decodes the special characters again
   *
   * @param text - The text to decode
   */
  decodeStringSpecialChars(text) {
    text = text.replace(/@@@singlequote@@@/g, '\'');
    text = text.replace(/@@@doublequote@@@/g, '"');
    text = text.replace(/@@@gravequote@@@/g, '`');
    text = text.replace(/@@@colon@@@/g, ':');
    text = text.replace(/@@@semicolon@@@/g, ';');
    text = text.replace(/@@@dot@@@/g, '.');
    text = text.replace(/@@@comma@@@/g, ',');
    text = text.replace(/@@@backslash@@@/g, '\\');
    text = text.replace(/@@@openRoundBracket@@@/g, '(');
    text = text.replace(/@@@closeRoundBracket@@@/g, ')');
    text = text.replace(/@@@openSquareBracket@@@/g, '[');
    text = text.replace(/@@@closeSquareBracket@@@/g, ']');
    text = text.replace(/@@@openCurlyBracket@@@/g, '{');
    text = text.replace(/@@@closeCurlyBracket@@@/g, '}');
    return text;
  }
  // Subfunctions
  // -----------------------------------------------------
  /**
   * Finds all subfunctions in a piece of text and replaces their round brackets with @@@...@@@-placeholders.
   *
   * @param text - The text to parse for substrings
   */
  encodeSubfunctions(text) {
    const openingBrackets = matchAll(text, /\(/gm);
    const closingBrackets = matchAll(text, /\)/gm);
    const allBrackets = [...openingBrackets, ...closingBrackets];
    allBrackets.sort((a, b) => a['index'] - b['index']);
    // Create functions text segments
    const functionSegments = [];
    const openedBrackets = [];
    for (const bracket of allBrackets) {
      if (bracket[0] === '(') {
        openedBrackets.push(bracket);
      } else {
        if (openedBrackets.length === 0) {
          throw Error('Input parse error. Closed function bracket without opening it first.');
        }
        // Only collect the outermost function brackets, not nested ones
        if (openedBrackets.length === 1) {
          functionSegments.push({
            startIndex: openedBrackets[0].index + 1,
            endIndex: bracket['index']
          });
        }
        openedBrackets.pop();
      }
    }
    if (openedBrackets.length !== 0) {
      throw Error('Input parse error. Opened function bracket without closing it.');
    }
    // Encode quote segments
    const encodedFunctionsText = this.encodeTextSegments(text, functionSegments, this.encodeFunctionBrackets);
    return encodedFunctionsText;
  }
  /**
   * Encodes all round brackets with harmless placeholders
   *
   * @param text - The text to encode
   */
  encodeFunctionBrackets(text) {
    text = text.replace(/\(/g, '@@@fnOpenBracket@@@');
    text = text.replace(/\)/g, '@@@fnCloseBracket@@@');
    return text;
  }
  /**
   * Decodes all round brackets again
   *
   * @param text - The text to decode
   */
  decodeFunctionBrackets(text) {
    text = text.replace(/@@@fnOpenBracket@@@/g, '\(');
    text = text.replace(/@@@fnCloseBracket@@@/g, '\)');
    return text;
  }
  // Subbrackets
  // -----------------------------------------------------
  /**
   * Finds all subbrackets in a piece of text and replaces their brackets with @@@...@@@-placeholders.
   *
   * @param text - The text to parse for substrings
   */
  encodeVariableSubbrackets(text) {
    // Property accessor opening brackets can be identified by what they are preceded by.
    // Must be a) text, b) closing square bracket or c) closing round bracket. Arrays can't be preceded by any of these.
    const variableOpeningBracketsWithLookbehinds = '(?<=[a-zA-Z_$\\]\)])\\['; // Too new for many older browsers
    const variableOpeningBrackets = '(?:[a-zA-Z_$\\]\)])(\\[)';
    const openingBrackets = matchAll(text, new RegExp(variableOpeningBrackets, 'gm'));
    // Note: Can't simply find closing brackets as well (as is done in the other encoder functions), because the closing
    // bracket doesn't have a uniquely identifiable syntax. Might also be array endings.
    // Find the corresponding closing bracket for each opening bracket by parsing the following brackets
    const bracketSegments = [];
    for (const openingBracket of openingBrackets) {
      const followingText = text.substring(openingBracket.index + 2); // openingBracket.index + 2, b/c the regex starts at the character before the opening bracket and followingText is supposed to start after the opening bracket
      const followingOpeningBrackets = matchAll(followingText, /\[/gm);
      const followingClosingBrackets = matchAll(followingText, /\]/gm);
      const allFollowingBrackets = [...followingOpeningBrackets, ...followingClosingBrackets];
      allFollowingBrackets.sort((a, b) => a['index'] - b['index']);
      let openedBrackets = 1; // Start with the first opening bracket already counted
      for (const followingBracket of allFollowingBrackets) {
        openedBrackets = followingBracket[0] === ']' ? openedBrackets - 1 : openedBrackets + 1;
        if (openedBrackets === 0) {
          bracketSegments.push({
            startIndex: openingBracket.index + 2,
            endIndex: openingBracket.index + 2 + followingBracket['index']
          });
          break;
        }
      }
      if (openedBrackets !== 0) {
        throw Error('Input parse error. Opened bracket without closing it.');
      }
    }
    // Throw out nested brackets
    const outerBracketSegments = [];
    for (const bracketSegment of bracketSegments) {
      if (outerBracketSegments.length === 0) {
        outerBracketSegments.push(bracketSegment);
      } else {
        if (outerBracketSegments[outerBracketSegments.length - 1].endIndex < bracketSegment.startIndex) {
          outerBracketSegments.push(bracketSegment);
        }
      }
    }
    // Encode bracket segments
    const encodedBracketsText = this.encodeTextSegments(text, outerBracketSegments, this.encodeVariableBrackets);
    return encodedBracketsText;
  }
  /**
   * Encodes all brackets with harmless placeholders
   *
   * @param text - The text to encode
   */
  encodeVariableBrackets(text) {
    text = text.replace(/\[/g, '@@@variableOpeningBracket@@@');
    text = text.replace(/\]/g, '@@@variableClosingBracket@@@');
    return text;
  }
  /**
   * Decodes all brackets again
   *
   * @param text - The text to encode
   */
  decodeVariableBrackets(text) {
    text = text.replace(/@@@variableOpeningBracket@@@/g, '\[');
    text = text.replace(/@@@variableClosingBracket@@@/g, '\]');
    return text;
  }
  // Context var placeholder
  // -----------------------------------------------------
  /**
   * Transforms a context var (that is already encoded for substrings, subfunctions and subbrackets) into a string placeholder
   * by encoding the context var syntax itself. This is so that can be safely parsed by JSON.parse() as a string and also so it
   * won't be misinterpreted by other regexes looking for JSON syntax (especially arrays b/c of context-var []-property-brackets)
   *
   * @param contextVar - The context var to transform
   */
  transformContextVarIntoPlacerholder(contextVar) {
    // Replace context. with __CXT__
    contextVar = '__CXT__' + contextVar.substring(7);
    // Encode variable syntax
    contextVar = contextVar.replace(/\"/g, '@@@cxtDoubleQuote@@@');
    contextVar = contextVar.replace(/\./g, '@@@cxtDot@@@');
    contextVar = contextVar.replace(/\[/g, '@@@cxtOpenSquareBracket@@@');
    contextVar = contextVar.replace(/\]/g, '@@@cxtCloseSquareBracket@@@');
    contextVar = contextVar.replace(/\(/g, '@@@cxtOpenRoundBracket@@@');
    contextVar = contextVar.replace(/\)/g, '@@@cxtCloseRoundBracket@@@');
    return contextVar;
  }
  /**
   * Transforms a context var placeholder back into the actual context var
   *
   * @param contextVar - The placeholder context var
   */
  transformPlaceholderIntoContextVar(contextVar) {
    contextVar = 'context' + contextVar.substring(7);
    contextVar = contextVar.replace(/@@@cxtDoubleQuote@@@/g, '"');
    contextVar = contextVar.replace(/@@@cxtDot@@@/g, '.');
    contextVar = contextVar.replace(/@@@cxtOpenSquareBracket@@@/g, '[');
    contextVar = contextVar.replace(/@@@cxtCloseSquareBracket@@@/g, ']');
    contextVar = contextVar.replace(/@@@cxtOpenRoundBracket@@@/g, '(');
    contextVar = contextVar.replace(/@@@cxtCloseRoundBracket@@@/g, ')');
    return contextVar;
  }
  // Other
  // -----------------------------------------------------
  /**
   * Takes a piece of text as well as array of TextSegments and encodes them with the help of an encodingFunction
   * The encoded text is then automatically assembled and returned.
   *
   * @param text - The text in question
   * @param specialTextSegments - The segments in the text to encode
   * @param encodingFunction - The encoding function to use
   */
  encodeTextSegments(text, specialTextSegments, encodingFunction) {
    // 1. Divide whole text into two types of segments: Those to be encoded and those to be left as they are
    const allTextSegments = [];
    for (const specialTextSegment of specialTextSegments) {
      // Push normal text segment since last special segment
      const lastSegmentEndIndex = allTextSegments.length === 0 ? 0 : allTextSegments[allTextSegments.length - 1].endIndex;
      allTextSegments.push({
        type: 'text',
        startIndex: lastSegmentEndIndex,
        endIndex: specialTextSegment.startIndex,
        string: text.substring(lastSegmentEndIndex, specialTextSegment.startIndex)
      });
      // Push next special segment
      allTextSegments.push({
        type: 'special',
        startIndex: specialTextSegment.startIndex,
        endIndex: specialTextSegment.endIndex,
        string: text.substring(specialTextSegment.startIndex, specialTextSegment.endIndex)
      });
    }
    // Add text segment for trailing text after last special segment
    const lastBracketEndIndex = allTextSegments.length === 0 ? 0 : allTextSegments[allTextSegments.length - 1].endIndex;
    allTextSegments.push({
      type: 'text',
      startIndex: lastBracketEndIndex,
      endIndex: text.length - 1,
      string: text.substring(lastBracketEndIndex)
    });
    // 2. Encode all special segments
    for (const segment of allTextSegments) {
      if (segment.type === 'special') {
        segment.string = encodingFunction(segment.string);
      }
    }
    // 3. Concat everything together again
    let encodedString = '';
    for (const segment of allTextSegments) {
      encodedString += segment.string;
    }
    return encodedString;
  }
  /**
   * Strips all escaping backslashes from a piece of text
   *
   * @param text - The text in question
   */
  stripSlashes(text) {
    return text.replace(/\\(.)/g, '$1');
    // return text.replace(new RegExp('\\\\(.)', 'g'), '$1');
  }
  /**
   * Escapes all double quotes in a piece of text
   *
   * @param text - The text in question
   */
  escapeDoubleQuotes(text) {
    const result = text.replace(/\\/g, '\\\\').replace(/\"/g, '\\"');
    return result;
  }
  static {
    this.ɵfac = function DataTypeEncoder_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DataTypeEncoder)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DataTypeEncoder,
      factory: DataTypeEncoder.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DataTypeEncoder, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * A parser that can evaluate stringified variables and turn them into their corresponding data types
 */
class DataTypeParser {
  constructor(dataTypeEncoder, logger) {
    this.dataTypeEncoder = dataTypeEncoder;
    this.logger = logger;
  }
  /**
   * Takes a string containing a Javascript data type as it would appear in code, such a number ('15'), a string ('"hello"'),
   * an array ('[1,2,3]'), an object ('{prop: "something"}') etc., and evaluates it to be an an actual variable.
   *
   * Note: This function works without invoking eval() and instead uses JSON.parse() for the heavy lifting. As such, it should be safe
   * to use and should cover most forms of input.
   *
   * @param dataTypeString - The string to parse
   * @param context - (optional) A context object to load variables from
   * @param event - (optional) An event object to place $event vars with
   * @param unescapeStrings - (optional) Whether to unescape strings or not
   * @param trackContextVariables - (optional) An object that will be filled out with all found context vars
   * @param allowContextFunctionCalls - (optional) Whether to allow function calls in context vars
   * @param options - (optional) The current parseOptions
   */
  evaluate(dataTypeString, context = {}, event, unescapeStrings = true, trackContextVariables = {}, allowContextFunctionCalls = true, options = getParseOptionDefaults()) {
    // a) Simple types
    // --------------------
    // null or undefined
    if (dataTypeString === 'null') {
      return null;
    }
    if (dataTypeString === 'undefined') {
      return undefined;
    }
    // boolean
    if (dataTypeString === 'true') {
      return true;
    }
    if (dataTypeString === 'false') {
      return false;
    }
    // number
    if (!isNaN(dataTypeString)) {
      return parseInt(dataTypeString, 10);
    }
    // string
    if (dataTypeString.startsWith('"') && dataTypeString.endsWith('"') || dataTypeString.startsWith("'") && dataTypeString.endsWith("'") || dataTypeString.startsWith("`") && dataTypeString.endsWith("`")) {
      // Remove outer quotes, potentially unescape and return
      let decodedString = dataTypeString.substring(1, dataTypeString.length - 1);
      decodedString = unescapeStrings ? this.dataTypeEncoder.stripSlashes(decodedString) : decodedString;
      return decodedString;
    }
    // b) Complex types
    // --------------------
    // IMPORTANT: To properly parse complex object structures as well as context variables with regex, the string needs to be prepared. This means:
    // 1. Substrings must be rendered 'harmless', meaning all special characters that regex might confuse with variable syntax must be encoded.
    // 2. The brackets of subfunctions (e.g. context.fn(otherFn(param)).var), must be encoded as well. Regex can't handle nested substructures and wouldn't know which bracket closes the outer function.
    // 3. The brackets of subbrackets (e.g. context[context['something']].var) must be encoded for the same reason.
    dataTypeString = this.encodeDataTypeString(dataTypeString);
    // array or object literal
    if (dataTypeString.startsWith('{') && dataTypeString.endsWith('}') || dataTypeString.startsWith('[') && dataTypeString.endsWith(']')) {
      // Prepare string and parse as JSON
      const json = this.parseAsJSON(dataTypeString, unescapeStrings);
      // Load variables
      return this.loadJSONVariables(json, context, event, unescapeStrings, trackContextVariables, allowContextFunctionCalls, options);
    }
    // event variable name
    if (dataTypeString === '$event') {
      return event;
    }
    // context variable name
    if (dataTypeString.match(new RegExp('^\\s*' + regexes.contextVariableRegex + '\\s*$', 'gm'))) {
      return this.loadContextVariable(dataTypeString, context, event, unescapeStrings, trackContextVariables, allowContextFunctionCalls, options);
    }
    throw Error('Data type for following input was not recognized and could not be parsed: "' + dataTypeString + '"');
  }
  /**
   * Encodes a data type string
   *
   * @param dataTypeString - The string to encode
   */
  encodeDataTypeString(dataTypeString) {
    dataTypeString = this.dataTypeEncoder.encodeSubstrings(dataTypeString); // Encode all potential substrings
    dataTypeString = this.dataTypeEncoder.encodeSubfunctions(dataTypeString); // Encode all potential subfunctions
    dataTypeString = this.dataTypeEncoder.encodeVariableSubbrackets(dataTypeString); // Encode all potential subbrackets of variables
    return dataTypeString;
  }
  /**
   * Decodes a data type string
   *
   * @param dataTypeString - The string to decode
   */
  decodeDataTypeString(dataTypeString) {
    dataTypeString = this.dataTypeEncoder.decodeStringSpecialChars(dataTypeString); // Decode special chars from substrings
    dataTypeString = this.dataTypeEncoder.decodeFunctionBrackets(dataTypeString); // Decode subfunctions
    dataTypeString = this.dataTypeEncoder.decodeVariableBrackets(dataTypeString); // Decode subbrackets
    dataTypeString = dataTypeString.trim(); // Trim whitespace
    return dataTypeString;
  }
  /**
   * In order to successfully parse a data type string with JSON.parse(), it needs to follow certain formatting rules.
   * This function ensures that these are followed and corrects the input if not.
   *
   * @param JSONString - The string to be given to JSON.parse()
   * @param unescapeStrings - Whether to unescape the strings of this JSON
   */
  parseAsJSON(JSONString, unescapeStrings) {
    // Find all single- and grave-quote-delimited strings and convert them to double quote strings
    const singleQuoteStringRegex = /\'(\\.|[^\'])*?\'/gm;
    JSONString = JSONString.replace(singleQuoteStringRegex, match => {
      return '"' + match.slice(1, -1) + '"';
    });
    const graveQuoteStringRegex = /\`(\\.|[^\`])*?\`/gm;
    JSONString = JSONString.replace(graveQuoteStringRegex, match => {
      return '"' + match.slice(1, -1) + '"';
    });
    // Add double-quotes around JSON property names where still missing
    const JSONPropertyRegex = /"?([a-z0-9A-Z_]+)"?\s*:/g;
    JSONString = JSONString.replace(JSONPropertyRegex, '"$1": ');
    // Prevent setting protected properties
    if (JSONString.match(/"?__proto__"?\s*:/g)) {
      throw Error('Setting the "__proto__" property in a hook input object is not allowed.');
    }
    if (JSONString.match(/"?prototype"?\s*:/g)) {
      throw Error('Setting the "prototype" property in a hook input object is not allowed.');
    }
    if (JSONString.match(/"?constructor"?\s*:/g)) {
      throw Error('Setting the "constructor" property in a hook input object is not allowed.');
    }
    // Replace undefined with null
    JSONString = this.replaceValuesInJSONString(JSONString, 'undefined', match => 'null');
    // Replace context vars with string placeholders
    JSONString = this.replaceValuesInJSONString(JSONString, regexes.contextVariableRegex, match => {
      return '"' + this.dataTypeEncoder.transformContextVarIntoPlacerholder(match) + '"';
    });
    // Replace $event with string placeholders
    JSONString = this.replaceValuesInJSONString(JSONString, '\\$event', match => '"__EVENT__"');
    // PARSE
    const json = JSON.parse(JSONString);
    // Decode all strings that are not context vars or the event object
    this.decodeJSONStrings(json, unescapeStrings);
    return json;
  }
  /**
   * Given a stringified json and a json value regex, allows you to replace all occurences
   * of those values in the json via a callback function.
   *
   * IMPORTANT: JSONString must be already encoded via this.encodeDataTypeString() for this to work.
   *
   * @param JSONString - The stringified JSON
   * @param valueRegex - The values to find
   * @param callbackFn - A callback fn that returns what you want to replace them with
   */
  replaceValuesInJSONString(JSONString, valueRegex, callbackFn) {
    // With lookbehinds (too new for some browsers)
    const withLookBehindsRegex = '(?:' + '(?<=:\\s*)' + valueRegex + '(?=\\s*[,}])' + '|' + '(?<=[\\[,]\\s*)' + valueRegex + '(?=\\s*[\\],])' + ')';
    // Without lookbehinds (make sure to keep the lookaheads, though. This way, the same comma can be the end of one regex and the beginning of the next)
    const regex = '(?:' + '(:\\s*)(' + valueRegex + ')(?=\\s*[,}])' + '|' +
    // Value in object: ':' followed by value followed by ',' or '}'
    '([\\[,]\\s*)(' + valueRegex + ')(?=\\s*[\\],])' +
    // Value in array: '[' or ',' followed by value followed by ',' or ']'
    ')';
    return JSONString.replace(new RegExp(regex, 'gm'), (full, p1, p2, p3, p4) => {
      const startPart = p1 ? p1 : p3;
      const value = p2 ? p2 : p4;
      return startPart + callbackFn(value);
    });
  }
  /**
   * Decodes all 'normal' strings without special meaning in a JSON-like object
   *
   * @param jsonLevel - The current level of parsing
   * @param unescapeStrings - Whether to unescape the decoded strings as well
   */
  decodeJSONStrings(jsonLevel, unescapeStrings) {
    for (const prop in jsonLevel) {
      if (typeof jsonLevel[prop] === 'string') {
        // Ignore var placeholders
        if (jsonLevel[prop] === '__EVENT__"' || jsonLevel[prop].match(new RegExp('^\\s*' + regexes.placeholderContextVariableRegex + '\\s*$', 'gm'))) {
          continue;
        }
        // Otherwise decode string
        let decodedString = this.decodeDataTypeString(jsonLevel[prop]);
        decodedString = unescapeStrings ? this.dataTypeEncoder.stripSlashes(decodedString) : decodedString;
        jsonLevel[prop] = decodedString;
      } else if (typeof jsonLevel[prop] === 'object') {
        this.decodeJSONStrings(jsonLevel[prop], unescapeStrings);
      }
    }
  }
  // Loading variables
  // ----------------------------------------------------------------------------------------------------------------------------------------
  /**
   * Travels a JSON-like object to find all context vars and event objects and replaces their placeholders with the actual values
   *
   * @param arrayOrObject - The property of the JSON to analyze
   * @param context - The current context object, if any
   * @param event - The current event object, if any
   * @param unescapeStrings - Whether to unescape strings or not
   * @param trackContextVariables - Whether to unescape strings or not
   * @param allowContextFunctionCalls - Whether function calls in context vars are allowed
   * @param options - The current parseOptions
   */
  loadJSONVariables(arrayOrObject, context, event, unescapeStrings, trackContextVariables, allowContextFunctionCalls, options) {
    for (const prop in arrayOrObject) {
      // Only interested in strings
      if (typeof arrayOrObject[prop] === 'string') {
        // If event placeholder
        if (arrayOrObject[prop] === '__EVENT__') {
          arrayOrObject[prop] = event;
          // If context var placeholder
        } else if (arrayOrObject[prop].match(new RegExp('^\\s*' + regexes.placeholderContextVariableRegex + '\\s*$', 'gm'))) {
          const contextVar = this.dataTypeEncoder.transformPlaceholderIntoContextVar(arrayOrObject[prop].trim());
          arrayOrObject[prop] = this.loadContextVariable(contextVar, context, event, unescapeStrings, trackContextVariables, allowContextFunctionCalls, options);
        }
      } else if (typeof arrayOrObject[prop] === 'object') {
        this.loadJSONVariables(arrayOrObject[prop], context, event, unescapeStrings, trackContextVariables, allowContextFunctionCalls, options);
      }
    }
    return arrayOrObject;
  }
  /**
   * Takes a context variable string and evaluates it to get the desired value
   *
   * IMPORTANT: To correctly parse variables, their substrings, subfunction and subbrackets must be encoded (done in evaluate())
   *
   * @param contextVar - The context var
   * @param context - The context object
   * @param event - An event object, if available
   * @param unescapeStrings - Whether to unescape strings or not
   * @param trackContextVariables - An optional object that will be filled out with all found context vars
   * @param allowContextFunctionCalls - Whether function calls in context vars are allowed
   * @param options - The current parseOptions
   */
  loadContextVariable(contextVar, context = {}, event, unescapeStrings = true, trackContextVariables = {}, allowContextFunctionCalls = true, options = getParseOptionDefaults()) {
    try {
      const shortContextVar = contextVar.substring(7); // Cut off 'context' from the front
      // If context object is requested directly
      if (shortContextVar.trim() === '') {
        return context;
      }
      // Otherwise, create variable path array and fetch value, so the context object can be easily travelled.
      // Variable path example: 'restaurants["newOrleans"].reviews[5]' becomes ['restaurants', 'newOrleans', 'reviews', 5],
      const path = [];
      const pathMatches = matchAll(shortContextVar, new RegExp(regexes.variablePathPartRegex, 'gm'));
      for (const match of pathMatches) {
        // 1. If dot notation
        if (match[0].startsWith('.')) {
          path.push({
            type: 'property',
            value: match[0].substring(1)
          });
        }
        // 2. If bracket notation
        if (match[0].startsWith('[') && match[0].endsWith(']')) {
          let bracketValue = match[0].substring(1, match[0].length - 1);
          // Evaluate bracket parameter
          bracketValue = this.decodeDataTypeString(bracketValue); // Decode variable
          bracketValue = this.evaluate(bracketValue, context, event, unescapeStrings, trackContextVariables, allowContextFunctionCalls); // Recursively repeat the process
          path.push({
            type: 'property',
            value: bracketValue
          });
        }
        // 3. If function call
        if (match[0].startsWith('(') && match[0].endsWith(')')) {
          // Check if function calls are allowed
          if (!allowContextFunctionCalls) {
            throw Error('Tried to call a function in a context variable. This has been disallowed in the current config.');
          }
          const funcParams = match[0].substring(1, match[0].length - 1); // Strip outer brackets
          // Evaluate function parameters
          const paramsArray = [];
          if (funcParams !== '') {
            for (const param of funcParams.split(',')) {
              let p = this.decodeDataTypeString(param); // Decode variable
              p = this.evaluate(p, context, event, unescapeStrings, trackContextVariables, allowContextFunctionCalls); // Recursively repeat the process
              paramsArray.push(p);
            }
          }
          // Add function to path
          path.push({
            type: 'function',
            value: paramsArray
          });
        }
      }
      try {
        const resolvedContextVar = this.fetchContextVariable(context, path);
        trackContextVariables[this.decodeDataTypeString(contextVar)] = resolvedContextVar;
        return resolvedContextVar;
      } catch (e) {
        throw Error('The required context variable "' + this.decodeDataTypeString(contextVar) + '" could not be found in the context object. Returning undefined instead.');
      }
    } catch (e) {
      this.logger.warn([e], options);
      trackContextVariables[this.decodeDataTypeString(contextVar)] = undefined;
      return undefined;
    }
  }
  /**
   * Recursively travels an object with the help of a path array and returns the specified value,
   * or undefined if not found
   *
   * @param contextLevel - The object to travel
   * @param path - The property path array
   */
  fetchContextVariable(contextLevel, path) {
    // Prevent accessing protected properties
    if (path[0].value === '__proto__') {
      throw Error('Accessing the __proto__ property through a context variable is not allowed.');
    }
    if (path[0].value === 'prototype') {
      throw Error('Accessing the prototype property through a context variable is not allowed.');
    }
    if (path[0].value === 'constructor') {
      throw Error('Accessing the constructor property through a context variable is not allowed.');
    }
    if (contextLevel === undefined) {
      throw Error('Context variable path could not be resolved. Trying to access ' + (path[0].type === 'property' ? 'property "' + path[0].value + '" of undefined.' : 'undefined function.'));
    }
    // Get property
    let result;
    if (path[0].type === 'property') {
      if (contextLevel.hasOwnProperty(path[0].value)) {
        result = contextLevel[path[0].value];
        // It makes a difference to JavaScript whether you call a function by 'obj.func()' or by 'let func = obj.func; func();'
        // In the latter case, 'this' will be undefined and not point to the parent. Since this recursive approach uses that latter version,
        // manually bind each function to the parent to restore the normal behavior.
        // Also: If the user has submitted a bound function himself, calling .bind here again does nothing, which is the desired behaviour.
        if (typeof result === 'function') {
          result = result.bind(contextLevel);
        }
        // Check '__proto__' as well as functions tend to live here instead of directly on the instance
      } else if (contextLevel.__proto__.hasOwnProperty(path[0].value)) {
        result = contextLevel.__proto__[path[0].value];
        if (typeof result === 'function') {
          result = result.bind(contextLevel);
        }
      } else {
        result = undefined;
      }
    } else if (path[0].type === 'function') {
      result = contextLevel(...path[0].value);
    }
    path.shift();
    // Recursively travel path
    if (path.length > 0) {
      result = this.fetchContextVariable(result, path);
    }
    return result;
  }
  static {
    this.ɵfac = function DataTypeParser_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DataTypeParser)(i0.ɵɵinject(DataTypeEncoder), i0.ɵɵinject(Logger));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DataTypeParser,
      factory: DataTypeParser.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DataTypeParser, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: DataTypeEncoder
  }, {
    type: Logger
  }], null);
})();

/**
 * A helper service for the SelectorHookParsers that evaluates bindings and only updates them when needed so references are retained when possible
 */
class BindingsValueManager {
  constructor(dataTypeParser, logger) {
    this.dataTypeParser = dataTypeParser;
    this.logger = logger;
  }
  // Inputs
  // -------------------------------------------------------------------------------------
  /**
   * Checks input bindings and evaluates/updates them as needed
   *
   * @param bindings - A list of @Input() bindings
   * @param context - The current context object
   * @param parserConfig - The parser config
   * @param options - The current ParseOptions
   */
  checkInputBindings(bindings, context, parserConfig, options) {
    for (const [inputName, inputBinding] of Object.entries(bindings)) {
      // If no need to parse, use raw as value
      if (!parserConfig.parseInputs) {
        inputBinding.value = inputBinding.raw;
      } else {
        // If not yet parsed, do so
        if (!inputBinding.parsed) {
          try {
            inputBinding.value = this.dataTypeParser.evaluate(inputBinding.raw, parserConfig.allowContextInBindings ? context : {}, undefined, parserConfig.unescapeStrings, inputBinding.boundContextVariables, parserConfig.allowContextFunctionCalls, options);
            inputBinding.parsed = true;
          } catch (e) {
            this.logger.error([`Hook input parsing error\nselector: ` + parserConfig.selector + `\ninput: ` + inputName + `\nvalue: "` + inputBinding.value + `"`], options);
            this.logger.error([e.stack], options);
            // If binding could not be parsed at all due to syntax error, remove from list of inputs.
            // No amount of calls to updateInputBindings() will fix this kind of error.
            delete bindings[inputName];
          }
          // Otherwise check if needs an update
        } else {
          this.updateInputBindingIfStale(inputBinding, context, parserConfig);
        }
      }
    }
  }
  /**
   * We can detect if a binding needs to be reevaluated via the bound context variables. There are three cases to consider:
   *
   * a) If a binding does not use context vars, don't reevaluate (binding is static and won't ever need to be updated)
   * b) If a binding does use context vars, but context vars haven't changed, don't reevaluate either (would evalute the same)
   * c) If a binding uses context vars and they have changed, reevaluate the binding from scratch to get the new version
   *
   * This is in line with the standard Angular behavior when evaluating template vars like [input]="{prop: this.something}".
   * When 'this.something' changes so that it returns false on a === comparison with its previous value, Angular does not
   * simply replace the reference bound to 'prop', but recreates the whole object literal and passes a new reference into the
   * input, triggering ngOnChanges.
   *
   * @param binding - The previous bindings
   * @param context - The current context object
   * @param parserConfig - The current parser config
   */
  updateInputBindingIfStale(binding, context, parserConfig) {
    if (Object.keys(binding.boundContextVariables).length > 0) {
      // Check if bound context vars have changed
      let boundContextVarHasChanged = false;
      for (const [contextVarName, contextVarValue] of Object.entries(binding.boundContextVariables)) {
        const encodedContextVarName = this.dataTypeParser.encodeDataTypeString(contextVarName);
        // Compare with previous value
        const newContextVarValue = this.dataTypeParser.loadContextVariable(encodedContextVarName, context, undefined, parserConfig.unescapeStrings, {}, parserConfig.allowContextFunctionCalls);
        if (newContextVarValue !== contextVarValue) {
          boundContextVarHasChanged = true;
          break;
        }
      }
      // Bound context var has changed! Reevaluate whole binding (which may include more than one context var, or point to some child property)
      if (boundContextVarHasChanged) {
        binding.boundContextVariables = {};
        binding.value = this.dataTypeParser.evaluate(binding.raw, parserConfig.allowContextInBindings ? context : {}, undefined, parserConfig.unescapeStrings, binding.boundContextVariables, parserConfig.allowContextFunctionCalls);
      }
    }
  }
  // Outputs
  // -------------------------------------------------------------------------------------
  /**
   * Checks output bindings and evaluates/updates them as needed
   *
   * @param bindings - A list of @Output() bindings
   * @param parserConfig - The current parser config
   * @param options - The current ParseOptions
   */
  checkOutputBindings(bindings, parserConfig, options) {
    for (const [outputName, outputBinding] of Object.entries(bindings)) {
      // Unlike inputs, outputs only need to be created once by the parser, never updated, as you only create a wrapper function around the logic to execute.
      // As this logic is run fresh whenever the output triggers, there is no need to replace this wrapper function on updates.
      if (!outputBinding.parsed) {
        outputBinding.value = (event, context) => {
          try {
            this.dataTypeParser.evaluate(outputBinding.raw, parserConfig.allowContextInBindings ? context : {}, event, parserConfig.unescapeStrings, outputBinding.boundContextVariables, parserConfig.allowContextFunctionCalls);
          } catch (e) {
            this.logger.error([`Hook output parsing error\nselector: ` + parserConfig.selector + `\noutput: ` + outputName + `\nvalue: "` + outputBinding.value + `"`], options);
            this.logger.error([e.stack], options);
          }
        };
        outputBinding.parsed = true;
      }
    }
  }
  static {
    this.ɵfac = function BindingsValueManager_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BindingsValueManager)(i0.ɵɵinject(DataTypeParser), i0.ɵɵinject(Logger));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BindingsValueManager,
      factory: BindingsValueManager.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BindingsValueManager, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: DataTypeParser
  }, {
    type: Logger
  }], null);
})();

/**
 * A helper class for resolving HookParserEntries
 */
class ParserEntryResolver {
  constructor(injector, parserResolver, platformService, tagHookFinder, bindingsValueManager, logger) {
    this.injector = injector;
    this.parserResolver = parserResolver;
    this.platformService = platformService;
    this.tagHookFinder = tagHookFinder;
    this.bindingsValueManager = bindingsValueManager;
    this.logger = logger;
  }
  /**
   * Takes a list of HookParserEntries and transforms them into a list of loaded HookParsers
   *
   * @param parserEntries - The list of HookParserEntries to process
   * @param injector - The injector to use for resolving parsers
   * @param blacklist - (optional) Which parsers to blacklist by name
   * @param whitelist - (optional) Which parsers to whitelist by name
   * @param options - The current ParseOptions
   */
  resolve(parserEntries, injector, blacklist, whitelist, options) {
    // Load all requested parsers
    const parsers = [];
    for (const parser of parserEntries) {
      const resolvedParser = this.resolveEntry(parser, injector, options);
      if (resolvedParser) {
        parsers.push(resolvedParser);
      }
    }
    // Check parser functions
    const validParsers = this.validateParserFunctions(parsers, options);
    // Check parser names
    this.checkParserNames(validParsers, options);
    // If no need to filter, return resolved parsers
    if (!blacklist && !whitelist) {
      return validParsers;
    }
    // Check black/whitelist
    this.checkBlackAndWhitelist(validParsers, blacklist, whitelist, options);
    // Filter parsers
    const filteredParsers = [];
    for (const validParser of validParsers) {
      if (validParser.hasOwnProperty('name') && typeof validParser.name === 'string') {
        if (blacklist && blacklist.includes(validParser.name)) {
          continue;
        }
        if (whitelist && !whitelist.includes(validParser.name)) {
          continue;
        }
      }
      filteredParsers.push(validParser);
    }
    return filteredParsers;
  }
  /**
   * Figures out what kind of config type the HookParserEntry is and loads it appropriately.
   *
   * The potential types are:
   * - 1. a component class (shorthand for nr. 5)
   * - 2. a parser service
   * - 3. a parser class
   * - 4. a parser instance
   * - 5. an object literal to configure SelectorHookParser with
   *
   * @param parserEntry - The HookParserEntry to process
   * @param injector - The injector to use for resolving this parser
   * @param options - The current ParseOptions
   */
  resolveEntry(parserEntry, injector, options) {
    // Check if class
    if (parserEntry.hasOwnProperty('prototype')) {
      // Check if component class
      const componentMeta = reflectComponentType(parserEntry);
      if (componentMeta) {
        return this.createSelectorHookParser({
          component: parserEntry
        });
        // Else must be parser class
      } else {
        // Check if service
        try {
          return injector.get(parserEntry);
          // Otherwise instantiate manually
        } catch (e) {
          return new parserEntry();
        }
      }
    }
    // Check if object
    else if (typeof parserEntry === 'object') {
      // Is instance
      if (parserEntry.constructor.name !== 'Object') {
        return parserEntry;
        // Is object literal
      } else {
        try {
          return this.createSelectorHookParser(parserEntry);
        } catch (e) {
          this.logger.error(['Invalid parser config - ' + e.message, parserEntry], options);
          return null;
        }
      }
    }
    this.logger.error(['Invalid parser config - ', parserEntry], options);
    return null;
  }
  /**
   * Depending on the config, load either string or element SelectorHookParser
   *
   * @param config - The selectorHookParserConfig
   */
  createSelectorHookParser(config) {
    if (config.hasOwnProperty('parseWithRegex') && config.parseWithRegex || config.hasOwnProperty('enclosing') && !config.enclosing || config.hasOwnProperty('bracketStyle') && config.bracketStyle) {
      return new TextSelectorHookParser(config, this.parserResolver, this.tagHookFinder, this.bindingsValueManager);
    } else {
      return new ElementSelectorHookParser(config, this.parserResolver, this.platformService, this.bindingsValueManager);
    }
  }
  /**
   * Makes sure that the parsers have all required functions
   *
   * @param parsers - The parsers in question
   * @param options - The current ParseOptions
   */
  validateParserFunctions(parsers, options) {
    const validParsers = [];
    for (const parser of parsers) {
      if (typeof parser.findHooks !== 'function' && typeof parser.findHookElements !== 'function') {
        this.logger.error(['Submitted parser neither implements "findHooks()" nor "findHookElements()". One is required. Removing from list of active parsers:', parser], options);
        continue;
      }
      if (typeof parser.loadComponent !== 'function') {
        this.logger.error(['Submitted parser does not implement "loadComponent()". Removing from list of active parsers:', parser], options);
        continue;
      }
      if (typeof parser.getBindings !== 'function') {
        this.logger.error(['Submitted parser does not implement "getBindings()". Removing from list of active parsers:', parser], options);
        continue;
      }
      validParsers.push(parser);
    }
    return validParsers;
  }
  /**
   * Makes sure that all parser names are unique
   *
   * @param parsers - The parsers in question
   * @param options - The current ParseOptions
   */
  checkParserNames(parsers, options) {
    const parserNames = parsers.map(entry => entry.name).filter(entry => entry !== undefined);
    const previousNames = [];
    const alreadyWarnedNames = [];
    for (const parserName of parserNames) {
      if (previousNames.includes(parserName) && !alreadyWarnedNames.includes(parserName)) {
        this.logger.warn(['Parser name "' + parserName + '" is not unique and appears multiple times in the list of active parsers.'], options);
        alreadyWarnedNames.push(parserName);
      }
      previousNames.push(parserName);
    }
  }
  /**
   * A black/whitelist validation function for the benefit of the user. Outputs warnings in the console if something is off.
   *
   * @param parsers - The parsers in question
   * @param blacklist - The blacklist in question
   * @param whitelist - The whitelist in question
   * @param options - The current ParseOptions
   */
  checkBlackAndWhitelist(parsers, blacklist, whitelist, options) {
    const parserNames = parsers.map(entry => entry.name).filter(entry => entry !== undefined);
    if (blacklist) {
      for (const blacklistedParser of blacklist) {
        if (!parserNames.includes(blacklistedParser)) {
          this.logger.warn(['Blacklisted parser name "' + blacklistedParser + '" does not appear in the list of global parsers names. Make sure both spellings are identical.'], options);
        }
      }
    }
    if (whitelist) {
      for (const whitelistedParser of whitelist) {
        if (!parserNames.includes(whitelistedParser)) {
          this.logger.warn(['Whitelisted parser name "' + whitelistedParser + '" does not appear in the list of global parsers names. Make sure both spellings are identical.'], options);
        }
      }
    }
  }
  static {
    this.ɵfac = function ParserEntryResolver_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ParserEntryResolver)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(SelectorHookParserConfigResolver), i0.ɵɵinject(AutoPlatformService), i0.ɵɵinject(TagHookFinder), i0.ɵɵinject(BindingsValueManager), i0.ɵɵinject(Logger));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ParserEntryResolver,
      factory: ParserEntryResolver.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ParserEntryResolver, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.Injector
  }, {
    type: SelectorHookParserConfigResolver
  }, {
    type: AutoPlatformService
  }, {
    type: TagHookFinder
  }, {
    type: BindingsValueManager
  }, {
    type: Logger
  }], null);
})();

/**
 * A helper class for resolving a combined settings object from all provided ones
 */
class SettingsResolver {
  constructor(parserEntryResolver) {
    this.parserEntryResolver = parserEntryResolver;
  }
  /**
   * Takes all provided settings objects and combines them into a final settings object
   *
   * @param injector - The current injector
   * @param content - The content
   * @param allSettings - All settings provided anywhere
   * @param ancestorSettings - All ancestor settings
   * @param moduleSettings - The current module settings
   * @param localParsers - A list of local parsers
   * @param localOptions - A local options object
   * @param globalParsersBlacklist - A list of global parsers to blacklist
   * @param globalParsersWhitelist - A list of global parsers to whitelist
   */
  resolve(injector, content, allSettings, ancestorSettings, moduleSettings, localParsers = null, localOptions = null, globalParsersBlacklist = null, globalParsersWhitelist = null) {
    let resolvedSettings = {};
    allSettings = allSettings || [];
    ancestorSettings = ancestorSettings || [];
    moduleSettings = moduleSettings || {};
    const defaultSettings = {
      options: getParseOptionDefaults()
    };
    // Merge settings according to inheritance
    if (!moduleSettings.hasOwnProperty('inheritance') || moduleSettings.inheritance === DynamicHooksInheritance.Linear) {
      resolvedSettings = this.mergeSettings([defaultSettings, ...ancestorSettings, {
        parsers: localParsers || undefined,
        options: localOptions || undefined
      }]);
    } else if (moduleSettings.inheritance === DynamicHooksInheritance.All) {
      // Additionally merge ancestorSettings after allSettings to give settings closer to the current injector priority
      resolvedSettings = this.mergeSettings([defaultSettings, ...allSettings, ...ancestorSettings, {
        options: localOptions || undefined
      }]);
    } else {
      resolvedSettings = this.mergeSettings([defaultSettings, moduleSettings || {}, {
        options: localOptions || undefined
      }]);
    }
    const finalOptions = resolvedSettings.options;
    // Disabled sanitization if content is not string
    if (content && typeof content !== 'string') {
      finalOptions.sanitize = false;
    }
    // Process parsers entries. Local parsers fully replace global ones.
    let finalParsers = [];
    if (localParsers) {
      finalParsers = this.parserEntryResolver.resolve(localParsers, injector, null, null, finalOptions);
    } else if (resolvedSettings.parsers) {
      finalParsers = this.parserEntryResolver.resolve(resolvedSettings.parsers, injector, globalParsersBlacklist, globalParsersWhitelist, finalOptions);
    }
    return {
      parsers: finalParsers,
      options: finalOptions
    };
  }
  /**
   * Merges multiple settings objects, overwriting previous ones with later ones in the provided array
   *
   * @param settingsArray - The settings objects to merge
   */
  mergeSettings(settingsArray) {
    const mergedSettings = {};
    for (const settings of settingsArray) {
      // Unique parsers are simply all collected, not overwritten
      if (settings.parsers !== undefined) {
        if (mergedSettings.parsers === undefined) {
          mergedSettings.parsers = [];
        }
        for (const parserEntry of settings.parsers) {
          if (!mergedSettings.parsers.includes(parserEntry)) {
            mergedSettings.parsers.push(parserEntry);
          }
        }
      }
      // Options are individually overwritten
      if (settings.options !== undefined) {
        if (mergedSettings.options === undefined) {
          mergedSettings.options = {};
        }
        mergedSettings.options = this.recursiveAssign(mergedSettings.options, settings.options);
      }
    }
    return mergedSettings;
  }
  /**
   * Recursively merges two objects
   *
   * @param a - The target object to merge into
   * @param b - The other object being merged
   */
  recursiveAssign(a, b) {
    if (Object(b) !== b) return b;
    if (Object(a) !== a) a = {};
    for (const key in b) {
      a[key] = this.recursiveAssign(a[key], b[key]);
    }
    return a;
  }
  static {
    this.ɵfac = function SettingsResolver_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SettingsResolver)(i0.ɵɵinject(ParserEntryResolver));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SettingsResolver,
      factory: SettingsResolver.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SettingsResolver, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ParserEntryResolver
  }], null);
})();
const findInElementsNodePlaceholder = '_ngx_dynamic_hooks_node_placeholder';
/**
 * The service responsible for finding text hooks in the content and replacing them with component anchors
 */
class TextHookFinder {
  constructor(platformService, logger) {
    this.platformService = platformService;
    this.logger = logger;
  }
  /**
   * Finds all text hooks in an existing element and creates the corresponding anchors
   *
   * @param element - The element to parse
   * @param context - The current context object
   * @param parsers - The parsers to use
   * @param token - The current parse token
   * @param options - The current ParseOptions
   * @param hookIndex - The hookIndex object to fill
   */
  findInElement(element, context, parsers, token, options, hookIndex) {
    // Only bother looking for text hooks if there even are text hook parsers
    for (const parser of parsers) {
      if (typeof parser.findHooks === 'function') {
        this.checkElement(element, context, parsers, token, options, hookIndex);
        break;
      }
    }
  }
  /**
   * Checks an individual element and travels it recursively
   *
   * @param element - The element to parse
   * @param context - The current context object
   * @param parsers - The parsers to use
   * @param token - The current parse token
   * @param options - The current ParseOptions
   * @param hookIndex - The hookIndex object to fill
   * @param extractedNodes - A recursively-used object holding all temporarily extracted nodes
   */
  checkElement(element, context, parsers, token, options, hookIndex, extractedNodes = {
    counter: 0,
    nodes: {}
  }) {
    let childNodes = this.platformService.getChildNodes(element);
    // To find text hooks in an already existing node, first replace non-text child nodes with string placeholders, then concat all text content.
    // This is so enclosing text hooks can be found even if they are separated by other elements
    let collectedText = '';
    const collectedNodes = {};
    for (const childNode of childNodes) {
      if (this.platformService.isTextNode(childNode)) {
        collectedText += this.platformService.getTextContent(childNode);
      } else {
        const nodeId = extractedNodes.counter++;
        collectedText += `${findInElementsNodePlaceholder}__${nodeId}__`;
        collectedNodes[nodeId] = childNode;
      }
    }
    // Then check for text hooks
    const prevHookCount = Object.keys(hookIndex).length;
    const result = this.find(collectedText, context, parsers, token, options, hookIndex);
    // If hooks were found, replace element content with result.content
    if (Object.keys(hookIndex).length > prevHookCount) {
      this.platformService.clearChildNodes(element);
      this.platformService.setInnerContent(element, result.content);
      childNodes = this.platformService.getChildNodes(element);
      // Also add locally removed nodes to total extractedNodes
      extractedNodes.nodes = {
        ...extractedNodes.nodes,
        ...collectedNodes
      };
    }
    // If still have extractedNodes, always look for their placeholders on every level (could be deeper than when they were extracted) and reinsert them
    if (Object.keys(extractedNodes.nodes).length) {
      for (const childNode of childNodes) {
        if (this.platformService.isTextNode(childNode)) {
          let text = this.platformService.getTextContent(childNode);
          if (text) {
            const matches = matchAll(text, new RegExp(`${findInElementsNodePlaceholder}__(\\d*)__`, 'g'));
            // If placeholders found
            if (matches.length) {
              const textReplacementNodes = [];
              let currentPos = 0;
              // Split text node containing placeholder into nodes array with restored nodes
              for (const match of matches) {
                const textBefore = text.substring(currentPos, match.index);
                const extractedNodeId = parseInt(match[1]);
                if (textBefore) {
                  textReplacementNodes.push(this.platformService.createTextNode(textBefore));
                }
                if (extractedNodeId && extractedNodes.nodes[extractedNodeId]) {
                  textReplacementNodes.push(extractedNodes.nodes[extractedNodeId]);
                  delete extractedNodes.nodes[extractedNodeId];
                }
                currentPos = match.index + match[0].length;
              }
              const textRemaining = text.substring(currentPos);
              if (textRemaining) {
                textReplacementNodes.push(this.platformService.createTextNode(textRemaining));
              }
              // Replace text node with that array
              const parent = this.platformService.getParentNode(childNode);
              for (const replacementNode of textReplacementNodes) {
                this.platformService.insertBefore(parent, replacementNode, childNode);
              }
              this.platformService.removeChild(parent, childNode);
              // Update child nodes var
              childNodes = this.platformService.getChildNodes(parent);
            }
          }
        }
      }
    }
    // Travel child nodes recursively
    for (const childNode of childNodes) {
      if (childNode.nodeType !== Node.TEXT_NODE) {
        this.checkElement(childNode, context, parsers, token, options, hookIndex, extractedNodes);
      }
    }
  }
  /**
   * Finds all text hooks in a string variable and creates the corresponding anchors
   *
   * @param content - The text to parse
   * @param context - The current context object
   * @param parsers - The parsers to use
   * @param token - The current parse token
   * @param options - The current ParseOptions
   * @param hookIndex - The hookIndex object to fill
   */
  find(content, context, parsers, token, options, hookIndex) {
    if (content === '') {
      return {
        content: content,
        hookIndex: hookIndex
      };
    }
    // Convert input HTML entities?
    if (options.convertHTMLEntities) {
      content = this.convertHTMLEntities(content);
    }
    // Collect all parser results, sort by order of appearance
    let parserResults = [];
    for (const parser of parsers) {
      if (typeof parser.findHooks === 'function') {
        for (const hookPosition of parser.findHooks(content, context, options)) {
          parserResults.push({
            parser,
            hookPosition
          });
        }
      }
    }
    parserResults.sort((a, b) => a.hookPosition.openingTagStartIndex - b.hookPosition.openingTagStartIndex);
    // Validate parser results
    parserResults = this.validateHookPositions(parserResults, content, options);
    // Process parser results
    const selectorReplaceInstructions = [];
    for (const pr of parserResults) {
      const hookId = Object.keys(hookIndex).length + 1;
      // Some info about this hook
      const hookSegments = this.getHookSegments(pr.hookPosition, content);
      // Prepare ReplaceInstructions array to replace all found hooks with anchor elements
      selectorReplaceInstructions.push({
        startIndex: pr.hookPosition.openingTagStartIndex,
        endIndex: pr.hookPosition.openingTagEndIndex,
        replacement: `<${anchorElementTag} ${anchorAttrHookId}="${hookId}" ${anchorAttrParseToken}="${token}">`
      });
      selectorReplaceInstructions.push({
        startIndex: hookSegments.enclosing ? pr.hookPosition.closingTagStartIndex : pr.hookPosition.openingTagEndIndex,
        endIndex: hookSegments.enclosing ? pr.hookPosition.closingTagEndIndex : pr.hookPosition.openingTagEndIndex,
        replacement: `</${anchorElementTag}>`
      });
      // Enter hook into index
      hookIndex[hookId] = {
        id: hookId,
        parser: pr.parser,
        value: {
          openingTag: hookSegments.openingTag,
          closingTag: hookSegments.closingTag,
          element: null,
          elementSnapshot: null
        },
        data: null,
        isLazy: false,
        bindings: null,
        previousBindings: null,
        componentRef: null,
        dirtyInputs: new Set(),
        outputSubscriptions: {},
        htmlEventSubscriptions: {}
      };
      // Remove tag artifacts (does not change parser results indexes)
      if (hookSegments.enclosing && options.fixParagraphTags) {
        const firstResult = this.removeTagArtifacts(hookSegments.textBefore, '<p>', '</p>', hookSegments.innerValue, '</p>', '<p>');
        hookSegments.textBefore = firstResult.firstText;
        hookSegments.innerValue = firstResult.secondText;
        const secondResult = this.removeTagArtifacts(hookSegments.innerValue, '<p>', '</p>', hookSegments.textAfter, '</p>', '<p>');
        hookSegments.innerValue = secondResult.firstText;
        hookSegments.textAfter = secondResult.secondText;
        content = hookSegments.textBefore + hookSegments.openingTag + hookSegments.innerValue + hookSegments.closingTag + hookSegments.textAfter;
      }
    }
    // Actually replace hooks with anchors (from the back, so no need to change indexes)
    selectorReplaceInstructions.sort((a, b) => b.startIndex - a.startIndex);
    for (const selectorReplaceInstruction of selectorReplaceInstructions) {
      const textBeforeSelector = content.substring(0, selectorReplaceInstruction.startIndex);
      const textAfterSelector = content.substring(selectorReplaceInstruction.endIndex);
      content = textBeforeSelector + selectorReplaceInstruction.replacement + textAfterSelector;
    }
    return {
      content: content,
      hookIndex: hookIndex
    };
  }
  /**
   * Takes a HookPosition and returns the HookValue as well as the text surrounding it
   *
   * @param hookPosition - The HookPosition in question
   * @param content - The source text for the HookPosition
   */
  getHookSegments(hookPosition, content) {
    const enclosing = Number.isInteger(hookPosition.closingTagStartIndex) && Number.isInteger(hookPosition.closingTagEndIndex);
    return {
      enclosing: enclosing,
      textBefore: content.substring(0, hookPosition.openingTagStartIndex),
      openingTag: content.substring(hookPosition.openingTagStartIndex, hookPosition.openingTagEndIndex),
      innerValue: enclosing ? content.substring(hookPosition.openingTagEndIndex, hookPosition.closingTagStartIndex) : null,
      closingTag: enclosing ? content.substring(hookPosition.closingTagStartIndex, hookPosition.closingTagEndIndex) : null,
      textAfter: enclosing ? content.substring(hookPosition.closingTagEndIndex) : content.substring(hookPosition.openingTagEndIndex)
    };
  }
  /**
   * Checks the combined parserResults and validates them. Invalid ones are removed.
   *
   * @param parserResults - The parserResults to check
   * @param content - The content string
   * @param options - The current ParseOptions
   */
  validateHookPositions(parserResults, content, options) {
    const checkedParserResults = [];
    outerloop: for (const [index, parserResult] of parserResults.entries()) {
      const enclosing = Number.isInteger(parserResult.hookPosition.closingTagStartIndex) && Number.isInteger(parserResult.hookPosition.closingTagEndIndex);
      const hookPos = parserResult.hookPosition;
      // Check if hook is in itself well-formed
      if (hookPos.openingTagStartIndex >= hookPos.openingTagEndIndex) {
        this.logger.warn(['Text hook error: openingTagEndIndex has to be greater than openingTagStartIndex. Ignoring.', hookPos], options);
        continue;
      }
      if (enclosing && hookPos.openingTagEndIndex > hookPos.closingTagStartIndex) {
        this.logger.warn(['Text hook error: closingTagStartIndex has to be greater than openingTagEndIndex. Ignoring.', hookPos], options);
        continue;
      }
      if (enclosing && hookPos.closingTagStartIndex >= hookPos.closingTagEndIndex) {
        this.logger.warn(['Text hook error: closingTagEndIndex has to be greater than closingTagStartIndex. Ignoring.', hookPos], options);
        continue;
      }
      // Check if hook overlaps with other hooks
      const previousHooks = parserResults.slice(0, index);
      innerloop: for (const previousHook of previousHooks) {
        const prevHookPos = previousHook.hookPosition;
        const prevIsEnclosing = Number.isInteger(prevHookPos.closingTagStartIndex) && Number.isInteger(prevHookPos.closingTagEndIndex);
        // Check if identical hook position
        if (hookPos.openingTagStartIndex === prevHookPos.openingTagStartIndex && hookPos.openingTagEndIndex === prevHookPos.openingTagEndIndex && (!enclosing || !prevIsEnclosing || hookPos.closingTagStartIndex === prevHookPos.closingTagStartIndex && hookPos.closingTagEndIndex === prevHookPos.closingTagEndIndex)) {
          this.generateHookPosWarning('A text hook with the same position as another text hook was found. There may be multiple parsers looking for the same text pattern. Ignoring duplicates.', hookPos, prevHookPos, content, options);
          continue outerloop;
        }
        // Opening tag must begin after previous opening tag has ended
        if (hookPos.openingTagStartIndex < prevHookPos.openingTagEndIndex) {
          this.generateHookPosWarning('Text hook error: Hook opening tag starts before previous hook opening tag ends. Ignoring.', hookPos, prevHookPos, content, options);
          continue outerloop;
        }
        // Just need to check for collisions with previous closing tag now
        // Opening tag must not overlap with previous closing tag
        if (prevIsEnclosing && !(hookPos.openingTagEndIndex <= prevHookPos.closingTagStartIndex || hookPos.openingTagStartIndex >= prevHookPos.closingTagEndIndex)) {
          this.generateHookPosWarning('Text hook error: Opening tag of hook overlaps with closing tag of previous hook. Ignoring.', hookPos, prevHookPos, content, options);
          continue outerloop;
        }
        // Closing tag must not overlap with previous closing tag
        if (prevIsEnclosing && enclosing && !(hookPos.closingTagEndIndex <= prevHookPos.closingTagStartIndex || hookPos.closingTagStartIndex >= prevHookPos.closingTagEndIndex)) {
          this.generateHookPosWarning('Text hook error: Closing tag of hook overlaps with closing tag of previous hook. Ignoring.', hookPos, prevHookPos, content, options);
          continue outerloop;
        }
        // Check if hooks are incorrectly nested, e.g. "<outer-hook><inner-hook></outer-hook></inner-hook>"
        if (enclosing && prevIsEnclosing && hookPos.openingTagEndIndex <= prevHookPos.closingTagStartIndex && hookPos.closingTagStartIndex >= prevHookPos.closingTagEndIndex) {
          this.generateHookPosWarning('Text hook error: The closing tag of a nested hook lies beyond the closing tag of the outer hook. Ignoring.', hookPos, prevHookPos, content, options);
          continue outerloop;
        }
      }
      // If everything okay, add to result array
      checkedParserResults.push(parserResult);
    }
    return checkedParserResults;
  }
  /**
   * Outputs a warning in the console when the positions of two hooks are invalid in some manner
   *
   * @param message - The error message
   * @param hookPos - The first HookPosition
   * @param prevHookPos - The second HookPosition
   * @param content - The content string
   * @param options - The current ParseOptions
   */
  generateHookPosWarning(message, hookPos, prevHookPos, content, options) {
    const prevHookSegments = this.getHookSegments(prevHookPos, content);
    const hookSegments = this.getHookSegments(hookPos, content);
    const prevHookData = {
      openingTag: prevHookSegments.openingTag,
      openingTagStartIndex: prevHookPos.openingTagStartIndex,
      openingTagEndIndex: prevHookPos.openingTagEndIndex,
      closingTag: prevHookSegments.closingTag,
      closingTagStartIndex: prevHookPos.closingTagStartIndex,
      closingTagEndIndex: prevHookPos.closingTagEndIndex
    };
    const hookData = {
      openingTag: hookSegments.openingTag,
      openingTagStartIndex: hookPos.openingTagStartIndex,
      openingTagEndIndex: hookPos.openingTagEndIndex,
      closingTag: hookSegments.closingTag,
      closingTagStartIndex: hookPos.closingTagStartIndex,
      closingTagEndIndex: hookPos.closingTagEndIndex
    };
    this.logger.warn([message + '\nFirst hook: ', prevHookData, '\nSecond hook:', hookData], options);
  }
  /**
   * When using an enclosing hook that is spread over several lines in an HTML editor, p-elements tend to get ripped apart. For example:
   *
   * <p><app-hook></p>
   *   <h2>This is the hook content</h2>
   * <p></app-hook></p>
   *
   * would cause the innerValue of app-hook to have a lone closing and opening p-tag (as their counterparts are outside of the hook).
   * To clean up the HTML, this function removes a pair of these artifacts (e.g. <p> before hook, </p> inside hook) if BOTH are found.
   * This is important as the HTML parser will otherwise mess up the intended HTML and sometimes even put what should be ng-content below the component.
   *
   * @param firstText - The text on one side of the hook
   * @param firstArtifact - A string that should be removed from firstText...
   * @param firstRemoveIfAfter - ...if it appears after the last occurrence of this string
   * @param secondText - The text on the other side of the hook
   * @param secondArtifact - A string that should be removed from secondText...
   * @param secondRemoveIfBefore - ...if it appears before the first occurrence of this string
   */
  removeTagArtifacts(firstText, firstArtifact, firstRemoveIfAfter, secondText, secondArtifact, secondRemoveIfBefore) {
    let firstArtifactFound = false;
    let secondArtifactFound = false;
    // a) Look for first artifact
    const firstArtifactIndex = firstText.lastIndexOf(firstArtifact);
    const firstArtifactIfAfterIndex = firstText.lastIndexOf(firstRemoveIfAfter);
    if (firstArtifactIndex >= 0 && firstArtifactIfAfterIndex === -1 || firstArtifactIndex > firstArtifactIfAfterIndex) {
      firstArtifactFound = true;
    }
    // b) Look for second artifact
    const secondArtifactIndex = secondText.indexOf(secondArtifact);
    const secondArtifactIfBeforeIndex = secondText.indexOf(secondRemoveIfBefore);
    if (
    // If startArtifact appears before startArtifactNotBefore
    secondArtifactIndex >= 0 && secondArtifactIfBeforeIndex === -1 || secondArtifactIndex < secondArtifactIfBeforeIndex) {
      secondArtifactFound = true;
    }
    // If artifacts found on both sides, remove both by overwriting them with empty spaces (doesn't change index)
    if (firstArtifactFound && secondArtifactFound) {
      firstText = firstText.substring(0, firstArtifactIndex) + ' '.repeat(firstArtifact.length) + firstText.substring(firstArtifactIndex + firstArtifact.length);
      secondText = secondText.substring(0, secondArtifactIndex) + ' '.repeat(secondArtifact.length) + secondText.substring(secondArtifactIndex + secondArtifact.length);
    }
    // Trim after artifacts removed
    return {
      firstText: firstText,
      secondText: secondText
    };
  }
  /**
   * Converts all HTML entities to normal characters
   *
   * @param text - The text with the potential HTML entities
   */
  convertHTMLEntities(text) {
    const div = this.platformService.createElement('div');
    const result = text.replace(/&[#A-Za-z0-9]+;/gi, hmtlEntity => {
      // Replace invisible nbsp-whitespace with normal whitespace (not \u00A0). Leads to problems with JSON.parse() otherwise.
      if (hmtlEntity === '&nbsp;') {
        return ' ';
      }
      this.platformService.setInnerContent(div, hmtlEntity);
      return this.platformService.getTextContent(div);
    });
    return result;
  }
  static {
    this.ɵfac = function TextHookFinder_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TextHookFinder)(i0.ɵɵinject(AutoPlatformService), i0.ɵɵinject(Logger));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TextHookFinder,
      factory: TextHookFinder.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextHookFinder, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AutoPlatformService
  }, {
    type: Logger
  }], null);
})();

/**
 * The service responsible for finding element hooks in the content and marking them with anchor attrs
 */
class ElementHookFinder {
  constructor(platformService, logger) {
    this.platformService = platformService;
    this.logger = logger;
  }
  /**
   * Finds all element hooks in an element and marks the corresponding anchor elements
   *
   * @param contentElement - The content element to parse
   * @param context - The current context object
   * @param parsers - The parsers to use
   * @param token - The current parse token
   * @param options - The current ParseOptions
   * @param hookIndex - The hookIndex object to fill
   */
  find(contentElement, context, parsers, token, options, hookIndex) {
    // Collect all parser results
    let parserResults = [];
    for (const parser of parsers) {
      if (typeof parser.findHookElements === 'function') {
        for (const hookElement of parser.findHookElements(contentElement, context, options)) {
          parserResults.push({
            parser,
            hookElement
          });
        }
      }
    }
    parserResults = sortElements(parserResults, this.platformService.sortElements.bind(this.platformService), entry => entry.hookElement);
    // Validate parser results
    parserResults = this.validateHookElements(parserResults, contentElement, options);
    // Process parser results
    for (const pr of parserResults) {
      const hookId = Object.keys(hookIndex).length + 1;
      // Enter hook into index
      hookIndex[hookId] = {
        id: hookId,
        parser: pr.parser,
        value: {
          openingTag: this.platformService.getOpeningTag(pr.hookElement),
          closingTag: this.platformService.getClosingTag(pr.hookElement),
          element: pr.hookElement,
          elementSnapshot: this.platformService.cloneElement(pr.hookElement)
        },
        data: null,
        isLazy: false,
        bindings: null,
        previousBindings: null,
        componentRef: null,
        dirtyInputs: new Set(),
        outputSubscriptions: {},
        htmlEventSubscriptions: {}
      };
      // Add anchor attrs
      this.platformService.setAttribute(pr.hookElement, anchorAttrHookId, hookId.toString());
      this.platformService.setAttribute(pr.hookElement, anchorAttrParseToken, token);
    }
    return hookIndex;
  }
  /**
   * Checks the combined parserResults and validates them. Invalid ones are removed.
   *
   * @param parserResults - The parserResults to check
   * @param contentElement - The content element
   * @param options - The current ParseOptions
   */
  validateHookElements(parserResults, contentElement, options) {
    const checkedParserResults = [];
    for (const [index, parserResult] of parserResults.entries()) {
      const previousCheckedParserResults = checkedParserResults.slice(0, index);
      const wasFoundAsElementHookAlready = previousCheckedParserResults.findIndex(entry => entry.hookElement === parserResult.hookElement) >= 0;
      // Must not already be a hook anchor (either from previous iteration of loop or text hook finder)
      if (wasFoundAsElementHookAlready || this.platformService.getAttributeNames(parserResult.hookElement).includes(anchorAttrHookId) || this.platformService.getAttributeNames(parserResult.hookElement).includes(anchorAttrParseToken)) {
        this.logger.warn(['An element hook tried to use an element that was found by another hook before. There may be multiple parsers looking for the same elements. Ignoring duplicates.', parserResult.hookElement], options);
        continue;
      }
      // Must not already be host or view element for an Angular component
      if (isAngularManagedElement(parserResult.hookElement)) {
        // this.logger.warn(['A hook element was found that is already a host or view element of an active Angular component. Ignoring.'], options);
        continue;
      }
      // If everything okay, add to result array
      checkedParserResults.push(parserResult);
    }
    return checkedParserResults;
  }
  static {
    this.ɵfac = function ElementHookFinder_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ElementHookFinder)(i0.ɵɵinject(AutoPlatformService), i0.ɵɵinject(Logger));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ElementHookFinder,
      factory: ElementHookFinder.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ElementHookFinder, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AutoPlatformService
  }, {
    type: Logger
  }], null);
})();
const sanitizerPlaceholderTag = 'dynamic-hooks-sanitization-placeholder';
const sanitizerPlaceholderRegex = new RegExp(`<\/?${sanitizerPlaceholderTag}.*?>`, 'g');
/**
 * A utility service that sanitizes an Element and all of its children while exluding found hook elements
 */
class ContentSanitizer {
  constructor(platformService) {
    this.platformService = platformService;
    this.attrWhitelist = [anchorAttrHookId, anchorAttrParseToken, 'class', 'href', 'src'];
  }
  /**
   * Sanitizes an element while preserving marked hook anchors
   *
   * @param contentElement - The element to sanitize
   * @param hookIndex - The current hookIndex
   * @param token - The current ParseToken
   */
  sanitize(contentElement, hookIndex, token) {
    const originalHookAnchors = {};
    // Replace all hook anchors with custom placeholder elements
    // This is so the browser has no predefined rules where they can and can't exist in the dom hierarchy and doesn't edit the html.
    for (const hook of Object.values(hookIndex)) {
      const anchorElement = this.platformService.querySelectorAll(contentElement, `[${anchorAttrHookId}="${hook.id}"][${anchorAttrParseToken}="${token}"]`)?.[0];
      if (anchorElement) {
        originalHookAnchors[hook.id] = anchorElement;
        const parentElement = this.platformService.getParentNode(anchorElement);
        const childNodes = this.platformService.getChildNodes(anchorElement);
        const placeholderElement = this.platformService.createElement(sanitizerPlaceholderTag);
        this.platformService.setAttribute(placeholderElement, anchorAttrHookId, hook.id.toString());
        this.platformService.setAttribute(placeholderElement, anchorAttrParseToken, token);
        this.platformService.insertBefore(parentElement, placeholderElement, anchorElement);
        this.platformService.removeChild(parentElement, anchorElement);
        for (const node of childNodes) {
          this.platformService.appendChild(placeholderElement, node);
        }
      }
    }
    // Encode sanitization placeholders (so they survive sanitization)
    let innerHTML = this.platformService.getInnerContent(contentElement);
    innerHTML = this.findAndEncodeTags(innerHTML, sanitizerPlaceholderRegex);
    // Sanitize (without warnings)
    const consoleWarnFn = console.warn;
    console.warn = () => {};
    let sanitizedInnerHtml = this.platformService.sanitize(innerHTML);
    console.warn = consoleWarnFn;
    // Decode sanitization placeholders
    sanitizedInnerHtml = this.decodeTagString(sanitizedInnerHtml);
    contentElement.innerHTML = sanitizedInnerHtml || '';
    // Restore original hook anchors
    for (const [hookId, anchorElement] of Object.entries(originalHookAnchors)) {
      const placeholderElement = this.platformService.querySelectorAll(contentElement, `${sanitizerPlaceholderTag}[${anchorAttrHookId}="${hookId}"]`)?.[0];
      if (placeholderElement) {
        const parentElement = this.platformService.getParentNode(placeholderElement);
        const childNodes = this.platformService.getChildNodes(placeholderElement);
        this.platformService.insertBefore(parentElement, anchorElement, placeholderElement);
        this.platformService.removeChild(parentElement, placeholderElement);
        for (const node of childNodes) {
          this.platformService.appendChild(anchorElement, node);
        }
        // As a last step, sanitize the hook anchor attrs as well
        this.sanitizeElementAttrs(anchorElement);
      }
    }
    return contentElement;
  }
  /**
   * Sanitizes a single element's attributes
   *
   * @param element - The element in question
   */
  sanitizeElementAttrs(element) {
    // Collect all existing attributes, put them on span-element, sanitize it, then copy surviving attrs back onto hook anchor element
    const attrs = this.platformService.getAttributeNames(element);
    const tmpWrapperElement = this.platformService.createElement('div');
    const tmpElement = this.platformService.createElement('span');
    this.platformService.appendChild(tmpWrapperElement, tmpElement);
    // Move attr to tmp
    for (const attr of attrs) {
      try {
        this.platformService.setAttribute(tmpElement, attr, this.platformService.getAttribute(element, attr));
      } catch (e) {}
      // Keep in separate try-catch, so the first doesn't stop the second
      try {
        // Always keep those two
        if (attr !== anchorAttrHookId && attr !== anchorAttrParseToken) {
          this.platformService.removeAttribute(element, attr);
        }
      } catch (e) {}
    }
    // Sanitize tmp
    tmpWrapperElement.innerHTML = this.platformService.sanitize(this.platformService.getInnerContent(tmpWrapperElement));
    // Move surviving attrs back to element
    const sanitizedTmpElement = this.platformService.querySelectorAll(tmpWrapperElement, 'span')[0];
    const survivingAttrs = this.platformService.getAttributeNames(sanitizedTmpElement);
    for (const survivingAttr of survivingAttrs) {
      try {
        this.platformService.setAttribute(element, survivingAttr, this.platformService.getAttribute(sanitizedTmpElement, survivingAttr));
      } catch (e) {}
    }
    return element;
  }
  // En/decoding placeholders
  // ------------------------
  /**
   * Finds and encodes all tags that match the specified regex so that they survive sanitization
   *
   * @param content - The stringified html content to search
   * @param substrRegex - The regex that matches the element tags
   */
  findAndEncodeTags(content, substrRegex) {
    let encodedContent = content;
    const matches = matchAll(content, substrRegex);
    matches.sort((a, b) => b.index - a.index);
    for (const match of matches) {
      const startIndex = match.index;
      const endIndex = match.index + match[0].length;
      const textBeforeSelector = encodedContent.substring(0, startIndex);
      const encodedPlaceholder = this.encodeTagString(encodedContent.substring(startIndex, endIndex));
      const textAfterSelector = encodedContent.substring(endIndex);
      encodedContent = textBeforeSelector + encodedPlaceholder + textAfterSelector;
    }
    return encodedContent;
  }
  /**
   * Encodes the special html chars in a html tag so that is is considered a harmless string
   *
   * @param element - The element as a string
   */
  encodeTagString(element) {
    element = element.replace(/</g, '@@@hook-lt@@@');
    element = element.replace(/>/g, '@@@hook-gt@@@');
    element = element.replace(/"/g, '@@@hook-dq@@@');
    return element;
  }
  /**
   * Decodes the encoded html chars in a html tag again
   *
   * @param element - The element as a string
   */
  decodeTagString(element) {
    element = element.replace(/@@@hook-lt@@@/g, '<');
    element = element.replace(/@@@hook-gt@@@/g, '>');
    element = element.replace(/@@@hook-dq@@@/g, '"');
    return element;
  }
  static {
    this.ɵfac = function ContentSanitizer_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ContentSanitizer)(i0.ɵɵinject(AutoPlatformService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ContentSanitizer,
      factory: ContentSanitizer.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentSanitizer, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AutoPlatformService
  }], null);
})();

/**
 * A service for comparing two variables by value instead of by reference
 */
class DeepComparer {
  // 1. Inputs
  // -----------------------------------------------------------------
  constructor(logger) {
    this.logger = logger;
  }
  /**
   * Tests if two objects are equal by value
   *
   * @param a - The first object
   * @param b - The second object
   * @param compareDepth - How many levels deep to compare
   * @param options - The current parseOptions
   */
  isEqual(a, b, compareDepth, options = getParseOptionDefaults()) {
    const aStringified = this.detailedStringify(a, compareDepth);
    const bStringified = this.detailedStringify(b, compareDepth);
    if (aStringified.result === null || bStringified.result === null) {
      this.logger.warn(['Objects could not be compared by value as one or both of them could not be stringified. Returning false. \n', 'Objects:', a, b], options);
      return false;
    }
    return aStringified.result === bStringified.result;
  }
  /**
   * Like JSON.stringify, but stringifies additional datatypes that would have been
   * nulled otherwise. It also doesn't throw errors on cyclic property paths.
   *
   * If obj can't be stringified for whatever reason, returns null.
   *
   * @param obj - The object to stringify
   * @param depth - How many levels deep to stringify
   */
  detailedStringify(obj, depth) {
    try {
      // Null cyclic paths
      const depthReached = {
        count: 0
      };
      const decylcedObj = this.decycle(obj, [], depth, depthReached);
      const stringified = JSON.stringify(decylcedObj, (key, value) => {
        // If undefined
        if (value === undefined) {
          return 'undefined';
        }
        // If function or class
        if (typeof value === 'function') {
          return value.toString();
        }
        // If symbol
        if (typeof value === 'symbol') {
          return value.toString();
        }
        return value;
      });
      return {
        result: stringified,
        depthReachedCount: depthReached.count
      };
    } catch (e) {
      return {
        result: null,
        depthReachedCount: 0
      };
    }
  }
  /**
   * Travels on object and replaces cyclical references with null
   *
   * @param obj - The object to travel
   * @param stack - To keep track of already travelled objects
   * @param depth - How many levels deep to decycle
   * @param depthReached - An object to track the number of times the max depth was reached
   */
  decycle(obj, stack = [], depth = 5, depthReached) {
    if (stack.length > depth) {
      depthReached.count++;
      return null;
    }
    if (!obj || typeof obj !== 'object' || obj instanceof Date) {
      return obj;
    }
    // Check if cyclical and we've traveled this obj already
    //
    // Note: Test this not by object reference, but by object PROPERTY reference/equality. If an object has identical properties,
    // the object is to be considered identical even if it has a different reference itself.
    //
    // Explanation: This is to prevent a sneaky bug when comparing by value and a parser returns an object as an input that contains a reference to the object holding it
    // (like returning the context object that contains a reference to the parent component holding the context object).
    // In this example, when the context object changes by reference, the old input will be compared with the new input. However, as the old input consists of
    // the old context object that now (through the parent component) contains a reference to the new context object, while the new input references the new context
    // object exclusively, the decycle function would produce different results for them if it only checked cyclical paths by reference (even if the context object
    // remained identical in value!)
    //
    // Though an unlikely scenario, checking cyclical paths via object properties rather than the object reference itself solves this problem.
    for (const stackObj of stack) {
      if (this.objEqualsProperties(obj, stackObj)) {
        return null;
      }
    }
    const s = stack.concat([obj]);
    if (Array.isArray(obj)) {
      const newArray = [];
      for (const entry of obj) {
        newArray.push(this.decycle(entry, s, depth, depthReached));
      }
      return newArray;
    } else {
      const newObj = {};
      for (const key of Object.keys(obj)) {
        newObj[key] = this.decycle(obj[key], s, depth, depthReached);
      }
      return newObj;
    }
  }
  /**
   * Returns true when all the properties of one object equal those of another object, otherwise false.
   *
   * @param a - The first object
   * @param b - The second object
   */
  objEqualsProperties(a, b) {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    if (aKeys.length !== bKeys.length) {
      return false;
    }
    for (const aKey of aKeys) {
      if (a[aKey] !== b[aKey]) {
        return false;
      }
    }
    return true;
  }
  static {
    this.ɵfac = function DeepComparer_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DeepComparer)(i0.ɵɵinject(Logger));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DeepComparer,
      factory: DeepComparer.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DeepComparer, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: Logger
  }], null);
})();

/**
 * The service responsible for updating dynamically created components
 */
class ComponentUpdater {
  constructor(deepComparer, logger) {
    this.deepComparer = deepComparer;
    this.logger = logger;
  }
  /**
   * Invoked when the inputs/outputs should be checked for updates
   *
   * @param hookIndex - The current hookIndex
   * @param context - The new context object
   * @param options - The current ParseOptions
   * @param triggerOnDynamicChanges - Whether to trigger the OnDynamicChanges method of dynamically loaded components
   */
  refresh(hookIndex, context, options, triggerOnDynamicChanges) {
    // Update bindings for all loaded hooks
    for (const [hookId, hook] of Object.entries(hookIndex)) {
      this.updateBindings(hook, context, options);
    }
    // Also: If context has changed by reference, call OnDynamicChanges() for all created components.
    if (triggerOnDynamicChanges) {
      for (const hook of Object.values(hookIndex)) {
        if (!hook.componentRef) {
          return;
        }
        if (typeof hook.componentRef.instance['onDynamicChanges'] === 'function') {
          hook.componentRef.instance['onDynamicChanges']({
            context
          });
        }
      }
    }
  }
  /**
   * Creates or updates bindings for a hook with a loaded component
   *
   * @param hook - THe hook to update
   * @param context - The context object
   * @param options - The current ParseOptions
   */
  updateBindings(hook, context, options) {
    if (!hook.componentRef) {
      return;
    }
    // Update bindings
    hook.bindings = hook.parser.getBindings(hook.id, hook.value, context, options);
    this.updateComponentWithNewInputs(hook, options);
    this.updateComponentWithNewOutputs(hook, context, options);
    // Snapshot bindings for comparison next time
    hook.previousBindings = {
      inputs: this.savePreviousBindings(hook, 'inputs', options.compareInputsByValue, options.compareByValueDepth),
      outputs: this.savePreviousBindings(hook, 'outputs', options.compareOutputsByValue, options.compareByValueDepth)
    };
  }
  /**
   * Creates a list of all previous bindings along with their stringified values
   *
   * @param hook - The hook to check
   * @param type - The type of bindings that should be saved
   * @param saveStringified - Whether to save the stringified value in addition to the reference
   * @param stringifyDepth - How many levels deep to stringify the previous bindings
   */
  savePreviousBindings(hook, type, saveStringified, stringifyDepth) {
    const result = {};
    if (hook.bindings.hasOwnProperty(type)) {
      for (const [bindingName, bindingValue] of Object.entries(hook.bindings[type])) {
        result[bindingName] = {
          reference: bindingValue,
          stringified: saveStringified ? this.deepComparer.detailedStringify(bindingValue, stringifyDepth) : null // To compare by value
        };
      }
    }
    return result;
  }
  // Updating bindings
  // ----------------------------------------------------------------------------------------------------------------
  /**
   * Processes a hook object and updates the inputs of a dynamic component where required
   *
   * @param hook - The hook in question
   * @param options - The current ParseOptions
   */
  updateComponentWithNewInputs(hook, options) {
    const component = hook.componentRef.instance;
    // Find out which inputs have changed
    const changedInputs = this.getChangedBindings(hook, 'inputs', options);
    // Check if inputs exists on component
    const existingInputs = [];
    const compMeta = reflectComponentType(hook.componentRef.componentType);
    for (const [inputName, inputValue] of Object.entries(changedInputs)) {
      // Some naming tolerance: Input name can be case-insensitive and in dash-case.
      // Look for more literal matches first (transformed dash-case + case-insensitive has lowest priority)
      const metaKey = options.ignoreInputAliases ? 'propName' : 'templateName';
      const inputEntry = compMeta.inputs.find(inputObject => inputName === inputObject[metaKey]) || compMeta.inputs.find(inputObject => inputName.toLowerCase() === inputObject[metaKey].toLowerCase()) || compMeta.inputs.find(inputObject => inputName.replace(/-/g, '') === inputObject[metaKey]) || compMeta.inputs.find(inputObject => inputName.replace(/-/g, '').toLowerCase() === inputObject[metaKey].toLowerCase());
      // If actual input was found, add it
      if (inputEntry) {
        existingInputs.push({
          propName: inputEntry.propName,
          templateName: inputEntry.templateName,
          value: inputValue
        });
        // If not, but accepts any property as input, add it anyway
      } else if (options.acceptInputsForAnyProperty) {
        // If property exists (in a case-agnostic way), use it. Otherwise create literal new property.
        let foundInputProp = Object.getOwnPropertyNames(component).find(propName => inputName === propName) || Object.getOwnPropertyNames(component).find(propName => inputName.toLowerCase() === propName.toLowerCase());
        const finalInputProp = foundInputProp || inputName;
        // Even this setting has limits. Don't allow setting fundamental JavaScript object properties.
        if (!['__proto__', 'prototype', 'constructor'].includes(finalInputProp)) {
          existingInputs.push({
            propName: finalInputProp,
            templateName: null,
            value: inputValue
          });
        } else {
          this.logger.error(['Tried to overwrite a __proto__, prototype or constructor property with input "' + finalInputProp + '" for hook "' + hook.componentRef.componentType.name + '". This is not allowed.'], options);
          continue;
        }
      }
    }
    // Set inputs in component
    for (const {
      propName,
      templateName,
      value
    } of existingInputs) {
      if (templateName) {
        hook.componentRef?.setInput(templateName, value); // Official method to set inputs. Sets property, triggers OnChanges and marks for OnPush. Also works with new signal inputs.
      } else if (propName) {
        hook.componentRef.instance[propName] = value; // Resort to manual setting only if prop isn't a declared input (may happen with "acceptInputsForAnyProperty")
      }
    }
    // Important: Still need to trigger cd, even with componentRef.setInput
    if (existingInputs.length) {
      hook.componentRef?.changeDetectorRef.detectChanges();
    }
  }
  /**
   * Processes a hook object and (re)subscribes the outputs of a dynamic component where required
   *
   * @param hook - The hook in question
   * @param context - The current context object
   * @param options - The current ParseOptions
   */
  updateComponentWithNewOutputs(hook, context, options) {
    const component = hook.componentRef.instance;
    // Find out which outputs have changed
    const changedOutputs = this.getChangedBindings(hook, 'outputs', options);
    // Check if outputs exist on component
    const existingOutputs = [];
    const compMeta = reflectComponentType(hook.componentRef.componentType);
    for (const [outputName, outputCallback] of Object.entries(changedOutputs)) {
      const metaKey = options.ignoreOutputAliases ? 'propName' : 'templateName';
      const outputEntry = compMeta.outputs.find(outputObject => outputName === outputObject[metaKey]) || compMeta.outputs.find(outputObject => outputName.toLowerCase() === outputObject[metaKey].toLowerCase()) || compMeta.outputs.find(outputObject => outputName.replace(/-/g, '') === outputObject[metaKey]) || compMeta.outputs.find(outputObject => outputName.replace(/-/g, '').toLowerCase() === outputObject[metaKey].toLowerCase());
      if (outputEntry) {
        existingOutputs.push({
          propName: outputEntry.propName,
          templateName: outputEntry.templateName,
          value: outputCallback
        });
      } else if (options.acceptOutputsForAnyObservable) {
        // If observable exists (in a case-agnostic way), use it
        let foundOutputProp = Object.getOwnPropertyNames(component).find(propName => component[propName] instanceof Observable && outputName === propName) || Object.getOwnPropertyNames(component).find(propName => component[propName] instanceof Observable && outputName.toLowerCase() === propName.toLowerCase());
        if (foundOutputProp) {
          existingOutputs.push({
            propName: foundOutputProp,
            templateName: null,
            value: outputCallback
          });
        }
      }
    }
    // (Re)subscribe to outputs, store subscription in Hook
    for (const {
      propName,
      templateName,
      value
    } of existingOutputs) {
      if (hook.outputSubscriptions[propName]) {
        hook.outputSubscriptions[propName].unsubscribe();
      }
      hook.outputSubscriptions[propName] = hook.componentRef.instance[propName].subscribe(event => {
        value(event, context);
      });
    }
  }
  /**
   * Compares the current with the previous bindings and returns those that have changed
   *
   * @param hook - The hook in question
   * @param type - What kind of binding to check
   * @param options - The current ParseOptions
   */
  getChangedBindings(hook, type, options) {
    const changedBindings = {};
    if (hook.bindings.hasOwnProperty(type)) {
      for (const [key, binding] of Object.entries(hook.bindings[type])) {
        // If binding did not exist in previous hook data, binding is considered changed
        if (!hook.previousBindings || !hook.previousBindings[type].hasOwnProperty(key)) {
          changedBindings[key] = binding;
          continue;
        }
        // Compare old with new
        // a) By reference
        if (type === 'inputs' ? !options.compareInputsByValue : !options.compareOutputsByValue) {
          if (binding !== hook.previousBindings[type][key].reference) {
            changedBindings[key] = binding;
          }
          // b) By value
        } else {
          const stringifiedBinding = this.deepComparer.detailedStringify(binding, options.compareByValueDepth);
          const canBeComparedByValue = this.checkDetailedStringifyResultPair(key, hook.componentRef.componentType.name, options, hook.previousBindings[type][key].stringified, stringifiedBinding);
          if (canBeComparedByValue) {
            if (stringifiedBinding.result !== hook.previousBindings[type][key].stringified.result) {
              changedBindings[key] = binding;
            }
          } else {
            if (binding !== hook.previousBindings[type][key].reference) {
              changedBindings[key] = binding;
            }
          }
        }
      }
    }
    return changedBindings;
  }
  /**
   * Checks whether two detailedStringifiedResults can be compared and throws lots of errors and warnings if not
   *
   * @param bindingName - The binding in question
   * @param componentName - The component in question
   * @param options - The current ParseOptions
   * @param oldResult - The detailedStringifiedResult for the old value
   * @param newResult - The detailedStringifiedResult for the new value
   */
  checkDetailedStringifyResultPair(bindingName, componentName, options, oldResult, newResult) {
    // Stringify successful?
    if (oldResult.result === null && newResult.result === null) {
      this.logger.warn(['Could stringify neither new nor old value for hook binding "' + bindingName + '" for component "' + componentName + '" to compare by value. Defaulting to comparison by reference instead.'], options);
      return false;
    }
    if (oldResult.result === null) {
      this.logger.warn(['Could not stringify old value for hook binding "' + bindingName + '" for component "' + componentName + '" to compare by value. Defaulting to comparison by reference instead.'], options);
      return false;
    }
    if (newResult.result === null) {
      this.logger.warn(['Could not stringify new value for hook binding "' + bindingName + '" for component "' + componentName + '" to compare by value. Defaulting to comparison by reference instead.'], options);
      return false;
    }
    // Max depth reached?
    if (oldResult.depthReachedCount > 0 && newResult.depthReachedCount > 0) {
      this.logger.warn(['Maximum compareByValueDepth of ' + options.compareByValueDepth + ' reached ' + newResult.depthReachedCount + ' time(s) for new value and ' + oldResult.depthReachedCount + ' time(s) for old value while comparing binding "' + bindingName + '" for component "' + componentName + '.\n', 'If this impacts performance, consider simplifying this binding, reducing comparison depth or setting compareInputsByValue/compareOutputsByValue to false.'], options);
    } else if (oldResult.depthReachedCount > 0) {
      this.logger.warn(['Maximum compareByValueDepth of ' + options.compareByValueDepth + ' reached ' + oldResult.depthReachedCount + ' time(s) for old value while comparing binding "' + bindingName + '" for component "' + componentName + '.\n', 'If this impacts performance, consider simplifying this binding, reducing comparison depth or setting compareInputsByValue/compareOutputsByValue to false.'], options);
    } else if (newResult.depthReachedCount > 0) {
      this.logger.warn(['Maximum compareByValueDepth of ' + options.compareByValueDepth + ' reached ' + newResult.depthReachedCount + ' time(s) for new value while comparing binding "' + bindingName + '" for component "' + componentName + '.\n', 'If this impacts performance, consider simplifying this binding, reducing comparison depth or setting compareInputsByValue/compareOutputsByValue to false.'], options);
    }
    return true;
  }
  static {
    this.ɵfac = function ComponentUpdater_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ComponentUpdater)(i0.ɵɵinject(DeepComparer), i0.ɵɵinject(Logger));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ComponentUpdater,
      factory: ComponentUpdater.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ComponentUpdater, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: DeepComparer
  }, {
    type: Logger
  }], null);
})();

/**
 * The service responsible for dynamically creating components for all found Hooks
 */
class ComponentCreator {
  constructor(platformId, appRef, componentUpdater, platformService, logger) {
    this.platformId = platformId;
    this.appRef = appRef;
    this.componentUpdater = componentUpdater;
    this.platformService = platformService;
    this.logger = logger;
  }
  /**
   * The main entry function to start the dynamic component initialization process
   *
   * @param contentElement - The main content element
   * @param hookIndex - The current hookIndex
   * @param token - The current parse token
   * @param context - The current context object
   * @param options - The current ParseOptions
   * @param environmentInjector - The environment injector to use for the dynamically-created components
   * @param injector - The injector to use for the dynamically-created components
   */
  init(contentElement, hookIndex, token, context, options, environmentInjector, injector) {
    const allComponentsLoaded = new ReplaySubject(1);
    const componentLoadSubjects = [];
    const anchorElements = {};
    // If no hooks found, no need to progress further
    if (Object.keys(hookIndex).length === 0) {
      allComponentsLoaded.next(true);
      return allComponentsLoaded;
    }
    // Check anchor elements prepare loading components
    // Note: Loop queried anchor elements instead of hookIndex entries as it will be in order of appearance, allowing for subsequent ones 
    // to potentially be skipped when overwritten by custom ng-content
    for (let anchorElement of this.platformService.querySelectorAll(contentElement, `[${anchorAttrHookId}][${anchorAttrParseToken}]`)) {
      const hookId = parseInt(this.platformService.getAttribute(anchorElement, anchorAttrHookId));
      // Discard hook if anchor element was removed by previous hook in loop via ng-content replacement
      if (this.platformService.querySelectorAll(contentElement, `[${anchorAttrHookId}="${hookId}"][${anchorAttrParseToken}="${token}"]`).length === 0) {
        delete hookIndex[hookId];
        continue;
      }
      const hook = hookIndex[hookId];
      hook.data = hook.parser.loadComponent(hook.id, hook.value, context, this.platformService.getChildNodes(anchorElement), options);
      hook.isLazy = hook.data.component.hasOwnProperty('importPromise') && hook.data.component.hasOwnProperty('importName');
      // Skip loading lazy components during SSR
      if (!isPlatformBrowser(this.platformId) && hook.isLazy) {
        delete hookIndex[hookId];
        continue;
      }
      // If anchor element is a void element and no custom host element specified, fallback to default anchor element
      if (!hook.data.hostElementTag && voidElementTags.includes(this.platformService.getTagName(anchorElement).toLowerCase())) {
        hook.data.hostElementTag = anchorElementTag;
      }
      /*
      * Replace anchor element with custom one, if desired. Do this before loading any components.
      *
      * Explanation:
      * When a component is created, one of its projectableNodes happens to be another components anchor element, but the parent component doesn't render the anchor right away
      * (due to *ngIf, for example), you can't replace that anchor anymore as it is now tracked in Angular's memory as a reference. And that exact reference will
      * be rendered when the component's *ngIf eventually resolved to true. So need to process all custom host element requests before loading components.
      */
      if (hook.data.hostElementTag) {
        anchorElement = this.useCustomHostElement(anchorElement, hook.data.hostElementTag);
        hook.value.element = anchorElement;
      }
      // Insert child content according to hook.data immediately
      // This has the benefit that if the child content is custom, the next iterations of this loop will throw out all hooks whose placeholder elements 
      // can no longer be found (b/c they were in the discarded child content) so their component won't be unnecessarily loaded.
      this.createContentSlotElements(anchorElement, hook, token);
      anchorElements[hookId] = anchorElement;
    }
    // For safety: Remove hooks from index whose anchor element for whatever reason could no longer be found
    const foundHookIds = Object.keys(anchorElements).map(hookId => parseInt(hookId));
    for (const [hookId, hook] of Object.entries(hookIndex)) {
      if (!foundHookIds.includes(parseInt(hookId))) {
        this.logger.warn(['Error when trying to load components - The anchor element for the following hook was found initially, but could not be found again for loading the component. Ignoring.', hook], options);
        delete hookIndex[hookId];
      }
    }
    // Load components
    for (const [hookId, anchorElement] of Object.entries(anchorElements)) {
      const hook = hookIndex[hookId];
      // Start with of(true) to catch errors from loadComponentClass in the observable stream as well
      componentLoadSubjects.push(of(true)
      // Load component class first (might be lazy-loaded)
      .pipe(mergeMap(() => this.loadComponentClass(hook.data.component))).pipe(tap(compClass => {
        // Get projectableNodes from the content slots
        const projectableNodes = this.extractContentSlotElements(anchorElement, token);
        // Instantiate component
        this.createComponent(hook, context, anchorElement, projectableNodes, options, compClass, environmentInjector, injector);
      }))
      // If could not be created, remove from hookIndex
      .pipe(catchError(e => {
        this.logger.error([e.stack], options);
        delete hookIndex[hook.id];
        return of(null);
      })));
    }
    // If no components in text, no need to progress further
    if (componentLoadSubjects.length === 0) {
      allComponentsLoaded.next(true);
      return allComponentsLoaded;
    }
    // Once all normal and lazy components have loaded
    combineLatest([...componentLoadSubjects]).pipe(first()).subscribe(() => {
      // Call dynamic lifecycle methods for all created components
      for (const hook of Object.values(hookIndex)) {
        // Find all content children components
        const contentChildren = [];
        if (typeof hook.componentRef.instance['onDynamicMount'] === 'function' || typeof hook.componentRef.instance['onDynamicChanges'] === 'function') {
          this.findContentChildren(hook.componentRef.location.nativeElement, contentChildren, hookIndex, token);
        }
        // OnDynamicChanges
        if (typeof hook.componentRef.instance['onDynamicChanges'] === 'function') {
          hook.componentRef.instance['onDynamicChanges']({
            contentChildren
          });
        }
        // OnDynamicMount
        if (typeof hook.componentRef.instance['onDynamicMount'] === 'function') {
          hook.componentRef.instance['onDynamicMount']({
            context,
            contentChildren
          });
        }
      }
      // Remove now redundant attributes from component elements
      for (const anchorElement of Object.values(anchorElements)) {
        this.platformService.removeAttribute(anchorElement, anchorAttrHookId);
        this.platformService.removeAttribute(anchorElement, anchorAttrParseToken);
        this.platformService.removeAttribute(anchorElement, 'ng-version');
      }
      // Done!
      allComponentsLoaded.next(true);
    });
    return allComponentsLoaded;
  }
  // DOM manipulation
  // ----------------------------------------------------------------------------------------------------------------
  /**
   * Replaces a default anchor element with a custom element
   *
   * @param anchorElement - The default component anchor element
   * @param customTagName - The custom tag that should be used instead
   */
  useCustomHostElement(anchorElement, customTagName) {
    const customHostElement = this.platformService.createElement(customTagName);
    // Move attributes to selector
    this.platformService.setAttribute(customHostElement, anchorAttrHookId, this.platformService.getAttribute(anchorElement, anchorAttrHookId));
    this.platformService.setAttribute(customHostElement, anchorAttrParseToken, this.platformService.getAttribute(anchorElement, anchorAttrParseToken));
    this.platformService.removeAttribute(anchorElement, anchorAttrHookId);
    this.platformService.removeAttribute(anchorElement, anchorAttrParseToken);
    // Move child nodes to selector
    const childNodes = this.platformService.getChildNodes(anchorElement);
    for (const node of childNodes) {
      this.platformService.appendChild(customHostElement, node);
    }
    // Replace anchorElement
    this.platformService.insertBefore(this.platformService.getParentNode(anchorElement), customHostElement, anchorElement);
    this.platformService.removeChild(this.platformService.getParentNode(anchorElement), anchorElement);
    return customHostElement;
  }
  /**
   * Creates a content slot dom element for each ng-content tag of the dynamically loaded component.
   *
   * This is to create a direct dom-representation of each entry in the projectableNodes array returned
   * by parser.loadComponent, so it can be cleanly resolved back into projectableNodes later on. Without these
   * content slots for separation, you wouldn't know which child nodes go into which ng-content slot.
   *
   * @param hostElement - The dom element to create the content slots in
   * @param hook - The hook of the component
   * @param token - The current parse token
   */
  createContentSlotElements(hostElement, hook, token) {
    let content;
    // If content property is defined, use the submitted content slots
    if (hook.data.hasOwnProperty('content') && Array.isArray(hook.data.content)) {
      content = hook.data.content;
      // Otherwise just wrap existing content into single content slot
    } else {
      content = [this.platformService.getChildNodes(hostElement)];
    }
    // Empty child nodes
    this.platformService.clearChildNodes(hostElement);
    // Insert new ones
    for (const [index, contentSlot] of content.entries()) {
      if (contentSlot !== undefined && contentSlot !== null) {
        const contentSlotElement = this.platformService.createElement('dynamic-component-contentslot');
        this.platformService.setAttribute(contentSlotElement, 'slotIndex', index.toString());
        this.platformService.setAttribute(contentSlotElement, 'parsetoken', token);
        for (const node of contentSlot) {
          this.platformService.appendChild(contentSlotElement, node);
        }
        this.platformService.appendChild(hostElement, contentSlotElement);
      }
    }
  }
  /**
   * Returns all previously created content slots for a component element as a projectableNodes[][] array
   *
   * @param componentHostElement - The dom element with the content slots
   * @param token - The current parse token
   */
  extractContentSlotElements(componentHostElement, token) {
    // Resolve ng-content from content slots
    const projectableNodes = [];
    const contentSlotElements = this.platformService.getChildNodes(componentHostElement).filter(entry => this.platformService.getTagName(entry) === 'DYNAMIC-COMPONENT-CONTENTSLOT' && this.platformService.getAttribute(entry, 'parsetoken') === token);
    for (const contentSlotElement of contentSlotElements) {
      const slotIndex = this.platformService.getAttribute(contentSlotElement, 'slotIndex');
      projectableNodes[parseInt(slotIndex)] = this.platformService.getChildNodes(contentSlotElement);
    }
    // Bugfix: Make sure to manually remove the content slots and not just rely on createComponent() to do so. 
    // Otherwise they will persist with SSR due to hydration bug.
    this.platformService.clearChildNodes(componentHostElement);
    return projectableNodes;
  }
  // Component creation
  // ----------------------------------------------------------------------------------------------------------------
  /**
   * Loads the component class from a ComponentConfig. Returns a subject the emits the class when ready.
   *
   * @param componentConfig - The componentConfig from HookData
   */
  loadComponentClass(componentConfig) {
    const componentClassLoaded = new ReplaySubject(1);
    // a) If is component class
    if (componentConfig.hasOwnProperty('prototype')) {
      componentClassLoaded.next(componentConfig);
      // c) If is function that returns promise with component class
    } else if (typeof componentConfig === 'function') {
      componentConfig().then(compClass => {
        componentClassLoaded.next(compClass);
      });
      // c) If is LazyLoadComponentConfig
    } else if (componentConfig.hasOwnProperty('importPromise') && componentConfig.hasOwnProperty('importName')) {
      // Catch typical importPromise error
      if (componentConfig.importPromise instanceof Promise) {
        throw Error(`When lazy-loading a component, the "importPromise"-field must contain a function returning the import-promise, but it contained the promise itself.`);
      }
      componentConfig.importPromise().then(m => {
        const importName = componentConfig.importName;
        const compClass = Object.prototype.hasOwnProperty.call(m, importName) ? m[importName] : m['default'];
        componentClassLoaded.next(compClass);
      });
    } else {
      throw Error('The "component" property of a returned HookData object must either contain the component class, a function that returns a promise with the component class or an explicit LazyLoadComponentConfig');
    }
    return componentClassLoaded;
  }
  /**
   * Dynamically creates the component with Angular methods
   *
   * @param hook - The hook for this component
   * @param context - The current context
   * @param componentHostElement - The hostElement for the component
   * @param projectableNodes - The nodes to inject as ng-content
   * @param options - The current ParseOptions
   * @param compClass - The component class
   * @param environmentInjector - The default environmentInjector
   * @param injector - The default injector
   */
  createComponent(hook, context, componentHostElement, projectableNodes, options, compClass, environmentInjector, injector) {
    // Dynamically create component
    // Note: Transcluded content (including components) for ng-content can simply be added here in the form of the projectableNodes-argument.
    // The order of component creation or injection via projectableNodes does not seem to matter.
    const dynamicComponentRef = createComponent(compClass, {
      hostElement: componentHostElement,
      environmentInjector: hook.data.environmentInjector || environmentInjector,
      elementInjector: hook.data.injector || injector,
      projectableNodes: projectableNodes
    });
    // Track component
    hook.componentRef = dynamicComponentRef;
    // Optionally trigger HTML events when outputs emit
    this.mapOutputsToHTMLEvents(hook, options);
    // Pass in initial bindings
    this.componentUpdater.updateBindings(hook, context, options);
    // Call initial OnDynamicChanges with context (if not undefined)
    if (typeof hook.componentRef.instance['onDynamicChanges'] === 'function' && context !== undefined) {
      hook.componentRef.instance['onDynamicChanges']({
        context
      });
    }
    // Activate change detection
    this.appRef.attachView(dynamicComponentRef.hostView);
    // Trigger an Initial cd call to:
    // - have Angular automatically invoke ngOnInit(), which happens the first time the change detector runs for a component
    // - prevent ExpressionHasChangedErrors in Angular<8
    dynamicComponentRef.changeDetectorRef.detectChanges();
  }
  // Other
  // ----------------------------------------------------------------------------------------------------------------
  /**
   * Register DOM events to trigger when component outputs emit
   *
   * @param hook - The component hook
   * @param options - The current ParseOptions
   */
  mapOutputsToHTMLEvents(hook, options) {
    const compMeta = reflectComponentType(hook.componentRef.componentType);
    for (const outputObject of compMeta.outputs) {
      const outputName = options.ignoreOutputAliases ? outputObject.propName : outputObject.templateName;
      // Trigger events, if requested
      hook.htmlEventSubscriptions[outputName] = hook.componentRef.instance[outputObject.propName].subscribe(event => {
        if (options.triggerDOMEvents) {
          this.platformService.dispatchEvent(hook.componentRef?.location.nativeElement, outputName, event);
        }
      });
    }
  }
  /**
   * Find all components that would be the ContentChildren of a dynamic component and returns them in a hierarchical tree object
   * Important: This function depends on the anchor attributes not being removed yet
   *
   * @param node - The HTML node to parse
   * @param treeLevel - The current tree level of DynamicContentChildren (for recursiveness)
   * @param hookIndex - The current hookIndex
   * @param token - The current parseToken
   */
  findContentChildren(node, treeLevel = [], hookIndex, token) {
    const childNodes = this.platformService.getChildNodes(node);
    if (childNodes != undefined && childNodes.length > 0) {
      childNodes.forEach((childNode, key) => {
        let componentFound = false;
        // If element has a parsetoken and hookid, it is a dynamic component
        const parseToken = this.platformService.getAttribute(childNode, anchorAttrParseToken);
        if (parseToken !== null && parseToken === token && this.platformService.getAttribute(childNode, anchorAttrHookId)) {
          const hookId = parseInt(this.platformService.getAttribute(childNode, anchorAttrHookId), 10);
          if (hookIndex.hasOwnProperty(hookId)) {
            treeLevel.push({
              componentRef: hookIndex[hookId].componentRef,
              contentChildren: [],
              hookValue: hookIndex[hookId].value
            });
            componentFound = true;
          }
        }
        // The hierarchical structure of the result is solely built on found components. It DOES NOT reflect the actual HTML structure.
        // E.g. two components returned on the same array level in the result may be on completely different nesting levels in the HTML,
        // as the only reason to 'go a level deeper' in the result is when a component was found.
        const treeLevelForNested = componentFound ? treeLevel[treeLevel.length - 1].contentChildren : treeLevel;
        this.findContentChildren(childNode, treeLevelForNested, hookIndex, token);
      });
    }
  }
  static {
    this.ɵfac = function ComponentCreator_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ComponentCreator)(i0.ɵɵinject(PLATFORM_ID), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(ComponentUpdater), i0.ɵɵinject(AutoPlatformService), i0.ɵɵinject(Logger));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ComponentCreator,
      factory: ComponentCreator.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ComponentCreator, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.ApplicationRef
  }, {
    type: ComponentUpdater
  }, {
    type: AutoPlatformService
  }, {
    type: Logger
  }], null);
})();

/**
 * The core service for the ngx-dynamic-hooks library. Provides the main logic internally used by all components.
 */
class DynamicHooksService {
  constructor(allSettings, ancestorSettings, moduleSettings, settingsResolver, textHookFinder, elementHookFinder, contentSanitizer, componentCreator, platformService, environmentInjector, injector) {
    this.allSettings = allSettings;
    this.ancestorSettings = ancestorSettings;
    this.moduleSettings = moduleSettings;
    this.settingsResolver = settingsResolver;
    this.textHookFinder = textHookFinder;
    this.elementHookFinder = elementHookFinder;
    this.contentSanitizer = contentSanitizer;
    this.componentCreator = componentCreator;
    this.platformService = platformService;
    this.environmentInjector = environmentInjector;
    this.injector = injector;
  }
  /**
   * Parses content and loads components for all found hooks
   *
   * @param content - The content to parse
   * @param parsers - An optional list of parsers to use instead of the global ones
   * @param context - An optional context object
   * @param options - An optional list of options
   * @param globalParsersBlacklist - An optional list of global parsers to blacklist
   * @param globalParsersWhitelist - An optional list of global parsers to whitelist
   * @param targetElement - An optional HTML element to use as the container for the loaded content.
   * @param targetHookIndex - An optional object to fill with the programmatic hook data. If none is provided, one is created and returned for you.
   * @param environmentInjector - An optional environmentInjector to use for the dynamically-loaded components. If none is provided, the default environmentInjector is used.
   * @param injector - An optional injector to use for the dynamically-loaded components. If none is provided, the default injector is used.
   */
  parse(content = null, parsers = null, context = null, options = null, globalParsersBlacklist = null, globalParsersWhitelist = null, targetElement = null, targetHookIndex = {}, environmentInjector = null, injector = null) {
    const usedEnvironmentInjector = environmentInjector || this.environmentInjector;
    const usedInjector = injector || this.injector;
    // Resolve options and parsers
    const {
      parsers: usedParsers,
      options: usedOptions
    } = this.settingsResolver.resolve(usedInjector,
    // Use element injector for resolving service parsers (instead of environment injector). Will fallback to environment injector anyway if doesn't find anything.
    content, this.allSettings, this.ancestorSettings, this.moduleSettings, parsers, options, globalParsersBlacklist, globalParsersWhitelist);
    // Needs string or element as content
    if (!content) {
      return of({
        element: targetElement || this.platformService.createElement('div'),
        hookIndex: targetHookIndex,
        context: context,
        usedParsers,
        usedOptions,
        usedInjector,
        usedEnvironmentInjector,
        destroy: () => this.destroy(targetHookIndex)
      });
    }
    const token = Math.random().toString(36).substring(2, 12);
    let contentElement = typeof content === 'string' ? this.platformService.createElement('div') : content;
    this.platformService.setAttribute(contentElement, contentElementAttr, '1');
    // a) Find all text hooks in string content
    if (typeof content === 'string') {
      const result = this.textHookFinder.find(content, context, usedParsers, token, usedOptions, targetHookIndex);
      this.platformService.setInnerContent(contentElement, result.content);
      // b) Find all text hooks in element content
    } else {
      this.textHookFinder.findInElement(contentElement, context, usedParsers, token, usedOptions, targetHookIndex);
    }
    // Find all element hooks
    targetHookIndex = this.elementHookFinder.find(contentElement, context, usedParsers, token, usedOptions, targetHookIndex);
    // Sanitize?
    if (usedOptions?.sanitize) {
      this.contentSanitizer.sanitize(contentElement, targetHookIndex, token);
    }
    // After sanitizing, insert into targetElement, if any
    if (targetElement && targetElement !== contentElement) {
      this.platformService.removeAttribute(contentElement, contentElementAttr);
      this.platformService.setAttribute(targetElement, contentElementAttr, '1');
      this.platformService.clearChildNodes(targetElement);
      for (const childNode of this.platformService.getChildNodes(contentElement)) {
        this.platformService.appendChild(targetElement, childNode);
      }
      contentElement = targetElement;
    }
    // Dynamically create components in component selector elements
    return this.componentCreator.init(contentElement, targetHookIndex, token, context, usedOptions, usedEnvironmentInjector, usedInjector).pipe(first()).pipe(map(allComponentsLoaded => {
      // Everything done!
      this.platformService.removeAttribute(contentElement, contentElementAttr);
      return {
        element: contentElement,
        hookIndex: targetHookIndex,
        context: context,
        usedParsers,
        usedOptions,
        usedInjector,
        usedEnvironmentInjector,
        destroy: () => this.destroy(targetHookIndex)
      };
    }));
  }
  /**
   * Cleanly destroys all loaded components in a given HookIndex
   *
   * @param hookIndex - The hookIndex to process
   */
  destroy(hookIndex) {
    if (hookIndex) {
      // Destroy dynamic components
      for (const hookIndexEntry of Object.values(hookIndex)) {
        if (hookIndexEntry.componentRef) {
          hookIndexEntry.componentRef.destroy();
        }
      }
      // Unsubscribe from hook outputs
      for (const hook of Object.values(hookIndex)) {
        for (const parserSub of Object.values(hook.outputSubscriptions)) {
          if (parserSub) {
            parserSub.unsubscribe();
          }
        }
        for (const htmlEventSub of Object.values(hook.htmlEventSubscriptions)) {
          if (htmlEventSub) {
            htmlEventSub.unsubscribe();
          }
        }
      }
    }
  }
  static {
    this.ɵfac = function DynamicHooksService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DynamicHooksService)(i0.ɵɵinject(DYNAMICHOOKS_ALLSETTINGS, 8), i0.ɵɵinject(DYNAMICHOOKS_ANCESTORSETTINGS, 8), i0.ɵɵinject(DYNAMICHOOKS_MODULESETTINGS, 8), i0.ɵɵinject(SettingsResolver), i0.ɵɵinject(TextHookFinder), i0.ɵɵinject(ElementHookFinder), i0.ɵɵinject(ContentSanitizer), i0.ɵɵinject(ComponentCreator), i0.ɵɵinject(AutoPlatformService), i0.ɵɵinject(i0.EnvironmentInjector), i0.ɵɵinject(i0.Injector));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DynamicHooksService,
      factory: DynamicHooksService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DynamicHooksService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [DYNAMICHOOKS_ALLSETTINGS]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [DYNAMICHOOKS_ANCESTORSETTINGS]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [DYNAMICHOOKS_MODULESETTINGS]
    }]
  }, {
    type: SettingsResolver
  }, {
    type: TextHookFinder
  }, {
    type: ElementHookFinder
  }, {
    type: ContentSanitizer
  }, {
    type: ComponentCreator
  }, {
    type: AutoPlatformService
  }, {
    type: i0.EnvironmentInjector
  }, {
    type: i0.Injector
  }], null);
})();
const allSettings = [];
/**
 * Sets up global parsers and options for the ngx-dynamic-hooks library
 *
 * @param settings - Parsers/options to be are shared in this injection context
 * @param platformService - (optional) If desired, you can specify a custom PlatformService to use here
 */
const provideDynamicHooks = (settings, platformService) => {
  const moduleSettings = Array.isArray(settings) ? {
    parsers: settings
  } : settings;
  if (moduleSettings !== undefined) {
    allSettings.push(moduleSettings);
  }
  const providers = [{
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    multi: true,
    deps: [DynamicHooksInitService]
  },
  // Settings
  {
    provide: DYNAMICHOOKS_ALLSETTINGS,
    useValue: allSettings
  },
  // AncestorSettings is a hierarchical array of provided settings
  // By having itself as a dependency with SkipSelf, a circular reference is avoided as Angular will look for DYNAMICHOOKS_ANCESTORSETTINGS in the parent injector.
  // It will keep traveling injectors upwards until it finds another or just use null as the dep.
  // Also, by returning a new array reference each time, the result will only contain the direct ancestor child settings, not all child settings from every module in the app.
  // See: https://stackoverflow.com/questions/49406615/is-there-a-way-how-to-use-angular-multi-providers-from-all-multiple-levels
  {
    provide: DYNAMICHOOKS_ANCESTORSETTINGS,
    useFactory: ancestorSettings => {
      ancestorSettings = Array.isArray(ancestorSettings) ? ancestorSettings : [];
      ancestorSettings = moduleSettings !== undefined ? [...ancestorSettings, moduleSettings] : ancestorSettings;
      return ancestorSettings;
    },
    deps: [[new SkipSelf(), new Optional(), DYNAMICHOOKS_ANCESTORSETTINGS]]
  }, {
    provide: DYNAMICHOOKS_MODULESETTINGS,
    useValue: moduleSettings
  },
  // Must provide a separate instance of DynamicHooksService each time you call provideDynamicHooks, 
  // so it can see passed settings of this level
  DynamicHooksService];
  if (platformService) {
    providers.push({
      provide: PLATFORM_SERVICE,
      useClass: platformService
    });
  }
  return providers;
};
/**
 * A service that will always be created on app init, even without using a DynamicHooksComponent
 */
class DynamicHooksInitService {
  ngOnDestroy() {
    // Reset allSettings on app close for the benefit of vite live reloads and tests (which does not destroy allSettings reference between app reloads)
    // Safer to do this only on app close rather than on app start as it acts like a cleanup function and the order of execution matters less
    allSettings.length = 0;
  }
  static {
    this.ɵfac = function DynamicHooksInitService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DynamicHooksInitService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DynamicHooksInitService,
      factory: DynamicHooksInitService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DynamicHooksInitService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const resetDynamicHooks = () => {
  allSettings.length = 0;
};

// Global state
// ----------
let sharedInjector = null;
let scopes = [];
let allParseResults = [];
const createInjector = async (providers = [], parent) => {
  // If no parent, create new root injector, so passed providers will also be actual root providers
  return parent ? createEnvironmentInjector(providers, parent) : (await createApplication({
    providers
  })).injector;
};
/**
 * Destroys all scopes and components created by standalone mode
 */
const destroyAll = () => {
  // Destroy all scopes
  for (const scope of scopes) {
    scope.destroy();
  }
  // Then all remaining independent parseResults
  for (const parseResult of allParseResults) {
    parseResult.destroy();
  }
  sharedInjector = null;
  scopes = [];
  allParseResults = [];
};
// Providers scope
// ----------
/**
 * Creates an isolated scope with its own providers that the dynamically-created components will then have access to.
 *
 * @param providers - A list of providers
 * @param parentScope - An optional parent scope created previously. Makes the parent providers also accessible to this scope.
 */
const createProviders = (providers = [], parentScope) => {
  return new ProvidersScope(providers, parentScope);
};
/**
 * A scope with an internal list of providers. All dynamic components created by its `parse` method will have access to them.
 */
class ProvidersScope {
  get injector() {
    return this._injector;
  }
  get parseResults() {
    return this._parseResults;
  }
  get isDestroyed() {
    return this._isDestroyed;
  }
  constructor(providers = [], parentScope) {
    this.providers = providers;
    this.parentScope = parentScope;
    this._injector = null;
    this._parseResults = [];
    this._isDestroyed = false;
    scopes.push(this);
  }
  /**
  * Parses content and loads components for all found hooks in standalone mode
  *
  * @param content - The content to parse
  * @param parsers - The parsers to use
  * @param context - An optional context object
  * @param options - An optional list of options
  * @param targetElement - An optional HTML element to use as the container for the loaded content.
  * @param targetHookIndex - An optional object to fill with the programmatic hook data. If none is provided, one is created and returned for you.
  * @param environmentInjector - An optional environmentInjector to use for the dynamically-loaded components. If none is provided, the default environmentInjector is used.
  */
  async parse(content, parsers, context = null, options = null, targetElement = null, targetHookIndex = {}, environmentInjector = null) {
    this.checkIfDestroyed();
    return parse(content, parsers, context, options, targetElement, targetHookIndex, environmentInjector || (await this.resolveInjector())).then(parseResult => {
      this.parseResults.push(parseResult);
      return parseResult;
    });
  }
  /**
   * Returns the injector for this scope
   */
  async resolveInjector() {
    this.checkIfDestroyed();
    if (!this.injector) {
      const parentInjector = this.parentScope ? await this.parentScope.resolveInjector() : undefined;
      this._injector = await createInjector(this.providers, parentInjector);
    }
    return this.injector;
  }
  /**
   * Destroys this scope and all of its created components
   */
  destroy() {
    this.checkIfDestroyed();
    for (const parseResult of this.parseResults) {
      parseResult.destroy();
      allParseResults = allParseResults.filter(entry => entry !== parseResult);
    }
    if (this.injector) {
      this.injector.destroy();
    }
    scopes = scopes.filter(scope => scope !== this);
    this._isDestroyed = true;
  }
  checkIfDestroyed() {
    if (this.isDestroyed) {
      throw new Error('This scope has already been destroyed. It or its methods cannot be used any longer.');
    }
  }
}
// parse
// ----------
/**
 * Parses content and loads components for all found hooks in standalone mode
 *
 * @param content - The content to parse
 * @param parsers - The parsers to use
 * @param context - An optional context object
 * @param options - An optional list of options
 * @param targetElement - An optional HTML element to use as the container for the loaded content.
 * @param targetHookIndex - An optional object to fill with the programmatic hook data. If none is provided, one is created and returned for you.
 * @param environmentInjector - An optional environmentInjector to use for the dynamically-loaded components. If none is provided, the default environmentInjector is used.
 */
const parse = async (content, parsers, context = null, options = null, targetElement = null, targetHookIndex = {}, environmentInjector = null) => {
  // Reuse the same global injector for all independent parse calls
  if (!environmentInjector) {
    if (!sharedInjector) {
      sharedInjector = await createInjector();
    }
    environmentInjector = sharedInjector;
  }
  // In standalone mode, emit HTML events from outputs by default
  if (!options) {
    options = {};
  }
  if (!options.hasOwnProperty('triggerDOMEvents')) {
    options.triggerDOMEvents = true;
  }
  const dynHooksService = environmentInjector.get(DynamicHooksService);
  // Needs to be run inside NgZone manually
  return environmentInjector.get(NgZone).run(() => {
    return firstValueFrom(dynHooksService.parse(content, parsers, context, options, null, null, targetElement, targetHookIndex, environmentInjector, null)).then(parseResult => {
      allParseResults.push(parseResult);
      return parseResult;
    });
  });
};

/**
 * A function that observes an HTMLElement and triggers a callback when new elements are added to it.
 * Does NOT trigger for Angular components or logic, only for neutral HTML elements.
 *
 * @param content - The HTMLElement to watch for element additions
 * @param callbackFn - The callback function to call when a change occurs. Will be called with the closest parent element of all added elements.
 */
const observeElement = (content, callbackFn) => {
  const observer = new MutationObserver((mutationsList, observer) => {
    // Collect only addded nodes
    let newNodes = [];
    for (const mutation of mutationsList) {
      mutation.addedNodes.forEach(addedNode => newNodes.push(addedNode));
      mutation.removedNodes.forEach(removedNode => newNodes = newNodes.filter(newNode => newNode !== removedNode));
    }
    // Ignore new nodes created as part of Angular component views
    newNodes = newNodes.filter(newNode => newNode.nodeType === 1 && !isAngularManagedElement(newNode) ||
    // Check HTMLElements
    newNode.nodeType === 3 && !isAngularManagedElement(newNode.parentNode) // Check text node parents
    );
    // Ignore new nodes that are children of a content element that is currently being parsed (lots of elements get created/removed during that time)
    newNodes = newNodes.filter(newNode => {
      const element = newNode.nodeType === 1 ? newNode : newNode.parentElement;
      return element.closest(`[${contentElementAttr}]`) === null;
    });
    if (newNodes.length) {
      // Find closest common parent
      const commonParent = findClosestCommonParent(newNodes);
      // Run callback
      callbackFn(commonParent);
    }
  });
  observer.observe(content, {
    childList: true,
    subtree: true
  });
  return observer;
};
/**
 * Finds the closest common parent element for multiple elements
 *
 * @param elements - The elements in question
 */
const findClosestCommonParent = elements => {
  if (elements.length === 0) return null;
  let parent = elements[0];
  for (const element of elements) {
    while (parent === element || !parent.contains(element)) {
      parent = parent.parentElement;
    }
  }
  return parent;
};

/**
 * The main component of the ngx-dynamic-hooks library to dynamically load components into content
 */
class DynamicHooksComponent {
  constructor(hostElement, dynamicHooksService, componentUpdater, platformService, environmentInjector, injector) {
    this.hostElement = hostElement;
    this.dynamicHooksService = dynamicHooksService;
    this.componentUpdater = componentUpdater;
    this.platformService = platformService;
    this.environmentInjector = environmentInjector;
    this.injector = injector;
    this.content = null;
    this.context = null;
    this.globalParsersBlacklist = null;
    this.globalParsersWhitelist = null;
    this.parsers = null;
    this.options = null;
    this.componentsLoaded = new EventEmitter();
    this.hookIndex = {};
    this.activeOptions = getParseOptionDefaults();
    this.activeParsers = [];
    this.token = Math.random().toString(36).substring(2, 12);
    this.initialized = false;
  }
  ngDoCheck() {
    // Update bindings on every change detection run?
    if (!this.activeOptions.updateOnPushOnly) {
      this.refresh(false);
    }
  }
  ngOnChanges(changes) {
    // If text or options change, reset and parse from scratch
    if (changes.hasOwnProperty('content') || changes.hasOwnProperty('globalParsersBlacklist') || changes.hasOwnProperty('globalParsersWhitelist') || changes.hasOwnProperty('parsers') || changes.hasOwnProperty('options')) {
      this.reset();
      this.parse(this.content);
      // If only context changed, just refresh hook inputs/outputs
    } else if (changes.hasOwnProperty('context')) {
      this.refresh(true);
    }
  }
  ngAfterViewInit() {}
  ngAfterViewChecked() {}
  ngOnDestroy() {
    this.reset();
  }
  // ----------------------------------------------------------------------
  /**
   * Empties the state of this component
   */
  reset() {
    this.dynamicHooksService.destroy(this.hookIndex);
    // Reset state
    this.platformService.setInnerContent(this.hostElement.nativeElement, '');
    this.hookIndex = {};
    this.activeOptions = getParseOptionDefaults();
    this.activeParsers = [];
    this.initialized = false;
  }
  /**
   * Parses the content and load components
   *
   * @param content - The content to parse
   */
  parse(content) {
    this.dynamicHooksService.parse(content, this.parsers, this.context, this.options, this.globalParsersBlacklist, this.globalParsersWhitelist, this.hostElement.nativeElement, this.hookIndex, this.environmentInjector, this.injector).subscribe(parseResult => {
      // hostElement and hookIndex are automatically filled
      this.activeParsers = parseResult.usedParsers;
      this.activeOptions = parseResult.usedOptions;
      this.initialized = true;
      // Return all loaded components
      const loadedComponents = Object.values(this.hookIndex).map(hook => {
        return {
          hookId: hook.id,
          hookValue: hook.value,
          hookParser: hook.parser,
          componentRef: hook.componentRef
        };
      });
      this.componentsLoaded.emit(loadedComponents);
    });
  }
  /**
   * Updates the bindings for all existing components
   *
   * @param triggerOnDynamicChanges - Whether to trigger the OnDynamicChanges method of dynamically loaded components
   */
  refresh(triggerOnDynamicChanges) {
    if (this.initialized) {
      this.componentUpdater.refresh(this.hookIndex, this.context, this.activeOptions, triggerOnDynamicChanges);
    }
  }
  static {
    this.ɵfac = function DynamicHooksComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DynamicHooksComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(DynamicHooksService), i0.ɵɵdirectiveInject(ComponentUpdater), i0.ɵɵdirectiveInject(AutoPlatformService), i0.ɵɵdirectiveInject(i0.EnvironmentInjector), i0.ɵɵdirectiveInject(i0.Injector));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DynamicHooksComponent,
      selectors: [["ngx-dynamic-hooks"]],
      inputs: {
        content: "content",
        context: "context",
        globalParsersBlacklist: "globalParsersBlacklist",
        globalParsersWhitelist: "globalParsersWhitelist",
        parsers: "parsers",
        options: "options"
      },
      outputs: {
        componentsLoaded: "componentsLoaded"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function DynamicHooksComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DynamicHooksComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-dynamic-hooks',
      template: '',
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: DynamicHooksService
  }, {
    type: ComponentUpdater
  }, {
    type: AutoPlatformService
  }, {
    type: i0.EnvironmentInjector
  }, {
    type: i0.Injector
  }], {
    content: [{
      type: Input
    }],
    context: [{
      type: Input
    }],
    globalParsersBlacklist: [{
      type: Input
    }],
    globalParsersWhitelist: [{
      type: Input
    }],
    parsers: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    componentsLoaded: [{
      type: Output
    }]
  });
})();

/**
 * A component that can be used to dynamically load a single component and pass bindings to it
 */
class DynamicSingleComponent {
  constructor(hostElement, platformService, dynamicHooksService, componentUpdater) {
    this.hostElement = hostElement;
    this.platformService = platformService;
    this.dynamicHooksService = dynamicHooksService;
    this.componentUpdater = componentUpdater;
    this.component = null;
    this.inputs = {};
    this.outputs = {};
    this.options = {};
    this.componentLoaded = new EventEmitter();
    this.parseResult = null;
    this.parseOptions = {};
  }
  ngDoCheck() {
    // Update on every change detection run?
    if (!this.parseOptions.updateOnPushOnly) {
      this.refresh();
    }
  }
  ngOnChanges(changes) {
    // If component changed, reset and load from scratch
    if (changes.hasOwnProperty('component')) {
      this.reset();
      this.parseOptions = {
        ...getParseOptionDefaults(),
        ...this.options
      };
      this.loadComponent();
      // If anything else changed, just refresh inputs/outputs
    } else if (changes.hasOwnProperty('inputs') || changes.hasOwnProperty('outputs') || changes.hasOwnProperty('options')) {
      this.parseOptions = {
        ...getParseOptionDefaults(),
        ...this.options
      };
      this.refresh();
    }
  }
  ngAfterViewInit() {}
  ngAfterViewChecked() {}
  ngOnDestroy() {
    this.reset();
  }
  // ----------------------------------------------------------------------
  /**
   * Destroys the dynamic component and resets the state
   */
  reset() {
    if (this.parseResult) {
      this.dynamicHooksService.destroy(this.parseResult.hookIndex);
    }
    this.platformService.setInnerContent(this.hostElement.nativeElement, '');
    this.parseResult = null;
    this.parseOptions = {};
  }
  /**
   * Loads the dynamic component
   */
  loadComponent() {
    if (this.component) {
      const compMeta = reflectComponentType(this.component);
      if (!compMeta) {
        throw new Error('Provided component class input is not a valid Angular component.');
      }
      // Try to use component selector as hostElement. Otherwise default to standard anchor.
      let selector;
      let componentHostElement;
      try {
        selector = compMeta.selector;
        componentHostElement = this.platformService.createElement(selector);
      } catch (e) {
        selector = anchorElementTag;
        componentHostElement = this.platformService.createElement(anchorElementTag);
      }
      this.platformService.clearChildNodes(this.hostElement.nativeElement);
      this.platformService.appendChild(this.hostElement.nativeElement, componentHostElement);
      // Create parser that finds created hostElement as hook and loads requested component into it
      const parser = this.createAdHocParser(selector);
      this.dynamicHooksService.parse(this.hostElement.nativeElement, [parser], {}, this.parseOptions).subscribe(parseResult => {
        this.parseResult = parseResult;
        this.componentLoaded.next(parseResult.hookIndex[1].componentRef);
      });
    }
  }
  /**
   * Creates a parser specifically for the dynamic component
   *
   * @param selector - The selector to use for the component
   */
  createAdHocParser(selector) {
    const that = this;
    class AdHocSingleComponentParser {
      findHookElements(contentElement, context) {
        return that.platformService.querySelectorAll(contentElement, selector);
      }
      loadComponent(hookId, hookValue, context, childNodes) {
        return {
          component: that.component
        };
      }
      getBindings(hookId, hookValue, context) {
        return {
          inputs: that.inputs,
          outputs: that.outputs
        };
      }
    }
    return AdHocSingleComponentParser;
  }
  /**
   * Updates the bindings for the loaded component
   */
  refresh() {
    if (this.parseResult) {
      this.componentUpdater.refresh(this.parseResult.hookIndex, {}, this.parseOptions, false);
    }
  }
  static {
    this.ɵfac = function DynamicSingleComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DynamicSingleComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(AutoPlatformService), i0.ɵɵdirectiveInject(DynamicHooksService), i0.ɵɵdirectiveInject(ComponentUpdater));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DynamicSingleComponent,
      selectors: [["ngx-dynamic-single"]],
      inputs: {
        component: "component",
        inputs: "inputs",
        outputs: "outputs",
        options: "options"
      },
      outputs: {
        componentLoaded: "componentLoaded"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function DynamicSingleComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DynamicSingleComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-dynamic-single',
      template: '',
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: AutoPlatformService
  }, {
    type: DynamicHooksService
  }, {
    type: ComponentUpdater
  }], {
    component: [{
      type: Input
    }],
    inputs: [{
      type: Input
    }],
    outputs: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    componentLoaded: [{
      type: Output
    }]
  });
})();

/*
 * Public API Surface of ngx-dynamic-hooks
 */
// General
// Testing
// export * from './tests/testing-api';

/**
 * Generated bundle index. Do not edit.
 */

export { DataTypeParser, DeepComparer, DefaultPlatformService, DynamicHooksComponent, DynamicHooksInheritance, DynamicHooksService, DynamicSingleComponent, ElementSelectorHookParser, HookFinder, PLATFORM_SERVICE, ProvidersScope, TextSelectorHookParser, allSettings, createProviders, destroyAll, getParseOptionDefaults, isAngularManagedElement, matchAll, observeElement, parse, provideDynamicHooks, regexes, resetDynamicHooks, selectorHookParserConfigDefaults, sortElements };
